import React, { memo } from "react"
import { Mesh } from "three"
import { useAvatarStore } from "../../stores/avatarStore"

interface AvatarTargetProps {
	id: string
}

const AvatarTarget = memo<AvatarTargetProps>(({ id }) => {
	const position = useAvatarStore((state) => state.users[id].position)
	const updateTargetMesh = useAvatarStore((state) => state.actions.updateTargetMesh)

	const setRef = (newRef: Mesh | null) => {
		if (newRef) updateTargetMesh(id, newRef)
	}

	return (
		<>
			{position && (
				<mesh ref={setRef} position={[position[0], 0, position[1]]}>
					{/* <boxBufferGeometry args={[0.2, 0.2, 0.2]} />
				<meshStandardMaterial color={"hotpink"} /> */}
				</mesh>
			)}
		</>
	)
})

export default AvatarTarget
