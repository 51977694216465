import { extend } from "@react-three/fiber"
import { ShaderMaterial, Vector3, Vector4 } from "three"

const vertexShaderText = /* glsl */ `
	varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`

const fragmentShaderText = /* glsl */ `
	//precision highp float;
	varying vec2 vUv;
	uniform float time; 
	uniform vec3 avatarOptions[COUNT]; 
	uniform vec4 avatarPositions[COUNT];

    void main() {

		vec4 col1 = vec4(0.980392, 0.545098, 1.0, 1.0);
		vec4 col2 = vec4(0.407843, 0.403922, 1.0 , 1.0);
		vec4 gradient = mix(col1, col2, vUv.x);
		vec4 white = vec4(1.0, 1.0, 1.0, 1.0); 
		vec4 black = vec4(0.0, 0.0, 0.0, 1.0); 
		vec4 none = vec4(1.0, 1.0, 1.0, 0.0); 
		
		float v = 0.0;
		float k = 0.0;
		float p = 0.0;
		float sign = 1.0; 

		float dp = 0.0; 
		float vp = 0.0; 
		float dt = 0.0;
		float rp = RADIUSU; 
		float rt = RADIUST;
		float sigmap = 300.0;
		float sigmat = 700.0;
		float meshScaleMax = max(WIDTH, HEIGHT) + 0.0;

		float signal = 0.0;
		float signal_mask1 = 0.0;
		float signal_mask2 = 0.0;

		#pragma unroll_loop_start
		for (int i = 0; i < COUNT; i++) {
			sign = (avatarOptions[i].y  > 0.0) ? -1.0 : 1.0;
			
		
				dp = distance(avatarPositions[i].rg, vUv);
				v = v  +  sign * (((-1.0) / (0.5 + exp((-dp + rp + avatarOptions[i].x) * sigmap))) + 2.0);
				
				dt = distance(avatarPositions[i].ba, vUv);
				v = v + sign * (((-1.0) / (0.5 + exp((-dt + rt) * sigmat))) + 2.0);

				if(avatarOptions[i].z > 0.0) {
					signal_mask1 = clamp((avatarOptions[i].z + 0.6)  / SIGNAL_MAX_TIME * 1.42, 0.0, 1.0);
					signal_mask2 =  clamp((avatarOptions[i].z - 0.7 ) / SIGNAL_MAX_TIME  * 1.42, 0.0, 1.0);
					if( dp < SIGNAL_RADIUS * signal_mask1  && dp >  SIGNAL_RADIUS * signal_mask2 ) {
						signal = abs(sin(dp * dp * SIGNAL_FREQUENCY - avatarOptions[i].z * SIGNAL_SPEED));
						
						if(signal > 0.97) {
							k = k + signal; 
						}
						p = 1.0 - dp / SIGNAL_RADIUS;
					}
				}
			

		}
		#pragma unroll_loop_end


		

		if (v > 1.0) {
			gl_FragColor = white;
		} else if (v > 0.98) {
			gl_FragColor = white; 
			gl_FragColor.a = mix(1.0, 0.0, (1.0 - v) / 0.04);
		} else if (v < -1.0) {
			gl_FragColor = white; 
			gl_FragColor.a = 0.6;
		}else if (v < -0.96) {
			gl_FragColor = white; 
			gl_FragColor.a = mix(0.6, 0.0, (1.0 - abs(v)) / 0.04);
		} else {
			gl_FragColor = none;
		}

		

		if(k > 0.99) {
			gl_FragColor = vec4(1.0,1.0,1.0, p);
		} else if (k > 0.97) {
			gl_FragColor = white; 
			gl_FragColor.a = mix(p, 0.0, (0.99 - k) / 0.02);
		}
		

		//gl_FragColor = vec4(v,v, v,1.0); 

    }`

class MetaballMaterial extends ShaderMaterial {
	constructor(
		width: number,
		height: number,
		count: number,
		radiusUser: number,
		radiusTarget: number,
		signalMaxTime: number
	) {
		super({
			defines: {
				WIDTH: width + 0.001,
				HEIGHT: height + 0.001,
				COUNT: count <= 0 ? 1 : count,
				RADIUSU: radiusUser,
				RADIUST: radiusTarget,
				SIGNAL_RADIUS: 0.05,
				SIGNAL_FREQUENCY: 4000.001,
				SIGNAL_SPEED: 7.001,
				SIGNAL_MAX_TIME: signalMaxTime + 0.001,
			},
			uniforms: {
				time: {
					value: 0,
				},
				avatarPositions: {
					value: new Array(1).fill(new Vector4(0, 0, 0, 0)),
				},
				avatarOptions: {
					value: new Array(1).fill(new Vector3(0, 0, 0)),
				},
			},
			vertexShader: vertexShaderText,
			fragmentShader: fragmentShaderText,
			transparent: true,
		})
	}

	set time(v: number) {
		this.uniforms.time.value = v
	}

	set avatarPositions(v: Array<Vector4>) {
		this.uniforms.avatarPositions.value = v
	}

	set avatarOptions(v: Array<Vector3>) {
		this.uniforms.avatarOptions.value = v
	}
}

extend({ MetaballMaterial })
