import { Canvas } from "@react-three/fiber"

import React, { useEffect } from "react"
import { useGesture } from "react-use-gesture"
import { NoToneMapping, sRGBEncoding } from "three"
import useWindowFocus from "use-window-focus"
import Interface from "../../components/interface/interface"
import Onboarding from "../../components/onboarding/onboarding"
import StartScene from "../../scenes/defaultScene/startScene"
import { useDebugStore } from "../../stores/debugStore"
import { useInputStore } from "../../stores/inputStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSocketStore } from "../../stores/socketStore"
import { Container } from "./sceneManager_style"

const SceneManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Scene Manager Reload")

	// Stores
	const actions = useInputStore((state) => state.actions)
	const spaceId = useSocketStore((state) => state.spaceId)

	const pinchGesture = (state) => {
		state.event.preventDefault()
		console.log(state.event)
	}

	// Inputs
	const bind = useGesture({
		onDrag: (state) => actions.updateMouseDown(state),
		onDragEnd: (state) => actions.updateMouseUp(state),
		onWheel: (state) => actions.updateWheel(state),
		onMove: (state) => actions.updateMouseMove(state),
		onPinch: (state) => pinchGesture(state),
	})

	const windowFocused = useWindowFocus()
	useEffect(() => {
		actions.updateFocus(windowFocused)
	}, [windowFocused, actions])

	useEffect(() => {
		window.addEventListener("keydown", actions.updateKeydown)
		window.addEventListener("keyup", actions.updateKeyup)

		return () => {
			window.removeEventListener("keydown", actions.updateKeydown)
			window.removeEventListener("keyup", actions.updateKeyup)
		}
	})

	return (
		<Container {...bind()}>
			<Interface />
			<Onboarding id={spaceId} />
			<Canvas
				concurrent
				orthographic
				pixelRatio={1}
				camera={{}}
				gl={{ antialias: false }}
				resize={{ polyfill: ResizeObserver }}
				onCreated={({ gl }) => {
					gl.toneMapping = NoToneMapping
					gl.outputEncoding = sRGBEncoding
				}}
			>
				<StartScene />
				{/* <EffectComposer>
					<SelectiveBloom luminanceThreshold={0.0} luminanceSmoothing={0.3} height={20} selectionLayer={2} />
				</EffectComposer> */}
			</Canvas>
		</Container>
	)
}

export default SceneManager
