import BezierEasing from "bezier-easing"
import { create } from "zustand"
import { persist } from "zustand/middleware"
import { downloadObjectAsJson } from "./../utils/donwload"
import { Bot, BotAction, BotPosition, useAvatarStore } from "./avatarStore"
//import loadJsonFile from "load-json-file"

import { CubicBezierCurve, MathUtils, Vector2 } from "three"

type SerializedBot = {
	name: string
	videoUrl?: string
	imageUrl?: string
	positions: BotPosition[]
}

type BotAnimationStore = {
	timeIntervall: [startTime: number, endTime: number]
	startTime?: number
	stopTime?: number
	isPlaying: boolean
	donwloadCounter: number
	serializedBots: SerializedBot[]

	play: () => void
	pause: () => void
	reset: () => void
	save: () => void
	currentTime: (offest?: number) => number
	updateTimeIntervall: (timeIntervall: [startTime: number, endTime: number]) => void
	interpolatePosition: (time: number, positions: BotPosition[]) => [x: number, y: number] | undefined
	interpolateAction: (time: number, previousTime: number, positions: BotPosition[]) => BotAction | undefined
}

export const useBotAnimationStore = create(
	persist<BotAnimationStore>(
		(set, get) => ({
			timeIntervall: [0, 10],
			donwloadCounter: 0,

			isPlaying: false,
			startTime: undefined,
			stopTime: undefined,
			serializedBots: [],

			updateTimeIntervall: (timeIntervall): void => {
				set({ timeIntervall: timeIntervall })
			},
			play: (): void => {
				const { isPlaying, startTime, stopTime } = get()
				if (!isPlaying) {
					if (stopTime && startTime) {
						set({ isPlaying: true, startTime: new Date().getTime() - (stopTime - startTime) })
					} else {
						set({ isPlaying: true, startTime: new Date().getTime() })
					}
				}
			},
			pause: (): void => {
				set({ isPlaying: false, stopTime: new Date().getTime() })
			},
			reset: (): void => {
				set({ isPlaying: false, startTime: undefined, stopTime: undefined })
			},
			save: (): void => {
				const { donwloadCounter } = get()
				const serializedBots = Object.values(useAvatarStore.getState().users)
					.map((user) => {
						if (user.isBot) {
							const bot = user as Bot
							return {
								name: bot.name,
								positions: bot.positions,
								imageUrl: bot.avatarImageUrl,
								videoUrl: bot.videoUrl,
							}
						} else {
							return undefined
						}
					})
					.filter((bot) => bot !== undefined) as SerializedBot[]

				if (serializedBots.length > 0) {
					set({ serializedBots: serializedBots, donwloadCounter: donwloadCounter + 1 })
					downloadObjectAsJson({ bots: serializedBots }, "serialized bots_" + (donwloadCounter + 1))
				}
			},
			currentTime: (offset = 0): number => {
				const { isPlaying, startTime, stopTime, timeIntervall } = get()

				if (isPlaying && startTime) {
					const timeDiff = new Date().getTime() - startTime
					const time = ((timeDiff / 1000) % (timeIntervall[1] - timeIntervall[0])) + timeIntervall[0]
					if (offset > 0) {
						const offsetTime = (((timeDiff + offset) / 1000) % (timeIntervall[1] - timeIntervall[0])) + timeIntervall[0]
						if (offsetTime < time) {
							return time
						} else {
							return offsetTime
						}
					} else {
						return time
					}
				} else if (!isPlaying && startTime && stopTime) {
					const timeDiff = stopTime - startTime
					const time = ((timeDiff / 1000) % (timeIntervall[1] - timeIntervall[0])) + timeIntervall[0]
					if (offset > 0) {
						const offsetTime = (((timeDiff + offset) / 1000) % (timeIntervall[1] - timeIntervall[0])) + timeIntervall[0]
						if (offsetTime < time) {
							return time
						} else {
							return offsetTime
						}
					} else {
						return time
					}
				} else {
					return 0
				}
			},
			interpolateAction: (time, previousTime, positions): BotAction | undefined => {
				const { timeIntervall } = get()

				if (positions.length <= 1) return undefined
				if (time < timeIntervall[0] || time > timeIntervall[1]) return undefined

				for (var i = 0; i < positions.length - 1; i++) {
					const timeBefore = positions[i].time
					const timeAfter = positions[i + 1].time

					if (time >= timeBefore && time <= timeAfter) {
						if (!(previousTime >= timeBefore && previousTime <= timeAfter) || time < previousTime) {
							return positions[i].actions
						} else {
							return undefined
						}
					}
				}
				return undefined
			},

			interpolatePosition: (time, positions): [x: number, y: number] | undefined => {
				const { timeIntervall } = get()

				if (positions.length <= 1) return undefined
				if (time < timeIntervall[0] || time > timeIntervall[1]) return undefined

				for (var i = 0; i < positions.length - 1; i++) {
					const positionBefore = positions[i].position
					const timeBefore = positions[i].time

					const timeSmoothing = positions[i].timeSmoothing
					const positionSmoothing = positions[i].positionSmoothing

					const positionAfter = positions[i + 1].position
					const timeAfter = positions[i + 1].time

					if (time >= timeBefore && time <= timeAfter) {
						let lp = (time - timeBefore) / (timeAfter - timeBefore)

						if (timeSmoothing.isActive) {
							const bezier = BezierEasing(timeSmoothing.x0, timeSmoothing.y0, timeSmoothing.x1, timeSmoothing.y1)
							lp = bezier(lp)
						}
						let x = 0
						let y = 0

						if (positionSmoothing.isActive) {
							const curve = new CubicBezierCurve(
								new Vector2(positionBefore.x, positionBefore.y),
								new Vector2(positionSmoothing.x0, positionSmoothing.y0),
								new Vector2(positionSmoothing.x1, positionSmoothing.y1),
								new Vector2(positionAfter.x, positionAfter.y)
							)

							const point = curve.getPoint(lp)
							x = point.x
							y = point.y
						} else {
							x = MathUtils.lerp(positionBefore.x, positionAfter.x, lp)
							y = MathUtils.lerp(positionBefore.y, positionAfter.y, lp)
						}

						return [x, y]
					}
				}

				return undefined
			},
		}),
		{ name: "botAnimation" }
	)
)
