import { HorizontalGroup, VerticalGroup } from "@saysom/shared"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, CenterContainer, H1, H2, TextAreaInput } from "../../assets/style"
import Stars from "../../components/stars/stars"
import { useHandleChange } from "../../hooks/useHandleChange"
import { fetchApi } from "../../network/fetch"
import { useSocketStore } from "../../stores/socketStore"
import { SpaceIdParams } from "./join"

const Feedback = () => {
	const { spaceId } = useParams<SpaceIdParams>()

	const leave = useSocketStore((state) => state.leave)

	const [score, setScore] = useState(-1)
	const [message, setMessage] = useHandleChange("")
	const [feedbackReceived, setFeedbackReceived] = useState(false)

	const submit = useCallback(async () => {
		const result = await fetchApi("/feedback/create", { score: score + 1, spaceCode: spaceId, message }, "POST")
		if (result) {
			setFeedbackReceived(true)
		}
	}, [message, score, spaceId])

	useEffect(() => {
		leave()
	}, [leave])

	return (
		<div
			style={{
				width: "100%",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<CenterContainer width="500px">
				<H1 style={{ marginBottom: "20px" }}>Thank you for participating!</H1>

				{!feedbackReceived ? (
					<>
						<VerticalGroup spacing={10} style={{ margin: "20px 0" }} alignItems="center">
							<H2 style={{ textAlign: "center" }}>
								How likely are you to recommend SaySom to a friends or colleagues?
							</H2>
							<Stars onChange={setScore} />
						</VerticalGroup>

						<VerticalGroup spacing={10} style={{ margin: "20px 0" }} alignItems="center">
							<H2>Any feedback or wishes?</H2>
							<TextAreaInput
								placeholder="Optional"
								style={{ width: "400px" }}
								rows={3}
								value={message}
								onChange={setMessage}
							/>
						</VerticalGroup>

						<VerticalGroup spacing={10} style={{ margin: "20px 0" }} alignItems="center">
							<Button style={{ marginTop: "20px" }} onClick={submit} disabled={score === -1}>
								Submit
							</Button>

							<a href="https://saysom.app"> Skip </a>
						</VerticalGroup>
					</>
				) : (
					<>
						<H2 style={{ textAlign: "center" }}>We received your feedback, thank you!</H2>
						<Button
							style={{ marginTop: "20px" }}
							onClick={() => {
								window.location.href = "https://saysom.app"
							}}
						>
							Exit
						</Button>
					</>
				)}
			</CenterContainer>
		</div>
	)
}

export default Feedback
