interface EmojiProps {
	symbol: string
	label?: string
	size?: number
}

const Emoji = ({ label, symbol, size }: EmojiProps) => {
	return (
		<span
			role="img"
			className="emoji"
			aria-label={label ? label : ""}
			aria-hidden={label ? "false" : "true"}
			style={{ fontSize: size ? size + "px" : "default", willChange: "transform" }}
		>
			{symbol}
		</span>
	)
}
export default Emoji
