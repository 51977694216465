import styled from "styled-components"

export const Circle = styled.div`
	width: 26px;
	height: 26px;
	border-radius: 100px;
	pointer-events: none;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	overflow: hidden;

	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);
	border: 2px solid white;

	background-color: white;
`

export const Image = styled.img.attrs({ draggable: false })`
	width: 22px;
	height: 22px;

	cursor: pointer;
`
