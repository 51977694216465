import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { createRoot } from "react-dom/client"
import "react-toastify/dist/ReactToastify.css"
import App from "./app"
import "./index.css"

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://7b01e07cca72462aadf5f9cfbc2924e6@o492219.ingest.sentry.io/5559138",
		autoSessionTracking: true,
		integrations: [new Integrations.BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	})
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<App />)
