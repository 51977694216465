import { bezier } from "@leva-ui/plugin-bezier"
import { folder, LevaInputs, useControls } from "leva"
import React, { memo } from "react"
import { Bot, useAvatarStore } from "../../stores/avatarStore"
import { useBotAnimationStore } from "../../stores/botAnimationStore"
import { useDebugStore } from "../../stores/debugStore"
import { useInputStore } from "../../stores/inputStore"
import { useSettingsStore } from "../../stores/settingsStore"

interface BotEditPositionProps {
	id: string
	idx: number
	isLast: boolean
}

const BotEditPosition = memo<BotEditPositionProps>(({ id, idx, isLast }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Bot Reload")

	// Store
	const actions = useInputStore((state) => state.actions)
	const botActions = useAvatarStore((state) => (state.users[id] as Bot)?.positions[idx].actions)
	const position = useAvatarStore((state) => (state.users[id] as Bot)?.positions[idx].position)
	const time = useAvatarStore((state) => (state.users[id] as Bot)?.positions[idx].time)
	const timeSmoothing = useAvatarStore((state) => (state.users[id] as Bot)?.positions[idx].timeSmoothing)
	const timeIntervall = useBotAnimationStore((state) => state.timeIntervall)
	const positionSmoothing = useAvatarStore((state) => (state.users[id] as Bot)?.positions[idx].positionSmoothing)

	const updatePosition = useAvatarStore((state) => state.botActions.updatePosition)
	const updateAction = useAvatarStore((state) => state.botActions.updateAction)
	const updateTime = useAvatarStore((state) => state.botActions.updateTime)
	const updateTimeSmoothing = useAvatarStore((state) => state.botActions.updateTimeSmoothing)
	const updatePositionSmoothing = useAvatarStore((state) => state.botActions.updatePositionSmoothing)

	const handlePositionChange = (value: number[], index: number) => {
		updatePosition(id, index, value[0], value[1])
	}

	const handleTimeSmoothingActive = (value: boolean, index: number) => {
		updateTimeSmoothing(id, index, value, undefined, undefined, undefined, undefined)
	}
	const handleTimeSmoothing = (value: number[], index: number) => {
		updateTimeSmoothing(id, index, undefined, value[0], value[1], value[2], value[3])
	}

	const handlePositionSmoothingActive = (value: boolean, index: number) => {
		updatePositionSmoothing(id, index, value, undefined, undefined, undefined, undefined)
	}

	const handlePositionSmoothingP1 = (value: number[], index: number) => {
		updatePositionSmoothing(id, index, undefined, value[0], value[1], undefined, undefined)
	}

	const handlePositionSmoothingP2 = (value: number[], index: number) => {
		updatePositionSmoothing(id, index, undefined, undefined, undefined, value[0], value[1])
	}

	const handleTimeChange = (value: number, index: number) => {
		updateTime(id, index, value)
	}

	const handleIsSignalingChange = (value: boolean, index: number) => {
		updateAction(id, index, value, undefined, undefined, undefined)
	}

	const handleIsTalkingChange = (value: boolean, index: number) => {
		updateAction(id, index, undefined, undefined, undefined, value)
	}

	const handeIsInPrivateGroupChange = (value: boolean, index: number) => {
		updateAction(id, index, undefined, undefined, value, undefined)
	}

	const handeIisVideoBlurredChange = (value: boolean, index: number) => {
		updateAction(id, index, undefined, value, undefined, undefined)
	}

	const handleFoucs = () => {
		actions.updateInputFocus(true)
	}

	const handleBlur = () => {
		actions.updateInputFocus(false)
	}

	useControls(
		{
			[`${id}`]: folder({
				[`point (${idx})`]: folder({
					[`time`]: {
						value: time,
						type: LevaInputs.NUMBER,
						onChange: (value) => {
							handleTimeChange(value, idx)
						},
						min: 0,
						max: 60,
						onEditStart: () => {
							handleFoucs()
						},
						onEditEnd: () => {
							handleBlur()
						},
					},
					[`position`]: {
						value: [position.x, position.y],
						type: LevaInputs.VECTOR2D,
						onChange: (value) => {
							handlePositionChange(value, idx)
						},
						min: -20,
						max: 20,
						onEditStart: () => {
							handleFoucs()
						},
						onEditEnd: () => {
							handleBlur()
						},
					},
					[`time smoothing`]: folder(
						{
							[`smoth time `]: {
								value: timeSmoothing.isActive,
								type: LevaInputs.BOOLEAN,
								onChange: (value) => {
									handleTimeSmoothingActive(value, idx)
								},
							},
							[`bezier`]: bezier({
								handles: [timeSmoothing.x0, timeSmoothing.y0, timeSmoothing.x1, timeSmoothing.y1],
								onChange: (value) => {
									handleTimeSmoothing(value, idx)
								},
							}),
						},
						{ collapsed: true }
					),
					[`position smoothing`]: folder(
						{
							[`smooth position`]: {
								value: timeSmoothing.isActive,
								type: LevaInputs.BOOLEAN,
								onChange: (value) => {
									handlePositionSmoothingActive(value, idx)
								},
							},
							[`position (0)`]: {
								value: [positionSmoothing.x0, positionSmoothing.y0],
								type: LevaInputs.VECTOR2D,
								onChange: (value) => {
									handlePositionSmoothingP1(value, idx)
								},
								min: -20,
								max: 20,
								onEditStart: () => {
									handleFoucs()
								},
								onEditEnd: () => {
									handleBlur()
								},
							},
							[`position (1)`]: {
								value: [positionSmoothing.x1, positionSmoothing.y1],
								type: LevaInputs.VECTOR2D,
								onChange: (value) => {
									handlePositionSmoothingP2(value, idx)
								},
								min: -20,
								max: 20,
								onEditStart: () => {
									handleFoucs()
								},
								onEditEnd: () => {
									handleBlur()
								},
							},
						},
						{ collapsed: true }
					),
					[`actions`]: folder(
						{
							[`signaling`]: {
								value: botActions.isSignaling,
								type: LevaInputs.BOOLEAN,
								onChange: (value) => {
									handleIsSignalingChange(value, idx)
								},
							},
							[`private group`]: {
								value: botActions.isInPrivateGroup,
								type: LevaInputs.BOOLEAN,
								onChange: (value) => {
									handeIsInPrivateGroupChange(value, idx)
								},
							},
							[`blurred video`]: {
								value: botActions.isVideoBlurred,
								type: LevaInputs.BOOLEAN,
								onChange: (value) => {
									handeIisVideoBlurredChange(value, idx)
								},
							},
							[`talking`]: {
								value: botActions.isTalking,
								type: LevaInputs.BOOLEAN,
								onChange: (value) => {
									handleIsTalkingChange(value, idx)
								},
							},
						},
						{ collapsed: true }
					),
				}),
			}),
		},
		[id, idx, isLast, timeIntervall]
	)

	return <> </>
})

export default BotEditPosition
