import {
	LiveUserData,
	ModeratorType,
	ModeratorUpdateAdd,
	ModeratorUpdateRemove,
	ModeratorUpdateType,
} from "@saysom/shared"
import { nanoid } from "nanoid"
import { Mesh } from "three"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { config } from "../config"
import { PresenceState, VideoQuality } from "./../entities/avatar/avatarTile"
import { PositionBuffer, PositionSnapshot } from "./../utils/position"
import { useLocalStore } from "./localStore"
import { useSceneStore } from "./sceneStore"
import { useSocketStore } from "./socketStore"

export class Position {
	t: number
	pos: number[]

	constructor(t: number, pos: number[]) {
		this.t = t
		this.pos = pos
	}
} // TODO: Move somewhere else

export type BotAction = {
	isSignaling: boolean
	isVideoBlurred: boolean
	isInPrivateGroup: boolean
	isTalking: boolean
}

export type BotSmoothing = {
	isActive: boolean
	x0: number
	y0: number
	x1: number
	y1: number
}

export type BotPosition = {
	time: number
	position: {
		x: number
		y: number
	}
	timeSmoothing: BotSmoothing
	positionSmoothing: BotSmoothing
	actions: BotAction
}

export interface Media {
	videoQuality: VideoQuality
	presenceState: PresenceState
	isVideoMuted?: boolean
	isAudioMuted?: boolean
}

export interface UserBase extends LiveUserData {
	mesh?: Mesh
	groupId?: string
	isSignaling?: boolean
	signalStartElapsedTime?: number
	meshTarget?: Mesh
	media: Media
	isVisible?: boolean
	isBot: boolean
	moderatorType: ModeratorType
	hasScreenshare: boolean
}

export interface Bot extends LiveUserData {
	mesh?: Mesh
	video?: HTMLVideoElement
	groupId?: string
	videoUrl?: string
	meshTarget?: Mesh
	isVisible?: boolean
	isSignaling?: boolean
	signalStartElapsedTime?: number
	isBot: boolean
	positions: BotPosition[]
	isInPrivateGroup: boolean
	isTalking: boolean
	media: { videoQuality: VideoQuality }
}

export interface User extends UserBase {
	posBuffer: PositionBuffer
	audio?: HTMLAudioElement
	isInOwnerGroup: boolean
}

export interface Owner extends UserBase {
	isMoving: boolean
	areaId?: string
}

type BotActions = {
	addBot: (name?: string, positions?: BotPosition[], avatarImageUrl?: string, videoUrl?: string) => void
	removeBot: (id: string) => void
	updateName: (id: string, name: string) => void
	updateAvatarUrl: (id: string, url: string) => void
	updateVideoUrl: (id: string, url: string) => void
	updateVideo: (id: string, video?: HTMLVideoElement) => void
	updateTime: (id: string, idx: number, time: number) => void
	updatePosition: (id: string, idx: number, x: number, y: number) => void
	updateIsInPrivateGroup: (id: string, isInPrivateGroup: boolean) => void
	updateIsTalking: (id: string, isTalking: boolean) => void
	updateTimeSmoothing: (
		id: string,
		idx: number,
		isActive?: boolean,
		x0?: number,
		y0?: number,
		x1?: number,
		y1?: number
	) => void
	updatePositionSmoothing: (
		id: string,
		idx: number,
		isActive?: boolean,
		x0?: number,
		y0?: number,
		x1?: number,
		y1?: number
	) => void
	updateAction: (
		id: string,
		idx: number,
		isSignaling?: boolean,
		isVideoBlurred?: boolean,
		isInPrivateGroup?: boolean,
		isTalking?: boolean
	) => void
	addRow: (id: string) => void
	removeLastRow: (id: string) => void
}

type RequestActions = {
	moderator: (id: string, moderatorType: ModeratorType) => void
}

type UserActions = {
	removeUser: (id: string) => void
	addUser: (id: string, liveUserData: LiveUserData) => void
	updateUser: (id: string, liveUserData: LiveUserData) => void
	updateLiveUserData: (data: Record<string, LiveUserData>) => void
	updateGroup: (ids: string, groupId: string | undefined, isInOwnerGroup: boolean) => void
	updateGroupForUsers: (ids: string[], groupId: string | undefined, isInOwnerGroup: boolean) => void
	updateMesh: (id: string, mesh: Mesh | undefined) => void
	updateTargetMesh: (id: string, mesh: Mesh) => void
	updateAudioRef: (id: string, ref: HTMLAudioElement | undefined) => void
	updatePositionBuffers: (data: Record<string, number[]>) => void
	updatePresenceState: (id: string, presenceState: PresenceState) => void
	updateIsVideoMuted: (id: string, isVideoMuted: boolean) => void
	updateModeratorType: (id: string, moderatorType: ModeratorType) => void
	updateIsAudioMuted: (id: string, isAudioMuted: boolean) => void
	updateIsSignaling: (id: string, isSignaling: boolean) => void
	updateArea: (id: string, areaId: string | undefined) => void
	updateSignalStartElapsedTime: (id: string, signalStartElapsedTime?: number) => void
	// updatePosition: (id: string, position: number[]) => void
	updateIsVisible: (id: string, isVisible: boolean) => void
	updateVideoQuality: (id: string, videoQuality: VideoQuality) => void
	updateIsMoving: (isMoving: boolean) => void
	updateVisibleUsersCount: (visibleUserCount: number) => void
	updateHasScreenshare: (id: string, hasScreenshare: boolean) => void
}

type AvatarStore = {
	users: Record<string, User | Owner | Bot>
	actions: UserActions
	request: RequestActions
	botActions: BotActions
	visibleUsersCount: number
	resetStore: () => void
}

export const useAvatarStore = create(
	immer<AvatarStore>((set, get) => ({
		users: {},
		visibleUsersCount: 1,

		resetStore: (): void => {
			set((state) => {
				state.users = {}
				state.visibleUsersCount = 0
			})
		},
		request: {
			moderator: (email, moderatorType): void => {
				if (moderatorType !== ModeratorType.None) {
					const moderatorUpdateAdd: ModeratorUpdateAdd = {
						type: ModeratorUpdateType.Add,
						moderators: [{ email, type: moderatorType }],
					}
					useSocketStore.getState().emit.moderator(moderatorUpdateAdd)
				} else {
					const moderatorUpdateRemove: ModeratorUpdateRemove = {
						type: ModeratorUpdateType.Remove,
						moderators: [email],
					}
					useSocketStore.getState().emit.moderator(moderatorUpdateRemove)
				}
			},
		},

		actions: {
			removeUser: (id): void => {
				set((state) => {
					delete state.users[id]
				})
			},
			addUser: (id, liveUserData): void => {
				set((state) => {
					if (id === "owner") {
						state.users[id] = {
							isBot: false,
							isMoving: false,
							isVisible: true,
							media: {
								videoQuality: VideoQuality.High,
								presenceState: PresenceState.Connecting,
							},
							moderatorType: ModeratorType.None,
							hasScreenshare: false,
							...liveUserData,
						}

						const isModerator = useLocalStore.getState().isModerator
						if (isModerator && liveUserData.email)
							useSocketStore.getState().emit.moderator({
								type: ModeratorUpdateType.Add,
								moderators: [{ email: liveUserData.email, type: ModeratorType.Normal }],
							})
					} else {
						const serverConfig = config.server // TODO: Change
						state.users[id] = {
							isBot: false,
							posBuffer: new PositionBuffer(serverConfig.tickRate, serverConfig.tickLag),
							isVisible: true,
							isInOwnerGroup: false,
							media: {
								videoQuality: VideoQuality.Off,
								presenceState: PresenceState.Connecting,
							},
							moderatorType: ModeratorType.None,
							hasScreenshare: false,
							...liveUserData,
						}
					}
				})
			},
			updateLiveUserData: (data): void => {
				const { users, actions } = get()
				const ownerId = get().users["owner"]?.id

				for (var id in data) {
					const liveUserData = data[id]

					if (id === ownerId) id = "owner"
					const user = users[id]

					if (user === undefined) {
						actions.addUser(id, liveUserData)
					} else {
						actions.updateUser(id, liveUserData)
					}
				}
			},
			updateUser: (id, data): void => {
				set((state) => {
					state.users[id].id = data.id
					state.users[id].name = data.name
					state.users[id].email = data.email
					state.users[id].position = data.position
					// state.users[id].isModerator = data.isModerator
					state.users[id].avatarImageUrl = data.avatarImageUrl
				})
			},

			updateGroup: (id, groupId, isInOwnerGroup): void => {
				const ownerIsInGroup = groupId !== undefined && isInOwnerGroup
				set((state) => {
					const user = state.users[id]

					if (user) {
						if (user.groupId !== groupId) {
							state.users[id].groupId = groupId
						}

						if (id !== "owner" && (user as User).isInOwnerGroup !== ownerIsInGroup) {
							;(state.users[id] as User).isInOwnerGroup = ownerIsInGroup
						}
					}
				})
			},

			updateGroupForUsers: (ids, groupId, isInOwnerGroup): void => {
				const { users, actions } = get()
				const ownerId = get().users["owner"]?.id

				ids.forEach((id) => {
					if (id === ownerId) id = "owner"
					const user = users[id]

					if (user) {
						actions.updateGroup(id, groupId, isInOwnerGroup)
					}
				})
			},
			updateMesh: (id, mesh): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						state.users[id].mesh = mesh
					}
				})
			},
			updateTargetMesh: (id, mesh): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						state.users[id].meshTarget = mesh
					}
				})
			},
			updatePositionBuffers: (data): void => {
				var { users } = get()

				for (var id in data) {
					const user = users[id] as User

					if (user) {
						user.posBuffer.add(new PositionSnapshot(data[id]))
					}
				}
			},
			updatePresenceState: (id, presenceState): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						;(state.users[id] as Owner | User).media.presenceState = presenceState

						// Initialize TODO: Weird placement
						if (id === "owner" && presenceState !== PresenceState.Connecting) {
							useSceneStore.getState().initializeZoom()
						}
					}
				})
			},
			updateModeratorType: (email, moderatorType): void => {
				set((state) => {
					Object.entries(state.users).forEach(([id, user]) => {
						if (user.email === email) {
							;(state.users[id] as Owner | User).moderatorType = moderatorType
						}
					})
				})
			},
			updateIsSignaling: (id, isSignaling): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						state.users[id].isSignaling = isSignaling
					}
				})
			},
			updateSignalStartElapsedTime: (id, signalStartElapsedTime): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						state.users[id].signalStartElapsedTime = signalStartElapsedTime
					}
				})
			},
			updateIsAudioMuted: (id, isAudioMuted): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						;(state.users[id] as Owner | User).media.isAudioMuted = isAudioMuted
					}
				})
			},
			updateArea: (id, areaId): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						;(state.users[id] as Owner).areaId = areaId
					}
				})
			},
			updateIsVideoMuted: (id, isVideoMuted): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						;(state.users[id] as Owner | User).media.isVideoMuted = isVideoMuted
					}
				})
			},

			updateAudioRef: (id, ref): void => {
				if (id === "owner") return

				set((state) => {
					const user = state.users[id]
					if (user) {
						;(state.users[id] as User).audio = ref
					}
				})
			},
			updateHasScreenshare: (id, hasScreenshre): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						;(state.users[id] as UserBase).hasScreenshare = hasScreenshre
					}
				})
			},

			/*updatePosition: (id, position): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						state.users[id].position = position
					}
				})
			},*/
			updateIsVisible: (id, isVisible): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						state.users[id].isVisible = isVisible
					}
				})
			},
			updateVideoQuality: (id, videoQuality): void => {
				set((state) => {
					const user = state.users[id]
					if (user) {
						;(state.users[id] as Owner | User).media.videoQuality = videoQuality
					}
				})
			},
			updateIsMoving: (isMoving): void => {
				set((state) => {
					const owner = state.users["owner"]
					if (owner) {
						;(state.users["owner"] as Owner).isMoving = isMoving
					}
				})
			},
			updateVisibleUsersCount: (visibleUsersCount): void => {
				set((state) => {
					visibleUsersCount = visibleUsersCount <= 0 ? 1 : visibleUsersCount
					state.visibleUsersCount = visibleUsersCount
				})
			},
		},
		botActions: {
			addBot: (name, positions, avatarImageUrl, videoUrl): void => {
				const id = nanoid(8)
				const botId = "bot-" + id
				set((state) => {
					;(state.users[botId] as Bot) = {
						id: botId,
						isBot: true,
						email: "",
						isTalking: false,
						isInPrivateGroup: false,
						name: name ? name : "Bot " + id,
						media: { videoQuality: VideoQuality.High },
						videoUrl: videoUrl ? videoUrl : undefined,
						avatarImageUrl: avatarImageUrl ? avatarImageUrl : undefined,
						position: positions ? [positions[0].position.x, 0, positions[0].position.y] : [0, 0, 0],
						positions: positions
							? positions
							: [
									{
										time: 0,
										position: { x: 0, y: 0 },
										timeSmoothing: { isActive: false, x0: 0, y0: 0, x1: 1, y1: 1 },
										positionSmoothing: { isActive: false, x0: 0, y0: 0, x1: 1, y1: 1 },
										actions: { isSignaling: false, isVideoBlurred: false, isInPrivateGroup: false, isTalking: false },
									},
									{
										time: 1,
										position: { x: 1, y: 1 },
										timeSmoothing: { isActive: false, x0: 0, y0: 0, x1: 1, y1: 1 },
										positionSmoothing: { isActive: false, x0: 0, y0: 0, x1: 1, y1: 1 },
										actions: { isSignaling: false, isVideoBlurred: false, isInPrivateGroup: false, isTalking: false },
									},
							  ],
					}
				})
			},
			removeBot: (id: string): void => {
				set((state) => {
					delete state.users[id]
				})
			},
			updateName: (id, name): void => {
				set((state) => {
					state.users[id].name = name
				})
			},
			updateAvatarUrl: (id, url): void => {
				set((state) => {
					state.users[id].avatarImageUrl = url
				})
			},
			updateVideoUrl: (id, url): void => {
				set((state) => {
					;(state.users[id] as Bot).videoUrl = url
				})
			},
			updateVideo: (id, video): void => {
				set((state) => {
					;(state.users[id] as Bot).video = video
				})
			},

			updatePosition: (id, idx, x, y): void => {
				set((state) => {
					;(state.users[id] as Bot).positions[idx].position = { x, y }
				})
			},
			updateIsInPrivateGroup: (id, isInPrivateGroup): void => {
				set((state) => {
					;(state.users[id] as Bot).isInPrivateGroup = isInPrivateGroup
				})
			},
			updateIsTalking: (id, isTalking): void => {
				set((state) => {
					;(state.users[id] as Bot).isTalking = isTalking
				})
			},
			updateTime: (id, idx, time): void => {
				set((state) => {
					;(state.users[id] as Bot).positions[idx].time = time
				})
			},
			updateTimeSmoothing: (id, idx, isActive, x0, y0, x1, y1): void => {
				set((state) => {
					if (isActive !== undefined) (state.users[id] as Bot).positions[idx].timeSmoothing.isActive = isActive
					if (x0 !== undefined) (state.users[id] as Bot).positions[idx].timeSmoothing.x0 = x0
					if (y0 !== undefined) (state.users[id] as Bot).positions[idx].timeSmoothing.y0 = y0
					if (x1 !== undefined) (state.users[id] as Bot).positions[idx].timeSmoothing.x1 = x1
					if (y1 !== undefined) (state.users[id] as Bot).positions[idx].timeSmoothing.y1 = y1
				})
			},
			updatePositionSmoothing: (id, idx, isActive, x0, y0, x1, y1): void => {
				set((state) => {
					if (isActive !== undefined) (state.users[id] as Bot).positions[idx].positionSmoothing.isActive = isActive
					if (x0 !== undefined) (state.users[id] as Bot).positions[idx].positionSmoothing.x0 = x0
					if (y0 !== undefined) (state.users[id] as Bot).positions[idx].positionSmoothing.y0 = y0
					if (x1 !== undefined) (state.users[id] as Bot).positions[idx].positionSmoothing.x1 = x1
					if (y1 !== undefined) (state.users[id] as Bot).positions[idx].positionSmoothing.y1 = y1
				})
			},

			updateAction: (id, idx, isSignaling, isVideoBlurred, isInPrivateGroup, isTalking): void => {
				set((state) => {
					if (isSignaling !== undefined) (state.users[id] as Bot).positions[idx].actions.isSignaling = isSignaling
					if (isVideoBlurred !== undefined)
						(state.users[id] as Bot).positions[idx].actions.isVideoBlurred = isVideoBlurred
					if (isInPrivateGroup !== undefined)
						(state.users[id] as Bot).positions[idx].actions.isInPrivateGroup = isInPrivateGroup
					if (isTalking !== undefined) (state.users[id] as Bot).positions[idx].actions.isTalking = isTalking
				})
			},
			addRow: (id): void => {
				set((state) => {
					let positions = (state.users[id] as Bot).positions
					const lastPosition = positions[positions.length - 1]
					positions.push(lastPosition)
					;(state.users[id] as Bot).positions = positions
				})
			},
			removeLastRow: (id): void => {
				set((state) => {
					const positions = (state.users[id] as Bot).positions
					if (positions.length > 2) {
						positions.pop()
						;(state.users[id] as Bot).positions = positions
					}
				})
			},
		},
	}))
)
