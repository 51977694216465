import { create } from "zustand"

type Movement = {
	userInterpolation: boolean
}

type MovementTweak = {
	userInterpolation: boolean
}

type Scene = {
	metaball: boolean
}

type SceneTweak = {
	metaball: boolean
}

type Logs = {
	reload: boolean
}

type LogsTweak = {
	reload: boolean
}

type DebugStore = {
	logs: Logs
	scene: Scene
	movement: Movement
	tweaks: {
		logsTweak: (tweak: LogsTweak) => void
		sceneTweak: (tweak: SceneTweak) => void
		movementTweak: (tweak: MovementTweak) => void
	}
}

export const useDebugStore = create<DebugStore>((_set, get) => {
	return {
		logs: {
			reload: false,
		},
		scene: {
			metaball: true,
		},
		movement: {
			userInterpolation: true,
		},
		tweaks: {
			logsTweak({ reload }) {
				var { logs } = get()
				logs.reload = reload
			},
			sceneTweak({ metaball }) {
				var { scene } = get()
				scene.metaball = metaball
			},
			movementTweak({ userInterpolation }) {
				var { movement } = get()
				movement.userInterpolation = userInterpolation
			},
		},
	}
})
