import React, { memo, useCallback } from "react"
import styled from "styled-components"
import { VideoQuality } from "../../entities/avatar/avatarTile"
import { Bot, useAvatarStore } from "../../stores/avatarStore"

export type BotTileProps = {
	id: string
}

const BotTile = memo<BotTileProps>(({ id }) => {
	// Store
	const videoURl = useAvatarStore((state) => (state.users[id] as Bot)?.videoUrl)
	const isVideoQualityLow = useAvatarStore((state) => (state.users[id] as Bot)?.media.videoQuality) === VideoQuality.Low
	const updateVideo = useAvatarStore((state) => state.botActions.updateVideo)

	const setRef = useCallback(
		(newRef: HTMLVideoElement | null) => {
			if (newRef === null) {
				updateVideo(id, undefined)
			} else {
				updateVideo(id, newRef)
			}
		},
		[updateVideo, id]
	)

	return (
		<>
			{videoURl && (
				<Video
					style={{ filter: isVideoQualityLow ? "blur(5px)" : "blur(0px)" }}
					src={videoURl}
					autoPlay
					controls={false}
					muted
					playsInline
					ref={setRef}
				/>
			)}
		</>
	)
})

export default BotTile

export const Video = styled.video`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;

	display: block;
`
