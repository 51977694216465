import { Property } from "csstype"
import styled from "styled-components"

interface CircleProps {
	color?: Property.Color
}

export const Circle = styled.div<CircleProps>`
	width: 76px;
	height: 76px;
	border-radius: 100px;
	pointer-events: none;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	overflow: hidden;

	will-change: transform;

	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);

	background-color: ${(props) => (props.color ? props.color : "none")};
	background-size: 400% 400%;

	animation: gradientFade 8s ease infinite;

	position: relative;

	@keyframes gradientFade {
		0% {
			background-position: 0% 28%;
		}
		50% {
			background-position: 100% 73%;
		}
		100% {
			background-position: 0% 28%;
		}
	}
`

export const CircleOverlay = styled.div`
	position: absolute;
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;

	border-radius: 100px;

	background-size: cover;
	background-position: center center;
`

export const Video = styled.div`
	position: absolute;
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;

	z-index: 1;
	will-change: transform;

	border-radius: 100px;
	overflow: hidden;
`

export const StatusIndicator = styled.div`
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 100px;
	width: 20px;
	height: 20px;

	will-change: transform;

	display: flex;
	align-items: center;
	justify-content: center;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
	z-index: 10;
`

export const StatusIndicatorImage = styled.img.attrs({ draggable: false })`
	width: 16px;
	height: 16px;
`

export const LoadingIndictatorImage = styled.img.attrs({ draggable: false })`
	width: 30px;
	height: 30px;
	animation: rotation 1s steps(8) infinite;

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

export const AvatarNameContainer = styled.div`
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 8px;
	padding-right: 20px;
	padding-top: 5px;
	padding-bottom: 5px;

	border: 2px solid;
	border-radius: 1000px;
`
