export class Ping {
	freq: number

	lastLatency: number
	latency: number | undefined
	maxLength: number
	latencyHistory: number[]

	sendTime: number | undefined
	retreiveTime: number | undefined

	interval: NodeJS.Timeout

	emit: () => void

	constructor(freq: number, emit: () => void, maxLength = 10) {
		this.freq = freq
		this.emit = emit
		this.latencyHistory = []
		this.maxLength = maxLength
		this.lastLatency = 0
		this.interval = setInterval(this.sendPing, freq)
	}

	sendPing = () => {
		this.sendTime = new Date().getTime()
		this.emit()
	}

	retreivePing = () => {
		if (!this.sendTime) return

		this.retreiveTime = new Date().getTime()
		const latency = this.retreiveTime - this.sendTime

		if (this.latencyHistory.length >= this.maxLength) this.latencyHistory.shift()
		this.lastLatency = latency / 2
		this.latencyHistory.push(this.lastLatency)

		const sortedLatencyHistory = [...this.latencyHistory].sort()

		this.latency = sortedLatencyHistory[Math.floor(sortedLatencyHistory.length / 2)]
	}
}
