import { Html } from "@react-three/drei"
import React, { ForwardedRef, forwardRef, memo, Suspense } from "react"
import { Mesh } from "three"
import LockClosed from "../../assets/images/lockClosed.svg"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { Circle, Image } from "./group_style"

interface GroupProps {
	isPrivate: boolean
}

// TODO: memoize
const GroupEntity = memo(
	forwardRef<Mesh, GroupProps>(({ isPrivate }, ref: ForwardedRef<Mesh>) => {
		// Reloading
		const logs = useDebugStore((state) => state.logs)
		const debug = useSettingsStore((state) => state.debug)
		if (debug && logs.reload) console.log("Group Reload")

		return (
			<Suspense fallback={null}>
				<mesh ref={ref} position={[0, 1000, 0]}>
					<Html distanceFactor={0.01} zIndexRange={[0, 0]} center={true}>
						{isPrivate && (
							<Circle>
								<Image src={LockClosed} />
							</Circle>
						)}
					</Html>
				</mesh>
			</Suspense>
		)
	})
)

export default GroupEntity
