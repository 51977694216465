import { memo, useEffect } from "react"
import Debug from "../../components/debug/debug"
import Camera from "../../entities/camera/camera"
import DotPlane from "../../entities/dotPlane/dotPlane"
import MetaballPlane from "../../entities/metaballPlane/metaballPlane"
import AreaManager from "../../manager/areaManager/areaManager"
import AudioManager from "../../manager/audioManager/audioManager"
import AvatarManager from "../../manager/avatarManager/avatarManager"
import DragManager from "../../manager/dragManager/dragManager"
import GroupManager from "../../manager/groupManager/groupManager"
import OwnerMovementManager from "../../manager/movementManager/ownerMovementManager"
import VisibilityManager from "../../manager/visibilityManager/visibilityManager"
import { useDebugStore } from "../../stores/debugStore"
import { useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"

interface StartSceneProps {
	id: string
}

const StartScene = memo<StartSceneProps>(({ id }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Default Scene Reload")

	// Stores
	const initialize = useSceneStore((state) => state.initialize)

	useEffect(() => {
		initialize(id)
	}, [initialize, id])

	return (
		<>
			<Camera />
			<AudioManager />
			<AvatarManager />
			<GroupManager />
			<DragManager />
			<OwnerMovementManager />
			<VisibilityManager />
			<AreaManager />
			<MetaballPlane />
			<DotPlane />
			<Debug />
		</>
	)
})

export default StartScene
