import { useCallback, useState, useEffect } from "react"

export const useHandleChange = (initial: any) => {
	const [value, setValue] = useState(initial)
	const handleChange = useCallback((event) => {
		setValue(event.target.value)
	}, [])

	useEffect(() => {
		setValue(initial)
	}, [initial])

	return [value, handleChange]
}
