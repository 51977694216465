import { shallow } from "zustand/shallow"
import Avatar from "../../entities/avatar/avatar"
import AvatarTarget from "../../entities/avatar/avatarTarget"
import { useAvatarStore } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"

const AvatarManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Avatar Manager Reload")

	// Stores
	const users = useAvatarStore((state) => Object.keys(state.users), shallow)

	return (
		<>
			{users.map((id) => {
				return (
					<group key={id}>
						<Avatar id={id} />
						<AvatarTarget id={id} />
					</group>
				)
			})}
		</>
	)
}

export default AvatarManager
