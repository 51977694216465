import React, { createRef, memo, RefObject, useMemo } from "react"
import { Mesh } from "three"
import GroupEntity from "../../entities/group/group"
import { useDebugStore } from "../../stores/debugStore"
import { Group } from "../../stores/groupStore"
import { useSettingsStore } from "../../stores/settingsStore"

interface GroupMemoManagerProps {
	groups: Record<string, Group>
}

const GroupMemoManager = memo<GroupMemoManagerProps>(({ groups }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Group Memo Manager Reload")

	const groupsData = useMemo(() => {
		return Object.keys(groups).map((id) => {
			let isPrivate = false
			const group = groups[id]
			var groupRef: RefObject<Mesh> | undefined = undefined

			if (group) {
				if (!group.ref) {
					groupRef = createRef()
					groups[id].ref = groupRef
				} else {
					groupRef = group.ref
				}

				isPrivate = group.private
			}

			return {
				id,
				ref: groupRef,
				isPrivate: isPrivate,
			}
		})
	}, [groups])

	return (
		<>
			{groupsData.map((group) => (
				<group key={group.id}>
					<GroupEntity ref={group.ref} isPrivate={group.isPrivate} />
				</group>
			))}
		</>
	)
})

export default GroupMemoManager
