import { useSceneStore } from "../../stores/sceneStore"
import YouTube from "../youtube/youtube"
import { Container } from "./interactableView_style"

const InteractableYoutubeView = () => {
	const isInitial = useSceneStore((state) => state.isInitial)

	return (
		<Container style={{ marginBottom: "-4px" }}>
			<YouTube
				opts={{
					width: "550px",
					height: "345px",
				}}
				videoId="HCo6X6LIuwo"
				onReady={(e) => {
					if (!isInitial) {
						e.target.playVideo()
					}
				}}
			/>
		</Container>
	)
}

export default InteractableYoutubeView
