import styled from "styled-components"
import Background from "../../assets/images/blue.png"

export const Container = styled.div`
	position: absolute;

	width: 100%;
	height: 100%;

	z-index: -1;

	background: ${(props) => (props.background !== undefined ? props.background : `url(${Background})`)};
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	-webkit-user-select: none;
`
