import { Button, SpeakerType } from "@saysom/shared"
import { Properties } from "csstype"
import React from "react"
import ScreenshareOff from "../../assets/images/screenshareOff.svg"
import ScreenshareOn from "../../assets/images/screenshareOn.svg"
import { useAvatarStore } from "../../stores/avatarStore"
import { useCommunicationStore } from "../../stores/communicationStore"
import { useSpeakerStore } from "../../stores/speakerStore"

const ScreenShareControls = ({ style }: { style?: Properties }) => {
	const requestChange = useSpeakerStore((state) => state.request.change)
	const ownerIsScreenShareSpeaker = useSpeakerStore(
		(state) => state.speaker["owner"]?.speakerType === SpeakerType.ScreenShare
	)
	const ownerIsMegaphoneSpeaker = useSpeakerStore((state) => state.speaker["owner"] !== undefined)
	const ownerId = useAvatarStore((state) => state.users["owner"]?.id)

	const room = useCommunicationStore((state) => state.room)

	const handleMegaphone = () => {
		if (ownerId) {
			if (ownerIsScreenShareSpeaker) {
				room?.stopScreenShare()
				requestChange(ownerId, SpeakerType.Megaphone)
			} else {
				room?.startScreenShare()
				requestChange(ownerId, SpeakerType.ScreenShare)
			}
		}
	}

	return (
		<>
			{ownerIsMegaphoneSpeaker && (
				<Button
					onClick={() => handleMegaphone()}
					image={ownerIsScreenShareSpeaker ? ScreenshareOff : ScreenshareOn}
					style={style}
				/>
			)}
		</>
	)
}

export default ScreenShareControls
