import { BASE_URL } from "../constants/BaseUrl"
import { config } from "../config"

export const formatUrlWithBaseUrl = (
	baseUrl: string,
	url: string,
	parameter?: Record<string, string | number | boolean>,
	withBaseUrl = true
) => {
	if (!parameter || parameter === null) {
		return baseUrl + url
	}

	Object.keys(parameter).forEach((key) => (parameter[key] === undefined ? delete parameter[key] : {}))

	const esc = encodeURIComponent
	const query = Object.keys(parameter)
		.map((k) => {
			return esc(k) + "=" + esc(parameter[k])
		})
		.join("&")

	let final = url
	if (query.length > 0) {
		final += "?" + query
	}

	if (withBaseUrl) {
		final = baseUrl + final
	}

	return final
}

const formatUrl = (url: string, parameter?: Record<string, string | number | boolean>, withBaseUrl = true) => {
	return formatUrlWithBaseUrl(BASE_URL, url, parameter, withBaseUrl)
}

export default formatUrl

export const urlForSpaceId = (spaceId: string): string => {
	const url = config.servers[spaceId]
	if (url) {
		return url
	} else {
		return BASE_URL
	}
}
