import { VerticalGroup } from "@saysom/shared"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Button, CenterContainer, H1, H2 } from "../../assets/style"
import { useSocketStore } from "../../stores/socketStore"
import { SpaceIdParams } from "./join"

const Over = () => {
	const { spaceId } = useParams<SpaceIdParams>()

	const leave = useSocketStore((state) => state.leave)

	useEffect(() => {
		leave()
	}, [leave])

	return (
		<div
			style={{
				width: "100%",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<CenterContainer width="500px">
				<H1 style={{ marginBottom: "20px" }}>The time contingent of this space is used!</H1>

				<VerticalGroup spacing={10} style={{ margin: "20px 0" }} alignItems="center">
					<H2 style={{ textAlign: "center" }}>Please contact the space owner to extend the time contingent.</H2>
					<Button
						style={{ marginTop: "20px" }}
						onClick={() => {
							window.location.href = "https://saysom.app"
						}}
					>
						Back to SaySom
					</Button>
				</VerticalGroup>
			</CenterContainer>
		</div>
	)
}

export default Over
