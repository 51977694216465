export const text = {
	icebreaker: [
		"Biggest challenge within the last year?",
		"Your biggest Learning within the last year?",
		"What do you like most about your job?",
		"Share one Home office tip!",
		"Are you type Remote-first or Office-first?",
		"What's your reality-TV guilty pleasure?",
		"What projects are you currently working on?",
		"What motivated you to come to H.U.G Digital?",
		"What was your first job ?",
	],
	explainSubject: [
		"Leadership",
		"Mindfulness",
		"New Work",
		"Corporate Culture",
		"Digital Leadership",
		"Sustainability",
		"Home Office",
		"Resilience",
		"OKRs",
		"Hybrid work culture",
		"Employee loyalty",
	],
	findPerson: [
		"with 2 matching interests",
		"that you don't know yet",
		"with a pet",
		"with the same job title",
		"who lives near you",
		"who listens to the same podcast as you",
		"who is watching the same show as you",
		"who has a birthday in the same month as you",
	],
}
