import { useFrame } from "@react-three/fiber"
import React, { MutableRefObject, useRef } from "react"
import * as THREE from "three"
import { Camera, Clock, Vector3 } from "three"
import { Owner, useAvatarStore } from "../../stores/avatarStore"
import { config } from "../../config"
import { useDebugStore } from "../../stores/debugStore"
import { useInputStore } from "../../stores/inputStore"
import { Target, useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSocketStore } from "../../stores/socketStore"
import { TimeSyncer } from "../../utils/time"
import { useAreaStore } from "../../stores/areaStore"

// Mouse speed properties
const stoppingDistance = 0.1
const sneakingSpeed = 0.8
const sneakingRadius = 0.3
const walkingSpeed = 1.34
const walkingRadius = 2
const runningSpeed = 5
const runningRadius = 5
var speed = 0

// Keyboard speed properties
const walkingDistance = 6
const runningDistance = 14

// Reset Properties
const resetSpeed = 3.0

const OwnerMovementManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Owner Movement Manager Reload")

	// Stores
	const bounds = useSceneStore((state) => state.floor.bounds)
	const targetType = useSceneStore((state) => state.target.type)

	// Stores
	// const scene = useSceneStore((state) => state)
	const emit = useSocketStore.getState().emit
	const layerMask = useSceneStore((state) => state.layerMask)
	const floor = useSceneStore((state) => state.floor)
	const mouse = useInputStore((state) => state.mouse)
	const owner = useAvatarStore((state) => state.users["owner"] as Owner)
	const keyboard = useInputStore((state) => state.keyboard)
	const updateIsMoving = useAvatarStore((state) => state.actions.updateIsMoving)
	const tickRate = config.client.tickRate
	const timeSyncer = useSocketStore((state) => state.timeSyncer)
	const updateIsSignaling = useAvatarStore((state) => state.actions.updateIsSignaling)
	const updateArea = useAvatarStore((state) => state.actions.updateArea)

	// Properties
	const clock = new Clock()
	const movementUpdateDidOccur = useRef(false)
	const previousSentPositionTime: MutableRefObject<number | undefined> = useRef(undefined)

	const sendPositionUpdate = () => {
		if (!timeSyncer) return
		if (!movementUpdateDidOccur?.current) return
		if (!(owner?.meshTarget && owner?.mesh)) return
		if (!previousSentPositionTime.current) {
			previousSentPositionTime.current = (timeSyncer as TimeSyncer).serverTime
			return
		}

		const currentTime = (timeSyncer as TimeSyncer).serverTime
		if (!currentTime) return

		if (currentTime - previousSentPositionTime.current < tickRate) return

		emit.position([
			owner.mesh.position.x,
			owner.mesh.position.z,
			owner.meshTarget.position.x,
			owner.meshTarget.position.z,
		])

		previousSentPositionTime.current = currentTime
		movementUpdateDidOccur.current = false
	}

	const determineTargetDirectionFromKeyboard = () => {
		const movementDirection = new THREE.Vector3(0, 0, 0)

		var fast = false

		keyboard.keysDown.forEach((key) => {
			if (key === "arrowup" || key === "w") {
				movementDirection.add(new THREE.Vector3(0, 0, -1))
			}

			if (key === "arrowleft" || key === "a") {
				movementDirection.add(new THREE.Vector3(-1, 0, 0))
			}

			if (key === "arrowright" || key === "d") {
				movementDirection.add(new THREE.Vector3(1, 0, 0))
			}

			if (key === "arrowdown" || key === "s") {
				movementDirection.add(new THREE.Vector3(0, 0, 1))
			}

			if (key === "p") {
				updateIsSignaling("owner", true)
			}

			if (key === "shift") {
				fast = true
			}
		})
		const radius = fast ? runningDistance : walkingDistance

		return movementDirection.normalize().multiplyScalar(radius)
	}

	const determineOwnerSpeed = (distance, delta) => {
		var speedForDistance = 0

		if (distance > sneakingRadius && distance < walkingRadius) {
			speedForDistance = sneakingSpeed
		} else if (distance > walkingRadius && distance < runningRadius) {
			speedForDistance = walkingSpeed
		} else if (distance > runningRadius) {
			speedForDistance = runningSpeed
		}

		// TODO: Check with diffreren frame rates
		speed = THREE.MathUtils.lerp(speed, speedForDistance, delta)
	}

	// Set Owner Position
	const setTargetPosition = (camera: Camera, delta: number) => {
		if (!(owner?.meshTarget && owner?.mesh)) return

		if (mouse.pressed) {
			// Move target to mouse position

			// Raycast
			var raycaster = new THREE.Raycaster()
			const mouseVector = new THREE.Vector2(mouse.pos[0], mouse.pos[1])
			raycaster.setFromCamera(mouseVector, camera)

			// Raycast Layer
			raycaster.layers.set(layerMask)

			// Mesh needs to be loaded
			if (!floor.mesh) return

			// Intersection with background
			var intersects = raycaster.intersectObject(floor.mesh, false)
			const intersection = intersects[0]

			// Plane must be under mouse
			if (!(intersection && intersection.point)) return

			// Update Target
			owner.meshTarget.position.set(intersection.point.x, intersection.point.y, intersection.point.z)
		} else if (keyboard.keysDown.size > 0) {
			const movementDir = determineTargetDirectionFromKeyboard()
			const ownerPos = owner.mesh.position.clone()

			// Reset target position
			owner.meshTarget.position.lerp(owner.mesh.position, delta * resetSpeed)

			const newOwnerTargetPos = ownerPos.add(movementDir)
			owner.meshTarget.position.lerp(newOwnerTargetPos, delta * resetSpeed)
		} else {
			// Move target to owner position
			const ownerDistanceToTarget = owner.mesh.position.distanceTo(owner.meshTarget.position)

			// Target should be greater than stopping distance
			if (ownerDistanceToTarget <= stoppingDistance) return

			// Reset target position
			owner.meshTarget.position.lerp(owner.mesh.position, delta * resetSpeed)

			// Stop Moving
			//updateDidMove(false)
			updateIsMoving(false)
		}
	}

	// Set Owner Position
	const setOwnerPosition = (delta: number) => {
		if (!(owner?.meshTarget && owner?.mesh)) return

		// Mouse must be pressed
		if (!mouse.pressed && keyboard.keysDown.size === 0) return

		const ownerDistanceToTarget = owner.mesh.position.distanceTo(owner.meshTarget.position)

		// Target should not be reached
		if (ownerDistanceToTarget <= stoppingDistance) return

		var ownerPosition3D = owner.mesh.position.clone()
		var ownerTargetPositionClone = owner.meshTarget.position.clone()
		var ownerDirection = ownerTargetPositionClone.sub(ownerPosition3D).normalize()

		determineOwnerSpeed(ownerDistanceToTarget, delta)
		ownerPosition3D.add(ownerDirection.multiplyScalar(delta).multiplyScalar(speed))
		ownerPosition3D.clamp(new Vector3(-bounds[0] / 2, 0, -bounds[1] / 2), new Vector3(bounds[0] / 2, 0, bounds[1] / 2))

		// Update position
		owner.mesh.position.set(ownerPosition3D.x, ownerPosition3D.y, ownerPosition3D.z)

		// Moving
		movementUpdateDidOccur.current = true
		updateIsMoving(true)
	}

	const setOwnerAreaPosition = () => {
		if (owner) {
			const areas = useAreaStore.getState().areas
			let foundArea: string | undefined = undefined
			Object.values(areas).forEach((area) => {
				if (owner.mesh && area) {
					if (owner.mesh.position.x >= area.position.x && owner.mesh.position.x <= area.position.x + area.size.width) {
						if (
							owner.mesh.position.z >= area.position.y &&
							owner.mesh.position.z <= area.position.y + area.size.height
						) {
							foundArea = area.key
						}
					}
				}
			})

			if (foundArea) {
				if (owner.areaId !== foundArea) updateArea("owner", foundArea)
			} else {
				if (owner.areaId) updateArea("owner", undefined)
			}
		}
	}

	useFrame(({ camera }) => {
		if (targetType !== Target.Owner) return

		const delta = clock.getDelta() // (!) Do not change
		setTargetPosition(camera, delta)
		setOwnerPosition(delta)
		sendPositionUpdate()
		setOwnerAreaPosition()
	})

	return <> </>
}

export default OwnerMovementManager
