import { useFrame } from "@react-three/fiber"
import { MutableRefObject, useEffect, useRef } from "react"
import { Vector2 } from "three"
import { config } from "../../config"
import { Bot, Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useGroupStore } from "../../stores/groupStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { centroid } from "../../utils/centroid"
import GroupMemoManager from "./groupMemoManager"

const GroupManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Group Manager Reload")

	// Stores
	const ownerId = useAvatarStore((state) => state.users["owner"]?.id)
	const ownerMesh = useAvatarStore((state) => state.users["owner"]?.mesh)
	const ownerGroupId = useAvatarStore((state) => state.users["owner"]?.groupId)
	const audioConfig = config.audio
	const groups = useGroupStore((state) => state.groups)
	const connectedUserGroup = useGroupStore((state) => state.connectedUser.group)
	const setClosestUser = useGroupStore((state) => state.connectedUser.setClosestUser)

	const users: MutableRefObject<Record<string, User | Owner | Bot>> = useRef({})
	useEffect(() => {
		useAvatarStore.subscribe((state) => (users.current = state.users))
	}, [])

	const setGroupPosition = () => {
		Object.values(groups).forEach((group) => {
			if (!(group && group.ref && group.ref.current)) return

			const userPositions = [...group.users].map((userId) => {
				if (userId === ownerId) {
					if (!ownerMesh) return undefined
					return new Vector2(ownerMesh.position.x, ownerMesh.position.z)
				} else {
					const user = users.current[userId]
					if (!(user && user.mesh)) return undefined
					return new Vector2(user.mesh.position.x, user.mesh.position.z)
				}
			})

			const centroidPos = centroid(userPositions)

			group.ref.current.position.set(centroidPos.x, 0, centroidPos.y)
		})
	}

	const findConnectedUser = () => {
		if (!ownerMesh) return

		// Closest user
		var closestUser: User | undefined = undefined
		var minDistance = Number.MAX_VALUE

		// Owner current position
		const ownerPos = ownerMesh.position

		// Loop users
		Object.entries(users.current).forEach(([id, user]) => {
			if (!user.mesh) return
			if (id === "owner") return
			if (!user.isVisible) return

			// User current position
			const userPos = user.mesh.position
			const dist = userPos.distanceTo(ownerPos)

			let isInOtherPrivateGroup = false
			if (user.groupId && ownerGroupId !== user.groupId && groups[user.groupId]) {
				isInOtherPrivateGroup = groups[user.groupId].private
			}

			// Divide into groups
			if (dist <= audioConfig.maxHearDist && !isInOtherPrivateGroup) {
				if (dist < minDistance) {
					minDistance = dist
					closestUser = user as User
				}

				if (!connectedUserGroup.userIds.has(id)) connectedUserGroup.join(id)
			} else {
				if (connectedUserGroup.userIds.has(id)) connectedUserGroup.leave(id)
			}
		})

		// Save closest user
		setClosestUser(closestUser)
	}

	useFrame(() => {
		setGroupPosition()
		findConnectedUser()
	})

	return <GroupMemoManager groups={groups} />
}

export default GroupManager
