import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react"
import { Container, FileInput, Preview, Select } from "./imagePicket_style"

const ImagePicker = ({ existing, onChange, style }: { existing: any; onChange: Function; style?: CSSProperties }) => {
	const fileInput = useRef<HTMLInputElement>(null)
	const [file, setFile] = useState<File | undefined>(undefined)
	const [showSelect, setShowSelect] = useState(true)
	const [source, setSource] = useState(undefined)
	const [isDragging, setIsDragging] = useState(false)

	const showFilePicker = () => {
		if (fileInput.current) {
			fileInput.current.click()
		}
	}

	useEffect(() => {
		if (existing) {
			setShowSelect(false)
		}
	}, [existing])

	useEffect(() => {
		let source
		if (file) {
			source = URL.createObjectURL(file)
		} else if (existing) {
			if (existing.name) {
				source = URL.createObjectURL(existing)
			} else if (existing.url) {
				source = existing.url
			} else {
				source = existing
			}
		}

		setSource(source)
	}, [file, existing])

	const _onSetFile = useCallback(
		(file: File) => {
			setShowSelect(false)
			setFile(file)
			onChange(file)
		},
		[setShowSelect, setFile, onChange]
	)

	return (
		<Container
			className="rounded-lg"
			onMouseEnter={() => setShowSelect(true)}
			onMouseLeave={() => setShowSelect(file ? false : true)}
			onDrop={(event) => {
				event.preventDefault()

				if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items.length > 0) {
					const files = event.dataTransfer.items
					const file = files[0].getAsFile()
					if (file && file.type.includes("image")) {
						_onSetFile(file)
					}
				}

				setIsDragging(false)
			}}
			onDragOver={(event) => {
				event.preventDefault()

				setIsDragging(true)
			}}
			onDragEnter={(event) => {
				event.preventDefault()

				setIsDragging(true)
			}}
			onDragLeave={(event) => {
				event.preventDefault()

				setIsDragging(false)
			}}
			onClick={showFilePicker}
			style={{ cursor: "pointer", ...style }}
			isDragging={isDragging}
		>
			<FileInput
				ref={fileInput}
				type="file"
				accept="image/png, image/jpeg"
				onChange={({ target }) => {
					if (target?.files) {
						const file = target.files[0]

						_onSetFile(file)
					}
				}}
			/>

			{source && <Preview src={source} />}

			<Select opacity={showSelect ? 1.0 : 0.0}>
				<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 cursor-pointer">
					<path d="M14 4a2 2 0 100 4 2 2 0 000-4z" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0 2a2 2 0 012-2h16a2 2 0 012 2v16a2.012 2.012 0 01-.238.947A2 2 0 0118 20H2a2 2 0 01-2-2V2zm6.466 2.19L6 3.724 1.333 8.39V2c0-.368.299-.667.667-.667h16c.368 0 .667.299.667.667v11.333h-3.058L6.472 4.195a.524.524 0 00-.005-.005z"
					/>
				</svg>
			</Select>
		</Container>
	)
}

export default ImagePicker
