import { Button, HorizontalGroup, ModeratorType, VerticalContainer, VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import { useEffect, useMemo, useState } from "react"
import Back from "../../assets/images/back.svg"
import Close from "../../assets/images/close.svg"
import { H3 } from "../../assets/style"
import { customSpaceForSpaceCode } from "../../config"
import { useAreaStore } from "../../stores/areaStore"
import { useAvatarStore } from "../../stores/avatarStore"
import { useChatStore } from "../../stores/chatStore"
import { useSpaceStore } from "../../stores/spaceStore"
import BotEditor from "../botEditor/botEditor"
import Chat from "../chat/chat"
import GameSelection from "../game/gameSelection"
import { PanelType } from "../interface/interface"
import ParticipantList from "../participants/participantList"
import Settings from "../settings/settings"
import AreaEditor from "../spaceEditor/areaEditor"
import SpaceEditor from "../spaceEditor/spaceEditor"
import SpaceJoiner from "../spaceSelector/spaceJoiner"
import SpaceSelector from "../spaceSelector/spaceSelector"

interface PanelProps {
	moderatorType: ModeratorType
	selectedPanel: PanelType
	onSelectedPanelChange: (panelType: PanelType, isGroup?: boolean) => void
	style?: Properties
}

const Panel = ({ moderatorType, selectedPanel, onSelectedPanelChange, style }: PanelProps) => {
	// Store
	const isGroupChat = useChatStore((state) => state.isGroupChat)
	const updateIsGroupChat = useChatStore((state) => state.updateIsGroupChat)
	const openChatId = useChatStore((state) => state.openChatId)
	const updateOpenChatId = useChatStore((state) => state.updateOpenChatId)
	const selectedAreaId = useAreaStore((state) => state.selectArea.id)
	const setSelectedArea = useAreaStore((state) => state.selectArea.update.set)
	const resetSelectedArea = useAreaStore((state) => state.selectArea.update.reset)
	const resetSpace = useSpaceStore((state) => state.updateSpace.reset)
	const areaTitle = useAreaStore((state) => {
		return selectedAreaId ? state.areas[selectedAreaId]?.title : "Area"
	})
	const chatUserName = useAvatarStore((state) => {
		return openChatId ? state.users[openChatId]?.name : " "
	})

	useEffect(() => {
		if (selectedPanel === PanelType.Announcements) {
			updateOpenChatId("all")
			updateIsGroupChat(false)
		} else {
			if (selectedPanel !== PanelType.Chat) {
				updateOpenChatId(undefined)
				updateIsGroupChat(false)
			}
		}

		if (selectedPanel !== PanelType.Area) {
			resetSelectedArea()
		}

		if (selectedPanel !== PanelType.Space) {
			resetSpace()
		}

		if (selectedPanel === PanelType.Area && selectedAreaId === undefined) {
			onSelectedPanelChange(PanelType.Space)
		}
	}, [
		selectedPanel,
		updateOpenChatId,
		updateIsGroupChat,
		resetSelectedArea,
		selectedAreaId,
		onSelectedPanelChange,
		resetSpace,
	])

	const panelName = (selectedPanel) => {
		switch (selectedPanel) {
			case PanelType.Settings:
				return "Settings"

			case PanelType.Announcements:
				return "Announcements"

			case PanelType.Games:
				return "Games"

			case PanelType.Spaces:
				return "Spaces"

			case PanelType.Participants:
				return "Participants"

			case PanelType.Bots:
				return "Bots"

			case PanelType.Chat:
				return isGroupChat ? "Current Group" : chatUserName

			case PanelType.Space:
				return "Space"

			case PanelType.Area:
				return areaTitle ? (areaTitle.length > 0 ? areaTitle : "Area") : "Area"
		}
		return ""
	}

	return (
		<VerticalContainer spacing={10} style={{ width: "350px", ...style, overflow: "hidden" }}>
			{![PanelType.Space, PanelType.Area].includes(selectedPanel) && (
				<HorizontalGroup alignItems="center" justifyContent="space-between">
					<HorizontalGroup alignItems="center" spacing={5}>
						{[PanelType.Chat].includes(selectedPanel) && (
							<Button
								image={Back}
								onClick={() => {
									onSelectedPanelChange(PanelType.Participants)
								}}
							/>
						)}
						<H3>{panelName(selectedPanel)}</H3>
					</HorizontalGroup>

					<Button
						image={Close}
						onClick={() => {
							onSelectedPanelChange(PanelType.None)
						}}
					/>
				</HorizontalGroup>
			)}

			{selectedPanel === PanelType.Participants && (
				<ParticipantList
					onSelectedChatUserChange={(userId, isGroup) => {
						updateOpenChatId(userId)
						onSelectedPanelChange(PanelType.Chat)
						updateIsGroupChat(isGroup ? isGroup : false)
					}}
				/>
			)}
			{selectedPanel === PanelType.Chat && <Chat id={openChatId} isGroupChat={isGroupChat} />}
			{selectedPanel === PanelType.Announcements && <Chat moderatorType={moderatorType} />}
			{selectedPanel === PanelType.Games && <GameSelection />}
			{selectedPanel === PanelType.Spaces && <SpacesPanel />}
			{selectedPanel === PanelType.Settings && <Settings />}
			{selectedPanel === PanelType.Bots && moderatorType === ModeratorType.Admin && <BotEditor />}
			{selectedPanel === PanelType.Area && selectedAreaId && (
				<AreaEditor onBack={() => onSelectedPanelChange(PanelType.Space)} id={selectedAreaId} />
			)}
			{selectedPanel === PanelType.Space && (
				<SpaceEditor
					onSelectedAreaChange={(areaId) => {
						setSelectedArea(areaId)
						onSelectedPanelChange(PanelType.Area)
					}}
				/>
			)}
		</VerticalContainer>
	)
}

const SpacesPanel = () => {
	const space = useSpaceStore((state) => state.space)

	const spaces = useMemo(() => {
		if (space && space.code) {
			const customSpace = customSpaceForSpaceCode(space.code)
			if (customSpace && customSpace.rooms) {
				return customSpace.rooms
			}
		}

		return []
	}, [space])

	const [selectedRoom, setSelectedRoom] = useState(spaces[0])

	return (
		<VerticalGroup flex="1" spacing={100}>
			<SpaceJoiner small spaceId={selectedRoom} />

			<SpaceSelector
				small
				spaces={spaces}
				onSelect={(id: string) => {
					setSelectedRoom(id)
				}}
			/>
		</VerticalGroup>
	)
}

export default Panel
