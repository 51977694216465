import { Shadows, VerticalGroup } from "@saysom/shared"
import styled from "styled-components"

export const Container = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: white;
	z-index: 3;

	display: flex;
	align-items: center;
	justify-content: center;

	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	@media (max-width: 450px) {
		width: 100vw;
	}
`

export const Mobile = styled.div`
	position: relative;
	width: 450px;

	@media (max-height: 800px) {
		height: 100vh;
		top: 0;
	}

	@media (max-width: 450px) {
		width: 100vw;
	}
`

export const InnerContainer = styled(VerticalGroup)`
	height: auto;

	padding: 20px;
`

export const CameraContainer = styled.div`
	width: 300px;
	height: 300px;

	border: 5px solid #ffffff;
	box-sizing: border-box;

	box-shadow: ${Shadows.highlighted};
	border-radius: 1000px;
	background-color: lightgrey;

	overflow: hidden;

	display: flex;
	align-items: stretch;
	justify-content: center;

	-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
`

export const InputContainer = styled(VerticalGroup)`
	width: 450px;

	@media (max-width: 450px) {
		width: 100vw;
		padding: 20px;
	}
`

export const InputGroup = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;

	@media (max-width: 450px) {
		align-items: flex-start;
		flex-direction: column;
	}
`
