import { Button, HorizontalGroup, Input, VerticalGroup } from "@saysom/shared"
import Plus from "../../assets/images/plus.svg"
import { H3 } from "../../assets/style"
import { useAreaStore } from "../../stores/areaStore"
import { useInputStore } from "../../stores/inputStore"
import { useSpaceStore } from "../../stores/spaceStore"
import { CancelButton, default as ActionButton } from "../button/button"
import ImagePicker from "../imagePicker/imagePicker"
import ImageTile from "../imageTile/imageTile"
import AreaListElement from "./areaListElement"

interface SpaceEditorProps {
	onSelectedAreaChange: (areaId: string) => void
}

const SpaceEditor = ({ onSelectedAreaChange }: SpaceEditorProps) => {
	// Stores
	const areas = useAreaStore((state) => state.areas)
	const actions = useInputStore((state) => state.actions)
	const logoImage = useSpaceStore((state) => state.space?.logoImage)
	const logoUrl = useSpaceStore((state) => state.space?.logoUrl)
	const title = useSpaceStore((state) => state.space?.title)
	const moderatorColor = useSpaceStore((state) => state.space?.colorScheme.moderator)
	const roomColor = useSpaceStore((state) => state.space?.colorScheme.roomColor)
	const startDate = useSpaceStore((state) => state.space?.startDate)

	const updateLogoImage = useSpaceStore((state) => state.updateSpace.logoImage)
	const updateModeratorColor = useSpaceStore((state) => state.updateSpace.moderatorColor)
	const updateRoomColorTopLeft = useSpaceStore((state) => state.updateSpace.roomColorTopLeft)
	const updateRoomColorBottomRight = useSpaceStore((state) => state.updateSpace.roomColorBottomRight)
	const updateTitle = useSpaceStore((state) => state.updateSpace.title)
	const updateIsPrivate = useSpaceStore((state) => state.updateSpace.isPrivate)
	const updateStartDate = useSpaceStore((state) => state.updateSpace.startDate)
	const initArea = useAreaStore((state) => state.requestArea.init)
	const updateSpace = useSpaceStore((state) => state.requestSpace.update)
	const resetSpace = useSpaceStore((state) => state.updateSpace.reset)
	const didChange = useSpaceStore((state) => state.updateSpace.didChange)

	const handleFoucs = () => {
		actions.updateInputFocus(true)
	}

	const handleBlur = () => {
		actions.updateInputFocus(false)
	}

	return (
		<>
			<HorizontalGroup justifyContent="space-between" alignItems="center">
				<H3>{title ? (title.length > 0 ? title : "Area") : "Area"}</H3>

				<HorizontalGroup spacing={5} className={`transition-opacity ${didChange ? "opacity-100" : "opacity-0"}`}>
					<CancelButton title="Cancel" onClick={resetSpace} />
					<ActionButton title="Save" onClick={updateSpace} />
				</HorizontalGroup>
			</HorizontalGroup>

			<div className="flex h-full flex-col overflow-hidden flex-1 space-y-4">
				<div className="flex space-y-2 flex-col">
					<HorizontalGroup className="space-x-2">
						<VerticalGroup>
							<label className="text-black text-opacity-50">Logo</label>
							<ImagePicker
								existing={logoImage ?? logoUrl}
								onChange={(file: File) => {
									updateLogoImage(file, true)
								}}
							/>
						</VerticalGroup>

						<VerticalGroup className="flex-1">
							<label className="text-black text-opacity-50">Space Title</label>
							<Input
								type="text"
								value={title ?? ""}
								placeholder="Title"
								onChange={(value) => updateTitle(value, true)}
								onFocus={handleFoucs}
								onBlur={handleBlur}
								style={{ margin: 0 }}
							/>
						</VerticalGroup>
					</HorizontalGroup>

					<HorizontalGroup alignItems="center" justifyContent="space-between">
						<label className="text-black text-opacity-50">Room Color</label>

						<div
							style={{
								background: `linear-gradient(135deg, ${roomColor ? roomColor[0] : "white"} 0%, ${
									roomColor ? roomColor[1] : "white"
								} 100%)`,
							}}
							className="flex flex-row rounded-full p-1 space-x-2"
						>
							<ImageTile
								color={roomColor ? roomColor[0] : "white"}
								onChange={(color) => updateRoomColorBottomRight(color, true)}
							/>
							<ImageTile
								color={roomColor ? roomColor[1] : "white"}
								onChange={(color) => updateRoomColorTopLeft(color, true)}
							/>
						</div>
					</HorizontalGroup>

					<HorizontalGroup alignItems="center" justifyContent="space-between" className="pr-1">
						<label className="text-black text-opacity-50">Moderator Color</label>

						<ImageTile color={moderatorColor ?? "white"} onChange={(color) => updateModeratorColor(color, true)} />
					</HorizontalGroup>
				</div>

				<VerticalGroup className="overflow-hidden space-y-2">
					<HorizontalGroup justifyContent="space-between" alignItems="center">
						<H3>Areas</H3>

						<Button
							onClick={() => {
								initArea({
									position: { x: 0, y: 0 },
									size: { width: 2, height: 2 },
									title: "Area",
									backgroundColor: "white",
								})
							}}
							image={Plus}
						/>
					</HorizontalGroup>

					<VerticalGroup className="flex-1 rounded-md overflow-y-scroll">
						{areas &&
							Object.keys(areas).map((id, idx) => (
								<AreaListElement
									key={id}
									id={id}
									alternateRow={idx % 2 === 0}
									onSelectedAreaChange={(areaId) => onSelectedAreaChange(areaId)}
								/>
							))}
					</VerticalGroup>
				</VerticalGroup>
			</div>
		</>
	)
}

export default SpaceEditor
