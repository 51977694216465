import { Button, HorizontalContainer, HorizontalGroup, ModeratorType } from "@saysom/shared"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Tooltip } from "react-tooltip"
import Camera from "../../assets/images/camera.svg"
import CameraOff from "../../assets/images/cameraOff.svg"
import Microphone from "../../assets/images/microphone.svg"
import MicrophoneOff from "../../assets/images/microphoneOff.svg"
import ScreenshareOff from "../../assets/images/screenshareOff.svg"
import ScreenshareOn from "../../assets/images/screenshareOn.svg"
import { customSpaceForSpaceCode } from "../../config"
import { Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { useCommunicationStore } from "../../stores/communicationStore"
import { useSpaceStore } from "../../stores/spaceStore"
import GroupControls from "./groupControls"
import MegaphoneControls from "./megaphoneControls"
import ScreenShareControls from "./screenShareControls"

const ActionControlBar = ({ panelOpen }: { panelOpen: boolean }) => {
	const [isAudioMute, setIsAudioMute] = useState(false)
	const [isVideoMute, setIsCameraMute] = useState(false)

	const muteAudio = useCommunicationStore((state) => state.ioActions.muteAudio)
	const muteVideo = useCommunicationStore((state) => state.ioActions.muteVideo)

	const id = useAvatarStore.getState().users["owner"]?.id
	const moderatorType = (useAvatarStore.getState().users["owner"] as Owner | User)?.moderatorType

	const space = useSpaceStore((state) => state.space)

	const room = useCommunicationStore((state) => state.room)
	const ownerHasScreenShare = useCommunicationStore((state) => state.participants[id]?.screenVideoTrack)

	useEffect(() => {
		useCommunicationStore.subscribe((state) => {
			if (id === undefined) return

			const localParticipant = state.participants[id]
			if (localParticipant) {
				setIsAudioMute(!localParticipant.audio)
				setIsCameraMute(!localParticipant.video)
			}
		})
	}, [id])

	const handleScreenShare = useCallback(() => {
		if (id) {
			if (ownerHasScreenShare !== undefined) {
				room?.stopScreenShare()
			} else {
				room?.startScreenShare()
			}
		}
	}, [id, ownerHasScreenShare, room])

	const disableScreenShare = useMemo(() => {
		if (space && space.code) {
			const customSpace = customSpaceForSpaceCode(space.code)
			if (customSpace) {
				return customSpace.disableScreenshare ?? false
			}
		}

		return false
	}, [space])

	return (
		<>
			<Tooltip place="top" variant="dark" />

			<div
				style={{
					position: "absolute",
					bottom: "10px",
					left: "10px",
					right: panelOpen ? "370px" : "10px",
					display: "flex",
					justifyContent: "center",
					transition: "all 200ms ease",
				}}
			>
				<HorizontalContainer spacing={10}>
					<HorizontalGroup spacing={10}>
						<Button
							data-tip={isVideoMute ? "Turn Video Off" : "Turn Video On"}
							image={isVideoMute ? CameraOff : Camera}
							onClick={() => muteVideo(!isVideoMute)}
						/>
						<Button
							data-tip={isVideoMute ? "Turn Mic Off" : "Turn Mic On"}
							image={isAudioMute ? MicrophoneOff : Microphone}
							onClick={() => muteAudio(!isAudioMute)}
						/>

						{!disableScreenShare && (
							<Button
								onClick={() => handleScreenShare()}
								image={ownerHasScreenShare ? ScreenshareOff : ScreenshareOn}
							/>
						)}
					</HorizontalGroup>

					{moderatorType !== ModeratorType.None && (
						<HorizontalGroup spacing={10}>
							<div
								style={{
									width: "1px",
									backgroundColor: "lightgrey",
									alignSelf: "stretch",
								}}
							/>

							{!disableScreenShare && <ScreenShareControls />}
							<MegaphoneControls />
						</HorizontalGroup>
					)}

					<GroupControls />
				</HorizontalContainer>
			</div>
		</>
	)
}

export default ActionControlBar
