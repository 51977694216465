import { useFrame } from "@react-three/fiber"
import React, { useRef } from "react"
import { makeMonitor, useTweaks } from "use-tweaks"
import { useCommunicationStore } from "../../stores/communicationStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSocketStore } from "../../stores/socketStore"

const Tweaks = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Tweaks Reload")

	// Network
	const latency = useRef(0)
	const clientTime = useRef(" - ")
	const serverTime = useRef(" - ")

	const videoSend = useRef(0)
	const videoSendPackageloss = useRef(0)
	const videoReceived = useRef(0)
	const videoReceivedPackageloss = useRef(0)
	const dailyRoom = useCommunicationStore((state) => state.room)

	const debugStore = useDebugStore((state) => state)
	const ping = useSocketStore((state) => state.ping)
	const timeSyncer = useSocketStore((state) => state.timeSyncer)

	useTweaks("Network", {
		...makeMonitor("Server Time", serverTime, {
			interval: 1000,
		}),
		...makeMonitor("Client Time", clientTime, {
			interval: 1000,
		}),
		...makeMonitor("Latency", latency, {
			view: "graph",
			min: 0,
			max: 50,
			interval: 1000,
		}),
	})

	useTweaks("Video Stream", {
		...makeMonitor("Video in kb/s", videoReceived, {
			interval: 1000,
		}),
		...makeMonitor("Packageloss out", videoReceivedPackageloss, {
			interval: 1000,
		}),
		...makeMonitor("Video out kb/s", videoSend, {
			interval: 1000,
		}),
		...makeMonitor("Packageloss in", videoSendPackageloss, {
			interval: 1000,
		}),
	})

	debugStore.tweaks.logsTweak(
		useTweaks("Logs", {
			reload: debugStore.logs.reload,
		})
	)

	debugStore.tweaks.sceneTweak(
		useTweaks("Scene", {
			metaball: debugStore.scene.metaball,
		})
	)

	debugStore.tweaks.movementTweak(
		useTweaks("User Movement", {
			userInterpolation: debugStore.movement.userInterpolation,
		})
	)

	async function networkStats() {
		const { stats } = await dailyRoom.getNetworkStats()
		const videoIn = stats.latest.videoRecvBitsPerSecond
		const videoInPackageloss = stats.latest.videoRecvPacketLoss

		const videoOut = stats.latest.videoSendBitsPerSecond
		const videoOutPackageloss = stats.latest.videoSendPacketLoss

		if (videoIn) {
			videoReceived.current = videoIn / 8000
			videoReceivedPackageloss.current = videoInPackageloss
		} else {
			videoReceived.current = 0
		}

		if (videoOut) {
			videoSend.current = videoOut / 8000
			videoSendPackageloss.current = videoOutPackageloss
		} else {
			videoSend.current = 0
		}
	}

	useFrame(() => {
		clientTime.current = new Date(new Date().getTime()).toString()

		if (timeSyncer && timeSyncer.serverTime) {
			serverTime.current = new Date(timeSyncer.serverTime).toString()
		}

		if (ping && ping.lastLatency) {
			latency.current = ping.lastLatency
		}

		if (dailyRoom) {
			networkStats()
		}
	})

	return <> </>
}

export default Tweaks
