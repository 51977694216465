import { HorizontalGroup, Spacer, VerticalGroup } from "@saysom/shared"
import React, { useMemo } from "react"
import { ToastContainer } from "react-toastify"
import Header from "../../components/header/header"
import { Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useInterfaceStore } from "../../stores/interfaceStore"
import { useSettingsStore } from "../../stores/settingsStore"
import ActionControlBar from "../controls/actionControlBar"
import ActiveFunctionInfoBar from "../controls/activeFunctionInfoBar"
import PanelControlBar from "../controls/panelControlBar"
import ZoomControlBar from "../controls/zoomControlBar"
import InteractablesList from "../interactablesList/interactablesList"
import Panel from "../panel/panel"
import { Container } from "./interface_style"

export enum PanelType {
	None,
	Settings,
	Chat,
	Participants,
	Games,
	Spaces,
	Bots,
	Announcements,
	Space,
	Area,
}

const Interface = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Interface Reload")

	// Stores
	const selectedPanel = useInterfaceStore((state) => state.selectedPanel)
	const updateSelectePanel = useInterfaceStore((state) => state.updateSelectedPanel)
	const moderatorType = useAvatarStore((state) => (state.users["owner"] as Owner | User)?.moderatorType)
	const isScreenShareFullscreen = useInterfaceStore((state) => state.isScreenshareFullscreen)

	const rightGroupRight = useMemo(() => {
		if (isScreenShareFullscreen) {
			return "-80px"
		} else {
			return selectedPanel !== PanelType.None ? "360px" : "0px"
		}
	}, [isScreenShareFullscreen, selectedPanel])

	return (
		<Container>
			<VerticalGroup style={{ height: "100%", overflow: "hidden" }}>
				<HorizontalGroup flex={1} alignSelf="stretch" style={{ overflow: "hidden", padding: "10px" }}>
					<VerticalGroup>
						<Header />
						<InteractablesList />
						<Spacer />
					</VerticalGroup>

					<Spacer />

					<HorizontalGroup
						style={{
							// transform: `translate(${selectedPanel !== PanelType.None ? "0px" : "360px"}, 0)`,
							transition: "all 200ms ease",
							position: "absolute",
							top: "0",
							bottom: "0",
							right: rightGroupRight,
							padding: "10px",
						}}
					>
						<ToastContainer
							toastStyle={{
								// FIXME:
								borderRadius: "10px",
								border: "0px",
								overflow: "hidden",
								zIndex: 1,
								boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
								backgroundColor: "rgba(255, 255, 255, 0.8)",
								backdropFilter: "blur(5px)",
							}}
							style={{
								top: "0",
								padding: "0px",
								position: "relative",
								pointerEvents: "all",
							}}
						/>

						<VerticalGroup>
							<PanelControlBar
								moderatorType={moderatorType}
								selectedPanel={selectedPanel}
								onSelectedPanelChange={(panelType) => {
									if (panelType !== selectedPanel) {
										updateSelectePanel(panelType)
									} else {
										updateSelectePanel(PanelType.None)
									}
								}}
							/>

							<Spacer />

							<ZoomControlBar />
						</VerticalGroup>
					</HorizontalGroup>

					<Panel
						moderatorType={moderatorType}
						selectedPanel={selectedPanel}
						onSelectedPanelChange={(panelType) => {
							if (panelType !== selectedPanel) {
								updateSelectePanel(panelType)
							} else {
								updateSelectePanel(PanelType.None)
							}
						}}
						style={{
							transform: `translate(${selectedPanel !== PanelType.None ? "0px" : "360px"}, 0)`,
							transition: "all 200ms ease",
							overflow: "hidden",
						}}
					/>
				</HorizontalGroup>

				<ActionControlBar panelOpen={selectedPanel !== PanelType.None} />
				<ActiveFunctionInfoBar panelOpen={selectedPanel !== PanelType.None} />
			</VerticalGroup>
		</Container>
	)
}

export default Interface
