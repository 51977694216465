import { Html } from "@react-three/drei"
import { AreaContentType } from "@saysom/shared"
import React, { memo, Suspense } from "react"
import { useScaleFactor } from "../../hooks/useScaleFactor"
import { useAreaStore } from "../../stores/areaStore"
import { Owner, useAvatarStore } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import RoundedPlane from "../roundedPlane/roundedPlane"
import { Thumbnail } from "./area_style"

interface AreaProps {
	id: string
}

const Area = memo<AreaProps>(({ id }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Area Reload")

	// Stores
	const position = useAreaStore((state) => state.areas[id].position)
	const size = useAreaStore((state) => state.areas[id].size)
	const imageUrl = useAreaStore((state) => state.areas[id].imageUrl)
	const image = useAreaStore((state) => state.areas[id].image)
	const iconUrl = useAreaStore((state) => state.areas[id].iconUrl)
	const iconImage = useAreaStore((state) => state.areas[id].iconImage)
	const title = useAreaStore((state) => state.areas[id].title)
	const description = useAreaStore((state) => state.areas[id].description)
	const contentType = useAreaStore((state) => state.areas[id].contentType)
	const backgroundColor = useAreaStore((state) => state.areas[id].backgroundColor)
	const ownerAreaId = useAvatarStore((state) => (state.users["owner"] as Owner)?.areaId)

	// NaN Check
	const pos = { x: isNaN(position.x) ? 0 : position.x, y: isNaN(position.y) ? 0 : position.y }
	const siz = { width: isNaN(size.width) ? 1 : size.width, height: isNaN(size.height) ? 1 : size.height }

	const scaleFactor = useScaleFactor()

	return (
		<group>
			<Suspense fallback={<group> </group>}>
				{siz.width !== 0 && siz.height !== 0 && (
					<RoundedPlane
						position={pos}
						size={siz}
						borderRadius={0.5}
						isDisco={contentType === AreaContentType.Disco}
						ownerOnPlane={ownerAreaId === id}
						image={
							image && contentType === AreaContentType.Image
								? URL.createObjectURL(image)
								: imageUrl
								? imageUrl
								: undefined
						}
						backgroundColor={backgroundColor}
						border={contentType === AreaContentType.None || contentType === AreaContentType.Color}
					/>
				)}
			</Suspense>
			{(iconImage || iconUrl || description || title) && (
				<Html distanceFactor={scaleFactor} zIndexRange={[2, 2]} position={[pos.x, 0, pos.y]} prepend>
					<div className="flex p-2 rounded-xl space-x-2 transform -translate-y-16 backdrop-filter backdrop-blur-lg pointer-events-none bg-white bg-opacity-80">
						{(iconImage || iconUrl) && (
							<Thumbnail image={(iconImage ? URL.createObjectURL(iconImage) : iconUrl) as string} />
						)}

						<div className="flex flex-col">
							<p className="font-medium text-lg whitespace-nowrap">{title}</p>
							<p className="text-sm">{description}</p>
						</div>
					</div>
				</Html>
			)}
		</group>
	)
})

export default Area
