const locations = {
	live: "https://api.saysom.app",
	local: `http://${window.location.host.split(":")[0]}:3000`,
	staging: "https://api.staging.saysom.app",
}

let location = "local"
if (process.env.NODE_ENV === "production") {
	location = "live"
}

if (process.env.REACT_APP_STAGING === "1") {
	location = "staging"
}

export const BASE_URL = locations[location]
