import { RefObject } from "react"
import { create } from "zustand"
import { useAvatarStore, User } from "./avatarStore"
import { useSocketStore } from "./socketStore"

export enum GroupUpdateType {
	create = "create",
	join = "join",
	leave = "leave",
	destroy = "destroy",
	private = "private",
}

export interface GroupUpdate {
	id: string
	type: GroupUpdateType
	data: boolean | string[] | undefined
}

class ConnectedUser {
	userIds: Set<string>

	constructor() {
		this.userIds = new Set()
	}

	join(id: string): void {
		const emit = useSocketStore.getState().emit as any
		this.userIds.add(id)
		emit.joinGroup(id)
	}

	leave(id: string): void {
		const emit = useSocketStore.getState().emit as any
		this.userIds.delete(id)
		emit.leaveGroup(id)
	}
}

export class Group {
	id: string
	private: boolean
	users: Set<string>
	ref: RefObject<THREE.Mesh> | undefined

	constructor(id: string) {
		this.id = id
		this.private = false
		this.users = new Set()
	}

	join = (users: string[]): void => {
		users.forEach((id) => this.users.add(id))
	}

	leave = (users: string[]): void => {
		users.forEach((id) => this.users.delete(id))
	}
}

type GroupStore = {
	connectedUser: {
		group: ConnectedUser
		closestUser: User | undefined
		setClosestUser: (user: User | undefined) => void
	}

	ownerGroupPrivacy: boolean | undefined

	groups: Record<string, Group>
	create: (data: GroupUpdate) => void
	join: (data: GroupUpdate) => void
	leave: (data: GroupUpdate) => void
	destroy: (data: GroupUpdate) => void
	private: (data: GroupUpdate) => void
	log: (data: GroupUpdate) => void
	resetStore: () => void
}

export const useGroupStore = create<GroupStore>((set, get) => {
	return {
		groups: {},
		connectedUser: {
			group: new ConnectedUser(),

			closestUser: undefined,
			setClosestUser(user: User | undefined): void {
				var { connectedUser } = get()
				connectedUser.closestUser = user
			},
		},
		resetStore() {
			const { connectedUser } = get()
			connectedUser.group = new ConnectedUser()
			connectedUser.closestUser = undefined

			set({
				groups: {},
				connectedUser: connectedUser,
			})
		},

		ownerGroupPrivacy: undefined,

		create(data: GroupUpdate) {
			var { groups } = get()
			const newGroup = new Group(data.id)
			const concerningUsers = data.data as string[]

			newGroup.join(concerningUsers)
			groups[data.id] = newGroup

			// Update owner group
			const owner = useAvatarStore.getState().users["owner"]

			const isOwnerPartOfUpdate = owner && concerningUsers.includes(owner.id)
			if (isOwnerPartOfUpdate) {
				set({ ownerGroupPrivacy: false })
			}

			const isInOwnerGroup = isOwnerPartOfUpdate

			useAvatarStore.getState().actions.updateGroupForUsers(concerningUsers, data.id, isInOwnerGroup)

			set({ groups: { ...groups } })
		},
		join(data) {
			var { groups } = get()
			const group = groups[data.id]
			const concerningUsers = data.data as string[]

			if (group) {
				const owner = useAvatarStore.getState().users["owner"]
				const isOwnerInGroup = owner && group.users.has(owner.id)

				group.join(concerningUsers)

				// Update owner group
				const isOwnerPartOfUpdate = owner && concerningUsers.includes(owner.id)
				if (isOwnerPartOfUpdate) {
					set({ ownerGroupPrivacy: false })
				}

				console.log("####################")
				console.log("User in group:")
				console.log(group.users)
				console.log("Owner id: " + owner.id)
				console.log("Concerning Users ")
				console.log(concerningUsers)

				if (isOwnerPartOfUpdate) {
					useAvatarStore.getState().actions.updateGroupForUsers([...group.users], data.id, true)
				} else {
					useAvatarStore.getState().actions.updateGroupForUsers(concerningUsers, data.id, isOwnerInGroup)
				}
			}
		},
		leave(data) {
			var { groups } = get()
			const group = groups[data.id]
			const concerningUsers = data.data as string[]

			if (group) {
				const owner = useAvatarStore.getState().users["owner"]
				const isOwnerInGroup = group.users.has(owner.id)

				group.leave(concerningUsers)

				/* console.log("####################")
				console.log("User in group:")
				console.log(group.users)
				console.log("Owner id: " + owner.id)
				console.log("Concerning Users ")
				console.log(concerningUsers) */

				// Update owner group
				const isOwnerPartOfUpdate = owner && concerningUsers.includes(owner.id)
				if (isOwnerPartOfUpdate) {
					set({ ownerGroupPrivacy: undefined })
				}

				if (isOwnerPartOfUpdate) {
					//TODO: Keep track if this is ok
					useAvatarStore.getState().actions.updateGroupForUsers(concerningUsers, undefined, false)
					useAvatarStore.getState().actions.updateGroupForUsers([...group.users], group.id, false)
				} else {
					useAvatarStore.getState().actions.updateGroupForUsers(concerningUsers, undefined, isOwnerInGroup)
				}
			}
		},
		destroy(data) {
			var { groups } = get()
			const group = groups[data.id]

			if (group) {
				// Update owner group
				const owner = useAvatarStore.getState().users["owner"]
				const isInOwnerGroup = owner && owner.groupId === group.id
				if (isInOwnerGroup) {
					set({ ownerGroupPrivacy: undefined })
				}

				useAvatarStore.getState().actions.updateGroupForUsers([...group.users], undefined, isInOwnerGroup)

				delete groups[data.id]
				set({ groups: { ...groups } })
			}
		},

		private(data) {
			var { groups } = get()
			const group = groups[data.id]
			const privatise = data.data as boolean

			if (group) {
				groups[data.id].private = privatise

				const owner = useAvatarStore.getState().users["owner"]
				if (owner && owner.groupId === group.id) set({ ownerGroupPrivacy: group.private })
				set({ groups: { ...groups } })
			}
		},

		log(data) {
			var { groups } = get()
			console.group()
			console.log("Group Update")
			console.log("Type: " + data.type)
			console.log("Data: " + data.data)
			console.log(groups)
			console.groupEnd()
		},
	}
})
