import { useTexture } from "@react-three/drei"
import { useEffect } from "react"
import { ClampToEdgeWrapping, Texture } from "three"

interface TextureMaterialProps {
	size: { width: number; height: number }
	image: string
}

enum TextureFit {
	none = 0,
	contain,
	cover,
}

const TextureMaterial = ({ size, image }: TextureMaterialProps) => {
	const fitTexture = (texture: Texture, screenAspect: number, mode: TextureFit, alignH = 0.5, alignV = 0.5) => {
		const imageAspect = texture.image.width / texture.image.height

		const scale = imageAspect / screenAspect
		const offsetX = (imageAspect - screenAspect) / imageAspect
		const offsetY = (screenAspect - imageAspect) / screenAspect

		switch (mode) {
			case TextureFit.contain: {
				if (screenAspect < imageAspect) {
					texture.offset.set(0, offsetY * alignV)
					texture.repeat.set(1, scale)
				} else {
					texture.offset.set(offsetX * alignH, 0)
					texture.repeat.set(1 / scale, 1)
				}
				break
			}
			case TextureFit.cover: {
				if (screenAspect < imageAspect) {
					texture.offset.set(offsetX * alignH, 0)
					texture.repeat.set(1 / scale, 1)
				} else {
					texture.offset.set(0, offsetY * alignV)
					texture.repeat.set(1, scale)
				}
				break
			}
			case TextureFit.none:
			default: {
				texture.offset.set(0, 0)
				texture.repeat.set(1, 1)
				break
			}
		}
	}

	const texture = useTexture(image)

	useEffect(() => {
		texture.wrapS = ClampToEdgeWrapping
		texture.wrapT = ClampToEdgeWrapping

		fitTexture(texture, size.width / size.height, TextureFit.cover)

		texture.needsUpdate = true
	}, [texture, size])

	return <meshBasicMaterial attach="material" map={texture} />
}

export default TextureMaterial
