import { fetchApiAuthorized } from "../network/fetch"

export const parseAndCreateBestFitUrl = (urlString?: string): string => {
	if (!urlString) return ""

	let baseUrl = urlString
	if (!baseUrl.includes("http")) {
		baseUrl = `https://${baseUrl}`
	}

	const url = new URL(baseUrl)
	if (url.hostname.split(".").length < 2) {
		url.hostname += ".com"
	}

	return url.toString()
}

export const checkIfCanBeInsideIframe = async (url: string): Promise<boolean> => {
	const data = await fetchApiAuthorized("/spaces/checkiframe", { url }, "POST")

	return data?.allowed ?? false
}
