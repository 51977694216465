import { useFrame, useThree } from "@react-three/fiber"
import { MutableRefObject, useCallback, useEffect, useRef } from "react"
import { MathUtils, Vector2, Vector3 } from "three"
import { PanelType } from "../../components/interface/interface"
import { Owner, useAvatarStore } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { Group, useGroupStore } from "../../stores/groupStore"
import { useInterfaceStore } from "../../stores/interfaceStore"
import { Target, useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"

const Camera = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Camera Reload")

	// Stores
	const ownerMesh = useAvatarStore((state) => state.users["owner"]?.mesh)
	const layerMask = useSceneStore((state) => state.layerMask)
	const isInitialZoom = useSceneStore((state) => state.isInitialZoom)
	const zoom: MutableRefObject<number> = useRef(useSceneStore.getState().zoom)
	const meshTarget = useSceneStore((state) => state.target.mesh)
	const setTarget = useSceneStore((state) => state.target.setTarget)
	const targetType = useSceneStore((state) => state.target.type)
	const selectedPanel = useInterfaceStore((state) => state.selectedPanel)
	const offset = useRef(selectedPanel === PanelType.None ? 0 : 180)

	const isMoving: MutableRefObject<boolean> = useRef(false)
	useEffect(() => {
		useAvatarStore.subscribe((state) => {
			const isMovingFromStore = (state.users["owner"] as Owner)?.isMoving
			isMoving.current = isMovingFromStore !== undefined ? isMovingFromStore : false
		})
	}, [])

	const groups: MutableRefObject<Record<string, Group>> = useRef({})
	useEffect(() => {
		useGroupStore.subscribe((state) => (groups.current = state.groups))
	}, [])

	// Properties
	const camHeight = 10
	const zoomDamping = 0.3
	const moveDamping = 0.01
	const stopDamping = 0.03
	const offsetDamping = 0.78
	const epsilonOffset = 0.01
	const { camera } = useThree()

	useEffect(() => useSceneStore.subscribe((state) => (zoom.current = state.zoom)), [])

	useEffect(() => {
		camera.layers.enable(layerMask)
		camera.rotation.setFromVector3(new Vector3(MathUtils.degToRad(-90), 0, 0))
		camera.updateProjectionMatrix()
	}, [camera, layerMask])

	useEffect(() => {
		camera.zoom = zoom.current
		camera.updateProjectionMatrix()
	}, [camera])

	const updateZoom = useCallback(() => {
		if (camera.zoom !== zoom.current) {
			camera.zoom = MathUtils.lerp(camera.zoom, zoom.current, zoomDamping)
			camera.updateProjectionMatrix()
		}
	}, [camera])

	const determineFollowPoint = (): Vector2 => {
		let followPoint = new Vector2(0, 0)

		if (!meshTarget) return followPoint

		const newOffset = selectedPanel === PanelType.None ? 0 : 180
		offset.current = MathUtils.lerp(newOffset, offset.current, offsetDamping)
		camera.setViewOffset(
			window.innerWidth,
			window.innerHeight,
			offset.current,
			0,
			window.innerWidth,
			window.innerHeight
		)

		// TODO: Follow group center
		const pos = meshTarget.position
		followPoint = new Vector2(pos.x + epsilonOffset, pos.z + epsilonOffset)

		return followPoint
	}

	const followTarget = (point: Vector2, damping: number) => {
		const camPos = new Vector2(camera.position.x, camera.position.z)
		if (camPos.distanceTo(point) < epsilonOffset) return
		const newCamPos = camPos.lerp(point, damping)

		camera.position.set(newCamPos.x, camHeight, newCamPos.y)
	}

	useFrame(() => {
		if (!isInitialZoom) updateZoom()

		if (!ownerMesh) return
		if (!meshTarget) {
			setTarget(ownerMesh, Target.Owner)
			return
		}

		const followPoint = determineFollowPoint()
		let damping = isMoving.current ? moveDamping : stopDamping

		if (isInitialZoom) damping = 1
		if (targetType === Target.Object) damping = 0.8
		followTarget(followPoint, damping)
	})

	return <mesh />
}

export default Camera
