import { HorizontalGroup, Input } from "@saysom/shared"
import { nanoid } from "nanoid"
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import useSWR from "swr"
import { CenterContainer, Form, H1, Label, P } from "../../assets/style"
import ContinueButton from "../../components/button/continueButton"
import { SpaceImage } from "../../components/header/header_style"
import { BASE_URL } from "../../constants/BaseUrl"
import { fetchApi } from "../../network/fetch"
import { useAuthStore } from "../../stores/authStore"
import { useLocalStore } from "../../stores/localStore"
import { useQuery } from "../../utils/UseQuery"
import { showError } from "../../utils/errorHandler"
import { Container } from "./join_style"

export interface SpaceIdParams {
	spaceId: string | undefined
}

// TODO: shared type
export interface Space {
	logoUrl?: string
	title: string
	isPrivate: boolean
	isOver: boolean
}

const Join = () => {
	const { spaceId } = useParams<SpaceIdParams>()
	const updateToken = useAuthStore((state) => state.update)
	const updateLocalStore = useLocalStore((state) => state.update)

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [newsletter, setNewsletter] = useState(false)

	const { data: space } = useSWR<Space | undefined>(`/spaces/${spaceId}`, fetchApi)

	const history = useHistory()
	const query = useQuery()

	const academicUrl = useMemo(() => {
		const url = new URL(BASE_URL)
		url.pathname = "/auth/oauth"

		const redirectUrl = new URL(typeof window !== "undefined" ? window.location.href : "https://saysom.app")
		redirectUrl.search = ""

		//url.searchParams.append("redirect", Buffer.from(JSON.stringify(redirectUrl.toString())).toString("base64"))

		return url.toString()
	}, [])

	useEffect(() => {
		if (space) {
			document.title = `Join ${space.title}`
		}
	}, [space])

	const createToken = useCallback(
		async (name: string, newsletter: boolean, email?: string) => {
			try {
				const token = await fetchApi(`/spaces/join/${spaceId}`, { name, email: email ?? nanoid(), newsletter }, "POST")

				if (token.message) {
					showError(new Error(token.message))
				} else {
					updateToken(token)
					updateLocalStore(name, undefined, undefined)
					history.push(`/${spaceId}`)
				}
			} catch (e: any) {
				showError(e)
			}
		},
		[updateToken, spaceId, history, updateLocalStore]
	)

	useEffect(() => {
		const token = query.get("token")
		const expiresIn = query.get("expiresIn")
		if (token && expiresIn) {
			updateToken({ token, expiresIn: parseInt(expiresIn) })
			history.push(`/${spaceId}`)

			return
		} else if (token) {
			const rawToken = JSON.parse(Buffer.from(decodeURI(token as string), "base64").toString("utf8"))

			updateToken({ token: rawToken.token, expiresIn: parseInt(rawToken.expiresIn) })
			history.push(`/${spaceId}`)

			return
		}

		const firstName = query.get("firstName")
		const lastName = query.get("lastName")
		const name = query.get("name")
		const isModerator = query.get("isModerator")
		const avatar = query.get("avatar") ?? undefined

		if (name) {
			const safeName = decodeURI(name)
			const isModeratorConverted = isModerator ? parseInt(isModerator) === 1 : undefined
			updateLocalStore(safeName, isModeratorConverted, avatar)
			createToken(safeName, false)
		}

		if (firstName) {
			const safeFirstName = decodeURI(firstName)
			const safeLastName = decodeURI(lastName ?? "")
			const isModeratorConverted = isModerator ? parseInt(isModerator) === 1 : undefined

			const combined = safeLastName ? `${safeFirstName} ${safeLastName}` : safeFirstName
			updateLocalStore(combined, isModeratorConverted, avatar)
			createToken(combined, false)
		}
	}, [query, createToken, updateLocalStore, history, spaceId, updateToken])

	const createUser = async (e: FormEvent) => {
		e.preventDefault()

		createToken(name, newsletter, email)
	}

	return (
		<Container>
			<CenterContainer width="400px" className="divide-y space-y-6">
				<div className="flex flex-col self-stretch">
					<HorizontalGroup alignItems="center" style={{ marginBottom: "30px" }}>
						{space !== undefined && space.logoUrl && <SpaceImage src={space.logoUrl} style={{ marginRight: "10px" }} />}
						<H1 style={{ margin: 0 }}>Welcome to {space !== undefined ? space.title + "!" : ""}</H1>
					</HorizontalGroup>
					<P style={{ alignSelf: "flex-start", marginBottom: "20px" }}>We need your name and email to continue</P>
					<Form style={{ alignSelf: "stretch", alignItems: "stretch" }} onSubmit={createUser}>
						<Label>
							Name
							<Input
								placeholder="Will be displayed on your avatar"
								type="text"
								value={name}
								onChange={(value) => setName(value)}
								required
							/>
						</Label>

						<Label>
							Email
							<Input placeholder="Your email" type="email" value={email} onChange={(value) => setEmail(value)} />
						</Label>

						<label
							className={`flex items-center flex-row self-center ${
								email.length === 0 ? "opacity-50" : "opacity-100"
							} mb-5`}
						>
							<input
								className="text-black mr-2"
								type="checkbox"
								disabled={email.length === 0}
								checked={newsletter}
								onChange={() => {
									setNewsletter((old) => !old)
								}}
							/>
							Signup for newsletter
						</label>

						<ContinueButton
							type="submit"
							text="Continue"
							isBlack
							className="self-center mt-4"
							disabled={name.length === 0}
						/>
					</Form>
				</div>

				<div className="flex self-stretch justify-center pt-6">
					<a
						href={academicUrl}
						className="flex flex-row py-3 px-6 items-center border border-black border-opacity-50 rounded-full space-x-2 shadow-sm text-sm hover:shadow-md transition-all"
					>
						<span>Login with</span>
						<img width="80px" height="auto" src="/academiccloud.svg" alt="AcademicCloud" />
					</a>
				</div>
			</CenterContainer>
		</Container>
	)
}

export default Join
