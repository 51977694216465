import { Area } from "../../stores/areaStore"

export const legacyAreas: Record<string, Record<string, Area>> = {
	// MicMuc
	orTmY1TCTK: {
		micmuc_1: {
			key: "micmuc_1",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/607eee0e6987e04da0ad1be3/Welcome.png",
			position: { x: -6, y: -2 },
			size: { width: 6, height: 6.25 },
		},
		micmuc_2: {
			key: "micmuc_2",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/607eee0e6987e04da0ad1be3/CoffeeBar.PNG",
			position: { x: -17, y: -14 },
			size: { width: 16, height: 10.5 },
		},
		micmuc_3: {
			key: "micmuc_3",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/607eee0e6987e04da0ad1be3/1on1.PNG",
			position: { x: 2, y: -16 },
			size: { width: 16, height: 10.5 },
		},
		micmuc_4: {
			key: "micmuc_4",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/607eee0e6987e04da0ad1be3/MemeCorner.PNG",
			position: { x: -17, y: 7 },
			size: { width: 16, height: 11.5 },
		},
		micmuc_5: {
			key: "micmuc_5",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/607eee0e6987e04da0ad1be3/TopicArea.PNG",
			position: { x: 2, y: 5 },
			size: { width: 16, height: 11 },
		},
	},

	// Park Here
	aqT5_BGQoP: {
		area_0: {
			key: "area_0",
			imageUrl:
				"https://saysom.s3.eu-central-1.amazonaws.com/events/60617dd137bf6403309ebfdf/PXL_20210128_090222969.jpg",
			position: { x: -18.5, y: -10 },
			size: { height: 4, width: 2.5 },
			title: undefined,
		},
		area_1: {
			key: "area_1",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60617dd137bf6403309ebfdf/image3.png",
			position: { x: -15, y: -10 },
			size: { height: 4, width: 8 },
			title: undefined,
		},
		area_2: {
			key: "area_2",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60617dd137bf6403309ebfdf/image2.png",
			position: { x: -6, y: -10 },
			size: { height: 4, width: 9 },
			title: undefined,
		},
		area_3: {
			key: "area_3",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60617dd137bf6403309ebfdf/image.png",
			position: { x: 4, y: -10 },
			size: { height: 4, width: 8 },
			title: undefined,
		},
		area_4: {
			key: "area_4",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60617dd137bf6403309ebfdf/2021-02-24-123311.jpg",
			position: { x: 13, y: -10 },
			size: { height: 4, width: 6 },
			title: undefined,
		},
	},

	// Gründes
	L5bolK_R9l: {
		area_0: {
			key: "area_0",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/605878796d33d1cddccbe1fa/logoneu.jpg",
			position: { x: -14, y: -10 },
			size: { height: 6, width: 6 },
			title: "Recycling Hero",
		},
		area_1: {
			key: "area_1",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/605878796d33d1cddccbe1fa/Empowermeter_Logo.jpg",
			position: { x: -14, y: -2 },
			size: { height: 6, width: 6 },
			title: "emPOWERmeter",
		},
		area_2: {
			key: "area_2",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/605878796d33d1cddccbe1fa/Offen_Office_logo.png",
			position: { x: -14, y: 6 },
			size: { height: 6, width: 6 },
			title: "Offen Office",
		},
		area_3: {
			key: "area_3",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/605878796d33d1cddccbe1fa/CInIT+Solutions_MAIN.jpg",
			position: { x: 10, y: -7 },
			size: { height: 5, width: 5 },
			title: "CInIT Solutions",
		},
		area_4: {
			key: "area_4",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/605878796d33d1cddccbe1fa/Beneto.foods.jpeg",
			position: { x: 10, y: 0 },
			size: { height: 5, width: 5 },
			title: "Beneto.foods",
		},
		area_5: {
			key: "area_5",
			imageUrl:
				"https://saysom.s3.eu-central-1.amazonaws.com/events/605878796d33d1cddccbe1fa/logo_WRS_Energie_final.jpg",
			position: { x: 10, y: 7 },
			size: { height: 5, width: 5 },
			title: "WRS Energie",
		},
		area_6: {
			key: "area_6",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/605878796d33d1cddccbe1fa/kessler.jpg",
			position: { x: -6, y: 13 },
			size: { height: 5, width: 14 },
			title: "Kessler Sektbar",
		},
	},

	// House of Innovation
	IJxvEBaRQ2: {
		area_0: {
			key: "area_0",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/%23Kitchen_03.jpeg",
			position: { x: -11, y: -10 },
			size: { height: 10, width: 13 },
			title: "#Kitchen",
		},
		area_1: {
			key: "area_1",
			imageUrl: undefined,
			position: { x: 2, y: 4 },
			size: { height: 0, width: 0 },
			title: "Upcoming events",
		},
		area_2: {
			key: "area_2",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/SM_Mrz.jpg",
			position: { x: 2, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
		area_3: {
			key: "area_3",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/Folie1.jpeg",
			position: { x: 6, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
		area_4: {
			key: "area_4",
			imageUrl:
				"https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/WCI+Innovation+Lunch+%2306+Story.png",
			position: { x: 10, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
		area_5: {
			key: "area_5",
			imageUrl:
				"https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/Community-Lunch_April.jpg",
			position: { x: 14, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
	},
	iciQ_gtavQ: {
		area_0: {
			key: "area_0",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/%23Kitchen_03.jpeg",
			position: { x: -11, y: -10 },
			size: { height: 10, width: 13 },
			title: "#Kitchen",
		},
		area_1: {
			key: "area_1",
			imageUrl: undefined,
			position: { x: 2, y: 4 },
			size: { height: 0, width: 0 },
			title: "Upcoming events",
		},
		area_2: {
			key: "area_2",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/SM_Mrz.jpg",
			position: { x: 2, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
		area_3: {
			key: "area_3",
			imageUrl: "https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/Folie1.jpeg",
			position: { x: 6, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
		area_4: {
			key: "area_4",
			imageUrl:
				"https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/WCI+Innovation+Lunch+%2306+Story.png",
			position: { x: 10, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
		area_5: {
			key: "area_5",
			imageUrl:
				"https://saysom.s3.eu-central-1.amazonaws.com/events/60242c650b662f038a686fb8/Community-Lunch_April.jpg",
			position: { x: 14, y: 4 },
			size: { height: 6, width: 3 },
			title: undefined,
		},
	},

	// Default
	default: {
		area_0: {
			key: "area_0",
			imageUrl:
				"https://images.unsplash.com/photo-1580933073521-dc49ac0d4e6a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1949&q=80",
			position: { x: -12, y: -8 },
			size: { height: 8, width: 7 },
			title: "Coffebar",
		},
		area_1: {
			key: "area_1",
			imageUrl:
				"https://images.unsplash.com/photo-1472691681358-fdf00a4bfcfe?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1949&q=80",
			position: { x: 1, y: -8 },
			size: { height: 5, width: 10 },
			title: undefined,
		},
		area_2: {
			key: "area_2",
			imageUrl:
				"https://images.unsplash.com/photo-1607679298992-43e643a0f3c8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2549&q=80",
			position: { x: -12, y: 4 },
			size: { height: 8, width: 12 },
			title: "Hangout",
		},
		area_3: {
			key: "area_3",
			imageUrl:
				"https://images.unsplash.com/photo-1604852961945-155837ffebe2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
			position: { x: 4, y: 2 },
			size: { height: 10, width: 7 },
			title: "Gallery",
		},
	},

	// Smart Mobility
	yjc_pV3dvU: {
		area_0: {
			key: "area_0",
			imageUrl:
				"https://images.unsplash.com/photo-1555386566-10f30cca698a?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2167&q=80",
			position: { x: -12, y: 0 },
			size: { height: 7, width: 10 },
			title: "Electric driving",
		},
		area_1: {
			key: "area_1",
			imageUrl:
				"https://images.unsplash.com/photo-1533788179956-82e8a027c962?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80",
			position: { x: 0, y: 0 },
			size: { height: 7, width: 10 },
			title: "Shared economy",
		},
		area_2: {
			key: "area_2",
			imageUrl:
				"https://images.unsplash.com/photo-1485463611174-f302f6a5c1c9?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8YXV0b25vbW91cyUyMGRyaXZpbmd8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
			position: { x: -12, y: -10 },
			size: { height: 7, width: 10 },
			title: "Antonomous driving",
		},
	},
}
