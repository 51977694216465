import { ModeratorType, VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import { useMemo, useState } from "react"
import { Tooltip } from "react-tooltip"
import { shallow } from "zustand/shallow"
import { Owner, useAvatarStore } from "../../stores/avatarStore"
import Participant from "./participant"
import { List } from "./participantList_style"
import ParticipantSearch from "./participantSearch"

interface ParticipantListProps {
	style?: Properties
	onSelectedChatUserChange: (userId: string, isGroup?: boolean) => void
}

const ParticipantList = ({ style, onSelectedChatUserChange }: ParticipantListProps) => {
	const allUsers = useAvatarStore((state) => Object.keys(state.users), shallow)
	const ownerGroupId = useAvatarStore((state) => state.users["owner"]?.groupId)
	const ownerModeratorType = useAvatarStore(
		(state) => (state.users["owner"] as Owner)?.moderatorType ?? ModeratorType.None
	)

	const users = useMemo(() => allUsers.filter((id) => id !== "owner"), [allUsers])
	const [filteredIds, setFilteredIds] = useState<string[] | undefined>(undefined)

	const participants = useMemo(() => (filteredIds !== undefined ? filteredIds : users), [filteredIds, users])

	return (
		<VerticalGroup style={{ ...style, overflow: "hidden" }} spacing={10} flex={1}>
			<Tooltip place="bottom" variant="dark" />

			<ParticipantSearch onFilter={setFilteredIds} />

			<div
				style={{
					flex: 1,
					flexGrow: 1,
					alignSelf: "stretch",
					overflowY: "scroll",
					display: "flex",
					WebkitOverflowScrolling: "touch",
				}}
			>
				{participants.length > 0 ? (
					<List>
						{ownerGroupId && (
							<Participant
								isGroup
								id={ownerGroupId}
								key={ownerGroupId}
								alternateRow={false}
								name={"Current Group"}
								onSelectedChatUserChange={(ownerGroupId) => onSelectedChatUserChange(ownerGroupId, true)}
							/>
						)}

						{participants.map((userId: string, index: number) => (
							<Participant
								key={userId}
								id={userId}
								ownerModeratorType={ownerModeratorType}
								name={useAvatarStore.getState().users[userId].name}
								alternateRow={(ownerGroupId ? index : index + 1) % 2 === 0}
								onSelectedChatUserChange={(userId) => onSelectedChatUserChange(userId)}
							/>
						))}
					</List>
				) : (
					<div style={{ flex: 1, alignSelf: "center", justifyContent: "center", textAlign: "center" }}>
						{filteredIds === undefined ? "You're the first one!" : "No results"}
					</div>
				)}
			</div>

			{allUsers.includes("owner") && (
				<Participant
					key="owner"
					id="owner"
					alternateRow={false}
					isOwner
					style={{ borderRadius: "0 0 8px 8px" }}
					name={useAvatarStore.getState().users["owner"].name}
				/>
			)}
		</VerticalGroup>
	)
}

export default ParticipantList
