import { HorizontalContainer, HorizontalGroup, VerticalGroup } from "@saysom/shared"
import { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import Leave from "../../assets/images/leave.svg"
import { H3, P } from "../../assets/style"
import { customSpaceForSpaceCode } from "../../config"
import { useSceneStore } from "../../stores/sceneStore"
import { useSocketStore } from "../../stores/socketStore"
import { useSpaceStore } from "../../stores/spaceStore"
import TutorialNotification from "../notification/tutorialNotification"
import { Button, ButtonImage, SpaceImage } from "./header_style"

const Header = () => {
	// Stores
	const title = useSpaceStore((state) => state.space?.title)
	const logoUrl = useSpaceStore((state) => state.space?.logoUrl)
	const logoImage = useSpaceStore((state) => state.space?.logoImage)
	const spaceId = useSocketStore((state) => state.spaceId)

	const leaveScene = useSceneStore((state) => state.leaveScene)
	const history = useHistory()

	const customSpace = useMemo(() => customSpaceForSpaceCode(spaceId ?? ""), [spaceId])
	const showLeaveButton = useMemo(() => {
		try {
			if (window.self !== window.top) {
				return customSpace?.customUrl !== undefined
			} else {
				return true
			}
		} catch (e) {
			console.log(e)
			return true
		} finally {
			return true
		}
	}, [customSpace])

	return (
		<HorizontalContainer
			style={{ minWidth: "300px", maxWidth: "600px" }}
			justifyContent="space-between"
			alignItems="center"
		>
			<HorizontalGroup spacing={10} style={{ marginRight: "10px" }} alignItems="center">
				{(logoUrl || logoImage) && <SpaceImage src={logoImage ? URL.createObjectURL(logoImage) : logoUrl} />}
				<VerticalGroup>
					<H3>{title ? title : "Connecting…"}</H3>
					<a href="https://saysom.app" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
						<P style={{ fontSize: "12px" }}>
							powered by <b>SaySom</b>
						</P>
					</a>
				</VerticalGroup>
			</HorizontalGroup>

			<HorizontalGroup spacing={5}>
				<Button
					onClick={() => {
						toast(<TutorialNotification />, {
							toastId: "tutorial",
							position: "top-right",
							autoClose: false,
							hideProgressBar: true,
							closeOnClick: true,
							draggable: true,
							progress: undefined,
							closeButton: false,
						})
					}}
				>
					<span style={{ fontWeight: 800, fontSize: "20px", color: "purple" }}>?</span>
				</Button>

				{showLeaveButton && (
					<Button
						onClick={() => {
							leaveScene()

							if (customSpace && customSpace.customUrl) {
								history.push(customSpace.customUrl)
							} else {
								history.push(`/${spaceId}/feedback`)
							}
						}}
					>
						<ButtonImage src={Leave} />
					</Button>
				)}
			</HorizontalGroup>
		</HorizontalContainer>
	)
}

export default Header
