import Fuse from "fuse.js"
import { useEffect, useRef } from "react"
import { Input } from "../../assets/style"
import { useHandleChange } from "../../hooks/useHandleChange"
import { useAvatarStore } from "../../stores/avatarStore"
import { useInputStore } from "../../stores/inputStore"

interface ParticipantSearchProps {
	onFilter: (ids: string[] | undefined) => void
}

interface FilterUser {
	id: string
	name: string
}

const ParticipantSearch = ({ onFilter }: ParticipantSearchProps) => {
	const updateInputFocus = useInputStore((state) => state.actions.updateInputFocus)
	const [term, setTerm] = useHandleChange("")
	const lastTerm = useRef("")

	const users = useRef<FilterUser[]>()

	useEffect(() => {
		if (lastTerm.current !== term) {
			lastTerm.current = term

			if (term === "") {
				onFilter(undefined)
			} else if (users.current) {
				const fuse = new Fuse(users.current, { keys: ["name"] })
				const result = fuse.search(term)

				const ids = result.map(({ item }) => item.id)
				onFilter(ids)
			}
		}
	}, [term, onFilter])

	useEffect(
		() =>
			useAvatarStore.subscribe((state) => {
				const _users = state.users

				const userCopy = { ..._users }
				delete userCopy["owner"]

				users.current = Object.entries(userCopy).map(([id, user]) => {
					return { id, name: user.name }
				})
			}),
		[]
	)

	return (
		<Input
			style={{ padding: "10px 10px", flex: 0 }}
			placeholder="Filter"
			value={term}
			onChange={setTerm}
			onFocus={() => updateInputFocus(true)}
			onBlur={() => updateInputFocus(false)}
		/>
	)
}

export default ParticipantSearch
