import { Colors } from "@saysom/shared"
import { forwardRef, Ref, useCallback, useMemo, useRef } from "react"

interface ContinueButtonProps {
	isBlack?: boolean
	text: string
	className?: string

	href?: string
	onClick?: () => void
	type?: "submit" | "reset" | "button"
	disabled?: boolean
}

type ContineButtonType = HTMLDivElement | HTMLAnchorElement | HTMLButtonElement

const ContinueButton = forwardRef<ContineButtonType, ContinueButtonProps>(
	({ isBlack = false, text, className, href, onClick, type, disabled }, ref) => {
		const toHover = useRef<SVGAnimationElement>(null!)
		const fromHover = useRef<SVGAnimationElement>(null!)

		const onHover = useCallback(
			(hover: boolean) => {
				if (hover && toHover.current && !disabled) {
					//@ts-ignore
					toHover.current.beginElement()
				} else if (!hover && fromHover.current && !disabled) {
					//@ts-ignore
					fromHover.current.beginElement()
				}
			},
			[disabled]
		)

		const content = useMemo(
			() => (
				<>
					<span>{text}</span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-5 w-5 ml-1"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path d="M9 5L16 12M16 12L9 19M16 12H15.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
							<animate
								attributeName="d"
								attributeType="XML"
								from="M9 5L16 12M16 12L9 19M16 12H15.5"
								to="M14 5L21 12M21 12L14 19M21 12H3"
								dur="100ms"
								fill="freeze"
								ref={toHover}
							/>
							<animate
								attributeName="d"
								attributeType="XML"
								from="M14 5L21 12M21 12L14 19M21 12H3"
								to="M9 5L16 12M16 12L9 19M16 12H15.5"
								dur="100ms"
								fill="freeze"
								ref={fromHover}
							/>
						</path>
					</svg>
				</>
			),
			[text]
		)

		return href !== undefined ? (
			<a
				style={{
					backgroundColor: isBlack ? Colors.black : "white",
					color: isBlack ? "white" : Colors.black,
				}}
				onMouseOver={() => onHover(true)}
				onMouseLeave={() => onHover(false)}
				className={`flex items-center justify-center shadow-md rounded-full px-5 py-2 text-sm font-semibold select-none cursor-pointer ${className}`}
				ref={(ref as unknown) as Ref<HTMLAnchorElement>}
				href={href}
			>
				{content}
			</a>
		) : onClick !== undefined ? (
			<div
				style={{
					backgroundColor: isBlack ? Colors.black : "white",
					color: isBlack ? "white" : Colors.black,
				}}
				onMouseOver={() => onHover(true)}
				onMouseLeave={() => onHover(false)}
				className={`flex items-center justify-center shadow-md rounded-full px-5 py-2 text-sm font-semibold select-none cursor-pointer ${className}`}
				ref={(ref as unknown) as Ref<HTMLDivElement>}
				onClick={onClick}
			>
				{content}
			</div>
		) : (
			<button
				onMouseOver={() => onHover(true)}
				onMouseLeave={() => onHover(false)}
				className={`flex items-center justify-center shadow-md rounded-full px-5 py-2 text-sm font-semibold select-none cursor-pointer disabled:cursor-default disabled:bg-opacity-50 ${
					isBlack ? "bg-black" : "bg-white"
				} ${isBlack ? "text-white" : "text-black"} ${className}`}
				ref={(ref as unknown) as Ref<HTMLButtonElement>}
				type={type}
				disabled={disabled}
			>
				{content}
			</button>
		)
	}
)

export default ContinueButton
