import { HorizontalGroup } from "@saysom/shared"
import { Property } from "csstype"
import styled from "styled-components"
import { H1 } from "../../assets/style"

interface BoxProps {
	width: number
	height: number
	circular: boolean
	image?: Property.BackgroundImage
	transparent: boolean
}

export const Box = styled.div<BoxProps>`
	border: 6px solid #333;
	background: linear-gradient(135deg, rgba(100, 50, 50, 0.4), rgba(150, 50, 50, 0.1));

	bottom: ${(props) => (props.circular ? 0 : "6px")};
	right: ${(props) => (props.circular ? 0 : "2px")};

	padding: 20px;

	border-radius: ${(props) => (props.circular ? "100000px" : "40px")};

	width: ${(props) => props.width};
	height: ${(props) => props.height};

	font-weight: bold;

	pointer-events: none;

	background-blend-mode: color-burn;

	opacity: ${(props) => (props.transparent ? "0.25" : 1)};
`

export const TitleContainer = styled(HorizontalGroup)`
	position: absolute;
	bottom: 10px;

	will-change: transform;
`

interface ThumbnailProps {
	image: Property.BackgroundImage
}

export const Thumbnail = styled.div<ThumbnailProps>`
	width: 50px;
	height: 50px;
	will-change: transform;

	background-image: url(${(props) => props.image});
	background-size: cover;
	background-position: center center;

	margin-right: 10px;
	border-radius: 10px;
`

export const Title = styled(H1)`
	font-weight: 500;
	will-change: transform;

	font-size: 30px;

	overflow: hidden;
	white-space: nowrap;
`

interface GridProps {
	width: number
	height: number
}

export const Grid = styled.div<GridProps>`
	display: grid;
	grid-template-columns: 1fr 1fr;

	grid-gap: 40px;
	padding: 40px;

	width: ${(props) => props.width};
	height: ${(props) => props.height};
`

interface GridItemProps {
	image?: Property.BackgroundImage
}

export const GridItem = styled.div<GridItemProps>`
	display: flex;

	background-image: url(${(props) => props.image});
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;

	// FIXME:
	border-radius: 10px;
`
