import { VerticalGroup } from "@saysom/shared"
import { P } from "../../../assets/style"
import AvatarTile, { VideoQuality } from "../../../entities/avatar/avatarTile"
import { useAvatarStore } from "../../../stores/avatarStore"

interface SingleSpeakerProps {
	speakerId: string
}

const Single = ({ speakerId }: SingleSpeakerProps) => {
	const ownerId = useAvatarStore((state) => state.users["owner"]?.id)
	const _speakerId = speakerId === ownerId ? "owner" : speakerId

	const name = useAvatarStore((state) => state.users[_speakerId]?.name)
	const id = useAvatarStore((state) => state.users[_speakerId]?.id)

	return (
		<VerticalGroup style={{ cursor: "default", overflow: "hidden" }}>
			<P style={{ fontWeight: 600, fontSize: "14px", marginBottom: "10px", marginTop: "5px" }}>{name}:</P>
			<div style={{ paddingTop: "90%", position: "relative" }}>
				<div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
					<AvatarTile
						id={id}
						isHearable={true}
						videoQuality={VideoQuality.High}
						isOwner={_speakerId === "owner"}
						isPresent={true}
						isMegaphoneSpeaker={true}
						isScreenShareSpeaker={false}
					/>
				</div>
			</div>
		</VerticalGroup>
	)
}

export default Single
