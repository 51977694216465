import { useEffect, useRef } from "react"

const CameraPreview = () => {
	const mediaStream = useRef<MediaStream | undefined>(undefined)
	const video = useRef<HTMLVideoElement>(null!)

	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia({ video: { width: 1280, height: 720 } })
			.then((stream) => {
				video.current.srcObject = stream
			})
			.catch((error) => {
				console.error(error)
			})
	}, [])

	useEffect(() => {
		return () => {
			if (mediaStream.current) {
				if ((mediaStream.current as MediaStream).getVideoTracks) {
					mediaStream.current.getVideoTracks().forEach((track) => {
						track.stop()
					})
				} else {
					;((mediaStream.current as unknown) as MediaStreamTrack).stop()
				}
			}
		}
	}, [])

	return (
		<video
			autoPlay
			muted
			playsInline
			ref={video}
			style={{ transform: "scaleX(-1)", objectFit: "cover" }}
			width="100%"
			height="100%"
		/>
	)
}

export default CameraPreview
