import { Button } from "@saysom/shared"
import { useEffect, useRef, useState } from "react"
import FullscreenOff from "../../assets/images/fullscreenOff.svg"
import FullscreenOn from "../../assets/images/fullscreenOn.svg"
import { useInterfaceStore } from "../../stores/interfaceStore"
import Iframe from "../iframe/iframe"
import { Container } from "./interactableView_style"

interface InteractableIFrameViewProps {
	url: string
}

const InteractableIFrameView = ({ url }: InteractableIFrameViewProps) => {
	const [isHovering, setIsHovering] = useState(false)

	// TODO: Mabe ad second variable
	const isScreenshareFullscreen = useInterfaceStore((state) => state.isScreenshareFullscreen)
	const toggleScreenshareFullscreen = useInterfaceStore((state) => state.toggleScreenshareFullscreen)

	const size = useRef([0, 0, 0, 0])
	const container = useRef<HTMLDivElement>(null!)

	useEffect(() => {
		if (!container || !container.current) {
			return
		}

		if (isScreenshareFullscreen) {
			const top = container.current.offsetTop
			const left = container.current.offsetLeft
			const width = container.current.offsetWidth
			const height = container.current.offsetHeight

			container.current.style.position = "absolute"
			container.current.style.top = top + "px"
			container.current.style.left = left + "px"
			container.current.style.right = window.innerWidth - left - width + "px"
			container.current.style.bottom = window.innerHeight - top - height + "px"
			container.current.style.zIndex = "100" // TODO: Remove

			container.current.style.borderRadius = "0"

			size.current = [top, left, window.innerWidth - left - width, window.innerHeight - top - height]

			setTimeout(() => {
				container.current.style.top = "0"
				container.current.style.left = "0"
				container.current.style.right = "0"
				container.current.style.bottom = "0"
			}, 100)
		} else {
			container.current.style.top = size.current[0] + "px"
			container.current.style.left = size.current[1] + "px"
			container.current.style.right = size.current[2] + "px"
			container.current.style.bottom = size.current[3] + "px"
			container.current.style.zIndex = "20" // TODO: Remove

			container.current.style.borderRadius = "10px"

			setTimeout(() => {
				if (container && container.current) {
					container.current.style.position = "relative"

					container.current.style.top = "initial"
					container.current.style.left = "initial"
					container.current.style.right = "initial"
					container.current.style.bottom = "initial"
				}
			}, 400)
		}
	}, [isScreenshareFullscreen])

	return (
		<Container ref={container} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
			<Iframe
				url={url}
				title="IFrame"
				styles={{ width: "100%", height: "100%" }}
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			/>

			<Button
				style={{
					position: "absolute",
					bottom: "10px",
					right: "10px",
					pointerEvents: isScreenshareFullscreen || isHovering ? "all" : "none",
					backgroundColor: `rgba(255,255,255,${isHovering ? 1.0 : 0.7})`,
				}}
				onClick={toggleScreenshareFullscreen}
				image={isScreenshareFullscreen ? FullscreenOff : FullscreenOn}
			/>
		</Container>
	)
}

export default InteractableIFrameView
