import { Button, ChatReceiver, HorizontalGroup, ModeratorType, VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import React, { useEffect, useRef, useState } from "react"
import Send from "../../assets/images/send.svg"
import { Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { useChatStore } from "../../stores/chatStore"
import { useInputStore } from "../../stores/inputStore"
import Message from "../message/message"
import { MessageForm, MessageInput } from "./chat_style"

interface ChatProps {
	id?: string
	name?: string
	style?: Properties
	isGroupChat?: boolean
	moderatorType?: ModeratorType
}

const Chat = ({ style, id, isGroupChat = false, moderatorType = undefined }: ChatProps) => {
	// States
	const [message, setMessage] = useState("")

	// Ref
	const ref = useRef<HTMLDivElement>(null!)

	// Stores
	const actions = useInputStore((state) => state.actions)
	const sendChatMessage = useChatStore((state) => state.sendChatMessage)
	const chat = useChatStore((state) =>
		id ? (isGroupChat ? state.chatHistory?.group[id] : state.chatHistory?.private[id]) : state.chatHistory.all
	)
	const ownerId = useAvatarStore((state) => state.users["owner"].id)

	// Handels
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (isGroupChat) {
			sendChatMessage(message, ChatReceiver.Group, id)
		} else {
			if (id) {
				sendChatMessage(message, ChatReceiver.User, id)
			} else {
				sendChatMessage(message, ChatReceiver.All)
			}
		}

		setMessage("")
	}

	useEffect(() => {
		scrollToBottom()
	}, [chat])

	const scrollToBottom = () => {
		ref.current.scrollTop = ref.current.scrollHeight
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value)
	}

	const handleFoucs = () => {
		actions.updateInputFocus(true)
	}

	const handleBlur = () => {
		actions.updateInputFocus(false)
	}

	return (
		<VerticalGroup spacing={10} style={{ ...style, overflow: "hidden" }} flex={1}>
			<div
				style={{ flex: 1, flexGrow: 1, alignSelf: "stretch", overflowY: "scroll", WebkitOverflowScrolling: "touch" }}
				ref={ref}
			>
				<VerticalGroup spacing={10}>
					{chat &&
						chat.map((chatMessage) => (
							<Message
								key={chatMessage.id}
								message={chatMessage.message}
								author={chatMessage.authorId ? useAvatarStore.getState().users[chatMessage.authorId]?.name : undefined}
								isFromOwner={chatMessage.authorId === ownerId}
								isFromModerator={
									chatMessage.authorId
										? (useAvatarStore.getState().users[chatMessage.authorId] as Owner | User)?.moderatorType !==
										  ModeratorType.None
										: false
								}
							/>
						))}
				</VerticalGroup>
			</div>

			{(moderatorType === undefined || moderatorType !== ModeratorType.None) && (
				<MessageForm onSubmit={handleSubmit}>
					<HorizontalGroup spacing={10} alignItems="center" justifyContent="center">
						<MessageInput
							placeholder="Message"
							type="message"
							value={message}
							onChange={handleChange}
							onFocus={handleFoucs}
							onBlur={handleBlur}
						/>
						<Button disabled={message.length === 0} type="submit" image={Send} />
					</HorizontalGroup>
				</MessageForm>
			)}
		</VerticalGroup>
	)
}

export default Chat
