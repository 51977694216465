import { AreaContentType, HorizontalGroup } from "@saysom/shared"
import React from "react"
import { useAreaStore } from "../../stores/areaStore"
import ImagePicker from "../imagePicker/imagePicker"
import ImageTile from "../imageTile/imageTile"

const AreaContent = ({ id, contentType }: { id: string; contentType: AreaContentType }) => {
	const image = useAreaStore((state) => state.areas[id]?.image)
	const imageUrl = useAreaStore((state) => state.areas[id]?.imageUrl)
	const updateImage = useAreaStore((state) => state.updateArea.image)

	const backgroundColor = useAreaStore((state) => state.areas[id]?.backgroundColor)
	const updateBackgroundColor = useAreaStore((state) => state.updateArea.backgroundColor)

	if (contentType === AreaContentType.Image) {
		return (
			<ImagePicker
				style={{ width: "100%", height: "200px" }}
				existing={image ?? imageUrl}
				onChange={(file: File) => {
					updateImage(id, file, true)
				}}
			/>
		)
	} else if (contentType === AreaContentType.Color) {
		return (
			<HorizontalGroup alignItems="center" justifyContent="space-between" className="pr-1">
				<label className="text-black text-opacity-50">Backgroundcolor</label>

				<ImageTile
					color={backgroundColor ?? "white"}
					onChange={(color) => {
						updateBackgroundColor(id, color, true)
					}}
				/>
			</HorizontalGroup>
		)
	} else {
		return <></>
	}
}

export default AreaContent
