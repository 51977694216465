export class TimeSyncer {
	tickRate: number
	lastPing: number | undefined
	lastServerTime: number | undefined
	lastClientTime: number | undefined

	constructor(tickRate: number) {
		this.tickRate = tickRate
	}

	updateTime = (ping: number, serverTime: number) => {
		this.lastClientTime = new Date().getTime()
		this.lastServerTime = serverTime
		this.lastPing = ping
	}

	get serverTime(): number | undefined {
		if (!(this.lastClientTime && this.lastServerTime && this.lastPing)) return undefined

		const currentClientTime = new Date().getTime()
		const diff = currentClientTime - this.lastClientTime - this.lastPing
		return this.lastServerTime + diff
	}
}
