import { extend, useFrame } from "@react-three/fiber"
import { MeshLine, MeshLineMaterial } from "meshline"
import { memo, useCallback, useMemo, useRef } from "react"
import { Material, MathUtils, Mesh, Shape, Vector3 } from "three"
import "../../components/material/discoMaterial"
import "../../components/material/parallaxMaterial"
import { useDebugStore } from "../../stores/debugStore"
import { useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"
import TextureMaterial from "./textureMaterial"

extend({ MeshLine, MeshLineMaterial })

interface RoundedPlaneProps {
	position: { x: number; y: number }
	size: { width: number; height: number }
	borderRadius: number
	border?: boolean
	image?: string
	borderWidth?: number
	isDisco?: boolean
	ownerOnPlane?: boolean
	backgroundColor?: string
}

const RoundedPlane = memo<RoundedPlaneProps>(
	({
		position,
		size,
		borderRadius,
		image,
		border = true,
		borderWidth = 0.06,
		isDisco = false,
		ownerOnPlane = false,
		backgroundColor = undefined,
	}) => {
		// Reloading
		const logs = useDebugStore((state) => state.logs)
		const debug = useSettingsStore((state) => state.debug)
		if (debug && logs.reload) console.log("Rounded Plane Reload")

		// TODO: FIX THIS SOMEWHERE ELSE
		position = { x: parseFloat("" + position.x), y: parseFloat("" + position.y) }

		// Rounded Rectangle Shape
		const roundedRectangle = useMemo(() => {
			const roundedRectShape = new Shape()

			// now is correct not upside down
			const width = size.width
			const height = size.height

			const radius = borderRadius

			roundedRectShape.moveTo(0, -radius)
			roundedRectShape.lineTo(0, -height + radius)
			roundedRectShape.quadraticCurveTo(0, -height, radius, -height)
			roundedRectShape.lineTo(width - radius, -height)
			roundedRectShape.quadraticCurveTo(width, -height, width, -height + radius)
			roundedRectShape.lineTo(width, -radius)
			roundedRectShape.quadraticCurveTo(width, 0, width - radius, 0)
			roundedRectShape.lineTo(radius, 0)
			roundedRectShape.quadraticCurveTo(0, 0, 0, -radius)

			return roundedRectShape
		}, [size, borderRadius])

		const onUpdate = useCallback(
			(self) => {
				var uvAttribute = self.attributes.uv

				const x = size.width / 2
				const y = size.height / 2

				for (var i = 0; i < uvAttribute.count; i++) {
					var u = uvAttribute.getX(i)
					var v = uvAttribute.getY(i)

					uvAttribute.setXY(i, (u + x) / size.width - 0.5, (v = (v + y) / size.height) + 0.5)
				}
			},
			[size]
		)

		const mesh = useRef<Mesh>(null)
		const borderMesh = useRef<Mesh>(null)
		const material = useRef<Material>(null)

		// @ts-ignore
		useFrame(() => {
			if (material && material.current) {
				if (isDisco) {
					if (ownerOnPlane) {
						// @ts-ignore
						material.current.time = useSceneStore.getState().clock.elapsedTime
						// @ts-ignore
						material.current.on = 1.0
					} else {
						// @ts-ignore
						material.current.on = 0.0
					}
				}
			}
			/* const difference = new Vector2(mesh.current.position.x, mesh.current.position.z).sub(
					new Vector2(camera.position.x, camera.position.z)
				) */

			// @ts-ignore
			//material.current.shift = difference

			//material.current.scale = lerp(material.current.scale, offsetFactor - top / ((pages - 1) * viewportHeight), 0.1)*/
		})

		return (
			<group>
				<mesh ref={mesh} position={[position.x, -0.02, position.y]} rotation={[MathUtils.degToRad(-90), 0, 0]}>
					<shapeGeometry attach="geometry" args={[roundedRectangle]} onUpdate={onUpdate} />
					{
						image ? (
							<TextureMaterial size={size} image={image} />
						) : isDisco ? (
							//@ts-ignore
							<discoMaterial ref={material} attach="material" args={[size.width, size.height]} />
						) : (
							<meshBasicMaterial transparent attach="material" color={backgroundColor ?? "white"} opacity={0.2} />
						)

						//@ts-ignore
						/* <parallaxMaterial ref={material} attach="material" tex={texture} args={[size.width, size.height]} /> */
					}
				</mesh>

				{border && (
					<mesh ref={borderMesh} position={[position.x, -0.02, position.y + size.height]}>
						{/* @ts-ignore */}
						<meshLine
							attach="geometry"
							points={roundedRectangle.getPoints().map((point) => {
								return new Vector3(point.x, 0, point.y)
							})}
						/>
						{/* @ts-ignore */}
						<meshLineMaterial
							attach="material"
							transparent
							depthTest={false}
							lineWidth={borderWidth}
							color={backgroundColor ?? "white"}
						/>
					</mesh>
				)}
			</group>
		)
	}
)

export default RoundedPlane
