import { DailyParticipant, DailyTrackState } from "@daily-co/daily-js"
import { SpeakerType } from "@saysom/shared"
import { Property } from "csstype"
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useAvatarStore } from "../../stores/avatarStore"
import { useCommunicationStore } from "../../stores/communicationStore"
import { useSpeakerStore } from "../../stores/speakerStore"
import { Video } from "./avatarTile_style"

export enum VideoQuality {
	High,
	Low,
	Off,
}

/* function getTrackUnavailableMessage(kind: string, trackState?: DailyTrackState) {
	if (!trackState) return

	switch (trackState.state) {
		case "blocked":
			if (trackState.blocked?.byPermissions) {
				return `${kind} permission denied`
			} else if (trackState.blocked?.byDeviceMissing) {
				return `${kind} device missing`
			}
			break
		case "off":
			if (trackState.off?.byUser) {
				return `${kind} muted`
			} else if (trackState.off?.byBandwidth) {
				return `${kind} muted to save bandwidth`
			}
			break
		case "sendable":
			return `${kind} not subscribed`
		case "loading":
			return `${kind} loading...`
		case "interrupted":
			return `${kind} interrupted`
		case "playable":
			return null

		default:
			return null
	}

	return null
} */

export enum PresenceState {
	Normal,
	Connecting,
	Blocked,
	Megaphone,
}

const presenceStateForTrackState = (
	isMegaphoneSpeaker: boolean, // FIXME: Naming or switch statement
	audioTrackState?: DailyTrackState,
	videoTrackState?: DailyTrackState
): PresenceState => {
	if (!audioTrackState || !videoTrackState) return PresenceState.Connecting

	if (isMegaphoneSpeaker) return PresenceState.Megaphone

	if (
		audioTrackState.state === "interrupted" ||
		videoTrackState.state === "interrupted" ||
		audioTrackState.state === "blocked" ||
		videoTrackState.state === "blocked"
	) {
		return PresenceState.Blocked
	}

	if (audioTrackState.state === "loading" || videoTrackState.state === "loading") {
		return PresenceState.Connecting
	}

	return PresenceState.Normal
}

interface AvatarTileProps {
	id: string
	isOwner: boolean
	isMegaphoneSpeaker?: boolean
	isScreenShareSpeaker?: boolean
	isPresent: boolean
	isHearable: boolean
	videoQuality: VideoQuality

	onPresenceStateChange?: (status: PresenceState) => void
	onAudioMute?: (mute: boolean) => void
	onVideoMute?: (mute: boolean) => void

	objectFit?: Property.ObjectFit
}

const AvatarTile = memo<AvatarTileProps>(
	({
		id,
		isOwner,
		isMegaphoneSpeaker = false,
		isScreenShareSpeaker = false,
		isPresent,
		isHearable,
		videoQuality,
		onPresenceStateChange = undefined,
		onAudioMute = undefined,
		onVideoMute = undefined,
		objectFit = undefined,
	}) => {
		// Store
		const updateAudioRef = useAvatarStore((state) => state.actions.updateAudioRef)

		const videoEl = useRef<HTMLVideoElement>(null)
		const audioEl = useRef<HTMLAudioElement>(null)

		const videoScreenEl = useRef<HTMLVideoElement>(null)
		const audioScreenEl = useRef<HTMLAudioElement>(null)

		const room = useCommunicationStore((state) => state.room)
		const participants = useCommunicationStore((state) => state.participants)
		const [participant, setParticipant] = useState<DailyParticipant | undefined>()

		const [videoTrackState, setVideoTrackState] = useState<DailyTrackState | undefined>(undefined)
		const [audioTrackState, setAudioTrackState] = useState<DailyTrackState | undefined>(undefined)

		const [videoScreenTrackState, setVideoScreenTrackState] = useState<DailyTrackState | undefined>(undefined)
		const [audioScreenTrackState, setAudioScreenTrackState] = useState<DailyTrackState | undefined>(undefined)

		useEffect(() => {
			if (room && participant && !isOwner) {
				room?.updateParticipant(participant.session_id, {
					setSubscribedTracks: videoQuality !== VideoQuality.Off || isHearable || isPresent,
				})
			}
		}, [id, room, isHearable, isPresent, isOwner, participant, videoQuality, isScreenShareSpeaker])

		const setAudioRef = useCallback(
			(newRef: HTMLAudioElement | null) => {
				if (newRef === null) {
					updateAudioRef(id, undefined)
				} else {
					updateAudioRef(id, newRef)
				}
			},
			[updateAudioRef, id]
		)

		useEffect(() => {
			const _participant = participants[id]

			if (_participant) {
				setParticipant(_participant)

				setVideoTrackState(_participant.tracks.video)
				setAudioTrackState(_participant.tracks.audio)

				setVideoScreenTrackState(_participant.tracks.screenVideo)
				setAudioScreenTrackState(_participant.tracks.screenAudio)
			}
		}, [participants, id])

		useEffect(() => {
			if (participant) {
				try {
					// @ts-ignore
					const sfu = window.rtcpeers?.sfu

					if (sfu) {
						const consumer = sfu.consumers[participant.session_id + "/cam-video"]
						if (videoQuality === VideoQuality.High) {
							// TODO: Fix what does happen when not available
							sfu.setConsumerLayer(consumer, 1)
						} else if (videoQuality === VideoQuality.Low) {
							// TODO: Fix what does happen when not available
							sfu.setConsumerLayer(consumer, 0)
						}
					}
				} catch (err) {
					console.log("Setting simulcatst down stream failed " + err)
				}
			}
		}, [participant, videoQuality])

		const videoTrack = useMemo(() => {
			// TODO: Support Low Quality

			return isPresent &&
				!isScreenShareSpeaker &&
				(videoQuality === VideoQuality.High || videoQuality === VideoQuality.Low) &&
				videoTrackState &&
				videoTrackState.state === "playable"
				? videoTrackState.track
				: null
		}, [videoTrackState, videoQuality, isPresent, isScreenShareSpeaker])

		const audioTrack = useMemo(() => {
			return !isOwner &&
				!isScreenShareSpeaker &&
				(isHearable || isMegaphoneSpeaker) &&
				audioTrackState &&
				audioTrackState.state === "playable"
				? audioTrackState.track
				: null
		}, [audioTrackState, isHearable, isMegaphoneSpeaker, isOwner, isScreenShareSpeaker])

		const videoScreenTrack = useMemo(() => {
			// TODO: This is bullshit but there are no callbacks from daily for screen share

			if (isMegaphoneSpeaker && videoScreenTrackState && videoScreenTrackState.state === "playable") {
				// useCommunicationStore.getState().speakerActions.updateScreenShare(true)
			} else {
				// useCommunicationStore.getState().speakerActions.updateScreenShare(false)
			}

			return isPresent && isScreenShareSpeaker && videoScreenTrackState && videoScreenTrackState.state === "playable"
				? videoScreenTrackState.track
				: null
		}, [videoScreenTrackState, isPresent, isMegaphoneSpeaker, isScreenShareSpeaker])

		const audioScreenTrack = useMemo(() => {
			return !isOwner && isScreenShareSpeaker && audioScreenTrackState && audioScreenTrackState.state === "playable"
				? audioScreenTrackState.track
				: null
		}, [audioScreenTrackState, isScreenShareSpeaker, isOwner])

		useEffect(() => {
			if (onPresenceStateChange) {
				onPresenceStateChange(presenceStateForTrackState(isMegaphoneSpeaker, audioTrackState, videoTrackState))
			}
		}, [audioTrackState, videoTrackState, onPresenceStateChange, isMegaphoneSpeaker])

		useEffect(() => {
			if (!onAudioMute) return

			if (audioTrackState?.off) {
				onAudioMute(true)
			} else {
				onAudioMute(false)
			}
		}, [audioTrackState, onAudioMute])

		useEffect(() => {
			if (!onVideoMute) return

			if (videoTrackState?.off) {
				onVideoMute(true)
			} else {
				onVideoMute(false)
			}
		}, [videoTrackState, onVideoMute])

		/**
		 * When video track changes, update video srcObject
		 */
		useEffect(() => {
			// console.log("Reload Video Track for: " + id)
			videoTrack && videoEl.current && (videoEl.current.srcObject = new MediaStream([videoTrack]))
		}, [videoTrack, id])

		/**
		 * When audio track changes, update audio srcObject
		 */
		useEffect(() => {
			//console.log("Reload Audio Track for: " + id)
			if (audioTrack && audioEl.current) {
				audioEl.current.srcObject = new MediaStream([audioTrack])
				setAudioRef(audioEl.current)
			}
		}, [audioTrack, id, setAudioRef])

		/**
		 * When video screen track changes, update video srcObject
		 */
		useEffect(() => {
			// console.log("Reload Video Track for: " + id)
			videoScreenTrack &&
				videoScreenEl.current &&
				(videoScreenEl.current.srcObject = new MediaStream([videoScreenTrack]))
		}, [videoScreenTrack, id])

		useEffect(() => {
			if (videoScreenTrack) {
				videoScreenTrack.onended = () => {
					room?.stopScreenShare()
					if (
						useSpeakerStore.getState().speaker[id] &&
						useSpeakerStore.getState().speaker[id]?.speakerType === SpeakerType.ScreenShare
					) {
						useSpeakerStore.getState().request.change(id, SpeakerType.Megaphone)
					}
				}
			}
		}, [videoScreenTrack, room, id])

		/**
		 * When audio screen track changes, update audio srcObject
		 */
		useEffect(() => {
			//console.log("Reload Audio Track for: " + id)
			audioScreenTrack &&
				audioScreenEl.current &&
				(audioScreenEl.current.srcObject = new MediaStream([audioScreenTrack]))
		}, [audioScreenTrack, id])

		return (
			<>
				{/* {getOverlayComponent()} */}
				{videoTrack && (
					<Video
						autoPlay
						controls={false}
						muted
						playsInline
						ref={videoEl}
						style={{
							transform: isOwner ? "rotateY(180deg)" : "",
							objectFit: objectFit ? objectFit : "cover",
						}}
					/>
				)}

				{audioTrack && <audio autoPlay controls={false} playsInline ref={audioEl} />}
				{videoScreenTrack && <Video autoPlay muted playsInline ref={videoScreenEl} />}
				{audioScreenTrack && <audio autoPlay playsInline ref={audioScreenEl} />}
			</>
		)
	}
)

export default AvatarTile
