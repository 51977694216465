export const config = {
	epsilon: 0.01,

	server: {
		tickRate: 100,
		tickLag: 200,
	},

	servers: {
		"naXvWeAs0-": "https://saysom-1.saysom.app",
		vGsykHbPFR: "https://saysom-2.saysom.app",
		aVp7ih03U7: "https://saysom-3.saysom.app",
		FgCmVwrXua: "https://saysom-4.saysom.app",
		cXlCPXXfMk: "https://saysom-5.saysom.app",
		QYMK2aQvO6: "https://saysom-6.saysom.app",
		"5ldAubcvn-": "https://saysom-7.saysom.app",
	},

	client: {
		tickRate: 100,
	},

	audio: {
		maxHearDist: 1.5,
		minFullHearDist: 1,
	},

	camera: {
		initialZoom: 40,
		zoomMultiplier: 5,
		zoomBounds: [25, 300], // left: far, right: near
	},

	video: {
		maxVideoOnCount: 30,
		maxVideoHighQualityCount: 10,
	},

	avatar: {
		noCameraEmojis: ["😀", "🥸", "🤖", "🤓", "🤠", "🥳"],
	},
}

export interface CustomSpaceConfig {
	title: string
	rooms: string[]
	noCameraEmojis?: string[]
	customUrl?: string
	colors?: string[]
	disableScreenshare?: boolean
}

export const customSpaces: CustomSpaceConfig[] = [
	{
		title: "DNV",
		rooms: ["PYxLNmyjhk", "ZEmElaqcen", "uvuYmKY66a", "qUmPwhAuqd"],
		noCameraEmojis: [
			"https://saysom.s3.eu-central-1.amazonaws.com/custom/Surveyor+flowers.PNG",
			"https://saysom.s3.eu-central-1.amazonaws.com/custom/Surveyor_move.PNG",
			"https://saysom.s3.eu-central-1.amazonaws.com/custom/Surveyor_phone.PNG",
		],
		customUrl: "/dnv",
		colors: ["#91ffb4", "#003591"],
	},
	{
		title: "Lounge",
		rooms: ["rCbiNrg7cY"],
		disableScreenshare: true,
	},
	{ title: "Lexxion", rooms: ["-xF4Vi2N_I"] },
]

export const customSpaceForSpaceCode = (code: string): CustomSpaceConfig | undefined => {
	return customSpaces.find((space) => space.rooms.includes(code))
}
