import { VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import { P } from "../../assets/style"
import Linkify from "./linkify"

interface MessageProps {
	closeToast?: () => void
	message: string
	author: string | undefined
	isFromOwner?: boolean
	isFromModerator?: boolean
	style?: Properties
}

const Message = ({ message, author, isFromOwner = false, isFromModerator = false, style }: MessageProps) => {
	return (
		<VerticalGroup
			style={{ padding: "10px", ...style, userSelect: "text", minWidth: "100px" }}
			alignSelf={isFromOwner ? "flex-end" : "flex-start"}
			alignItems={isFromOwner ? "flex-end" : "flex-start"}
			className={`${isFromOwner ? "bg-action text-white ml-5" : "bg-white text-black mr-5"} rounded-lg`}
		>
			<P style={{ userSelect: "text", fontWeight: 600, fontSize: "12px" }} color="inherit">
				{isFromOwner ? "You" : author}

				{isFromModerator && " (Moderator)"}
			</P>
			<P style={{ userSelect: "text" }} color="inherit">
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
						<a
							target="blank"
							href={decoratedHref}
							key={key}
							style={{
								textDecoration: "underline",
							}}
						>
							{decoratedText}
						</a>
					)}
				>
					{message}
				</Linkify>
			</P>
		</VerticalGroup>
	)
}

export default Message
