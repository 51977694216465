import DailyIframe, {
	DailyCall,
	DailyEventObjectParticipant,
	DailyEventObjectParticipantLeft,
	DailyParticipant,
	DailyParticipantsObject,
} from "@daily-co/daily-js"
import { create } from "zustand"
import { useAvatarStore } from "./avatarStore"
import { useSettingsStore } from "./settingsStore"

type CommunicationStore = {
	room: DailyCall | undefined
	baseURL: string

	state: SocketConnectionState
	participants: Record<string, DailyParticipant>

	join: (spaceId: string, token: string) => void
	leave: () => void

	ioActions: {
		updateMicrophone: (id: string) => void
		updateSpeaker: (id: string) => void
		updateCamera: (id: string) => void
		muteAudio: (mute: boolean) => void
		muteVideo: (mute: boolean) => void
	}

	resetStore: () => void
}

export enum SocketConnectionState {
	disconnected = "Disconnected",
	connecting = "Connecting",
	connected = "Connected",
	reconnecting = "Reconnecting",
}

export const useCommunicationStore = create<CommunicationStore>((set, get) => {
	return {
		room: undefined,
		baseURL: "https://saysom.daily.co/",

		participants: {},

		state: SocketConnectionState.disconnected,

		resetStore() {
			const { leave } = get()
			leave()

			set({
				room: undefined,
				participants: {},
				state: SocketConnectionState.disconnected,
			})
		},

		join: async (spaceId, token) => {
			let { room, baseURL, participants } = get()

			set({ state: SocketConnectionState.connecting })

			const microphone = useSettingsStore.getState().selectedMicrophone
			const camera = useSettingsStore.getState().selectedCamera

			room = DailyIframe.createCallObject({
				subscribeToTracksAutomatically: false,
				dailyConfig: {
					experimentalChromeVideoMuteLightOff: true,
					// camSimulcastEncodings: [
					// 	{ maxBitrate: 100000, maxFramerate: 2, scaleResolutionDownBy: 10 }, // 20 -> 10
					// 	{ maxBitrate: 600000, maxFramerate: 20, scaleResolutionDownBy: 4 },
					// ],
				},
			})

			const roomURL = baseURL + spaceId
			await room.join({ url: roomURL, token: token }) // TODO: Catching here?
			await room.setNetworkTopology({ topology: "sfu" })
			await room.setInputDevicesAsync({ audioDeviceId: microphone, videoDeviceId: camera }) // TODO: Need to create own Mediastream?

			await room.setBandwidth({
				// @ts-ignore
				//trackConstraints: { width: 256, height: 256, maxFramerate: 20 },
			})

			Object.entries(room.participants() as DailyParticipantsObject).forEach(([_id, participant]) => {
				participants[participant.user_id] = participant
			})

			// Input devices

			room.on("participant-joined", (event: DailyEventObjectParticipant | undefined) => {
				if (!event) return // TODO: Log error

				const { participants } = get()
				const { participant } = event

				if (participant.user_id) {
					participants[participant.user_id] = participant
				}

				// TODO: Maybe filter similar to twilio?
				set({ participants: { ...participants } })
			})

			room.on("participant-updated", (event: DailyEventObjectParticipant | undefined) => {
				if (!event) return // TODO: Log error

				const { participants } = get()
				const { participant } = event

				const userId = participant.user_id
				if (userId) {
					participants[userId] = participant

					if (participant.screenVideoTrack !== undefined) {
						useAvatarStore.getState().actions.updateHasScreenshare(userId, true)
					} else {
						useAvatarStore.getState().actions.updateHasScreenshare(userId, false)
					}
				}

				// TODO: Maybe filter similar to twilio?
				set({ participants: { ...participants } })
			})

			room.on("participant-left", (event: DailyEventObjectParticipantLeft | undefined) => {
				if (!event) return // TODO: Log error

				const { participants } = get()
				const { participant } = event

				if (participant.user_id) {
					delete participants[participant.user_id]
				}

				// TODO: Maybe filter similar to twilio?
				set({ participants: { ...participants } })
			})

			// room.on("track-started", (event: DailyEventObjectTrack | undefined) => {
			// 	event.
			// })

			set({ room, participants: { ...participants } })
		},
		leave: () => {
			const { room } = get()

			if (room) {
				room.leave()
			}
		},

		ioActions: {
			updateMicrophone: (id: string) => {
				const { room } = get()

				if (room) {
					// TODO: working?
					room.setInputDevices({ audioDeviceId: id })
				}
			},

			updateSpeaker: (id: string) => {
				const { room } = get()

				if (room) {
					room.setOutputDevice({ outputDeviceId: id })
					console.log(room)
				}
			},

			updateCamera: (id: string) => {
				const { room } = get()

				if (room) {
					room.setInputDevices({ videoDeviceId: id })
				}
			},

			muteAudio: (mute: boolean) => {
				const { room } = get()

				if (room) {
					room.setLocalAudio(!mute)
				}
			},

			muteVideo: (mute: boolean) => {
				const { room } = get()

				if (room) {
					room.setLocalVideo(!mute)
				}
			},
		},
	}
})
