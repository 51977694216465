import { HorizontalGroup } from "@saysom/shared"
import styled from "styled-components"

export const Top = styled(HorizontalGroup)`
	border-radius: 10px;
	position: relative;
	z-index: 2;

	padding: 16px 12px;
`

export const Bottom = styled.div`
	border-radius: 0 0 10px 10px;
	overflow: hidden;

	//padding: 20px 12px;
`

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	background: none;
	border-radius: 10px;
	border: 0;
	outline: 0;
	cursor: pointer;

	width: 36px;
	height: 36px;

	transition: all 200ms ease-in-out;

	:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	:active {
		background-color: rgba(0, 0, 0, 0.1);
	}

	:disabled {
		pointer-events: none;
		color: rgb(180, 180, 180);
	}
`

export const ButtonImage = styled.img.attrs({ draggable: false })`
	cursor: pointer;
`

export const SpaceImage = styled.img.attrs({ draggable: false })`
	width: 36px;
	height: 36px;

	border-radius: 10px;
	object-fit: contain;
`

export const Banner = styled(HorizontalGroup)`
	display: flex;
	justify-content: flex-end;
	border-radius: 0 0 10px 10px;
	background-color: white;
	overflow: hidden;
`

export const BannerImage = styled.img.attrs({ draggable: false })`
	width: 100%;
	height: 100%;

	object-fit: scale-down;
`

export const BannerButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	background: none;
	border-radius: 10px;
	border: 0;
	outline: 0;
	cursor: pointer;
	margin-left: -36px;

	width: 36px;
	height: 36px;

	transition: all 200ms ease-in-out;

	:hover {
		transform: scale(1.05);
	}

	:active {
		transform: scale(0.9);
	}

	:disabled {
		pointer-events: none;
		color: rgb(180, 180, 180);
	}
`

export const BannerButtonImage = styled.img.attrs({ draggable: false })`
	cursor: pointer;
`
