import { Button, Colors, HorizontalGroup, ModeratorType } from "@saysom/shared"
import { Properties } from "csstype"
import { memo, useState } from "react"
import AddAdmin from "../../assets/images/addAdmin.svg"
import AddModerator from "../../assets/images/addModerator.svg"
import Chat from "../../assets/images/chat.svg"
import Pin from "../../assets/images/pin.svg"
import RemoveModerator from "../../assets/images/removeModerator.svg"
import { P } from "../../assets/style"
import { Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { Target, useSceneStore } from "../../stores/sceneStore"

interface ParticipantProps {
	id: string
	alternateRow: boolean
	isOwner?: boolean
	style?: Properties
	isGroup?: boolean
	name: string
	ownerModeratorType?: ModeratorType
	onSelectedChatUserChange?: (userId: string) => void
}

const Participant = memo<ParticipantProps>(
	({
		id,
		alternateRow,
		isOwner = false,
		isGroup = false,
		style,
		onSelectedChatUserChange,
		name,
		ownerModeratorType,
	}) => {
		const setTargetFromId = useSceneStore((state) => state.target.setTargetFromId)
		const setTarget = useSceneStore((state) => state.target.setTarget)
		const targetId = useSceneStore((state) => state.target.id)

		const [isHovering, setIsHovering] = useState(false)

		const requestModerator = useAvatarStore((state) => state.request.moderator)
		const moderatorType = useAvatarStore((state) => (state.users[id] as Owner | User)?.moderatorType)
		const email = useAvatarStore((state) => (state.users[id] as Owner | User)?.email)

		return (
			<HorizontalGroup
				justifyContent="space-between"
				alignItems="center"
				style={{
					background: alternateRow ? "#f7f7f7" : "white",
					padding: "10px",
					borderBottom: `1px solid ${alternateRow ? "white" : "#f3f3f3"}`,
					...style,
					height: "58px",
				}}
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}
				spacing={5}
			>
				<P style={{ flex: 1 }}>
					{name}
					{isOwner && " (You)"}
				</P>

				{!isOwner && isHovering && (
					<>
						{!isGroup && (
							<Button
								onClick={() => {
									if (targetId === id) {
										setTarget(undefined, Target.Object)
									} else {
										setTargetFromId(id)
									}
								}}
								style={{
									borderColor: targetId === id ? Colors.action : "transparent",
								}}
								image={Pin}
							/>
						)}
						{onSelectedChatUserChange && <Button image={Chat} onClick={() => onSelectedChatUserChange(id)} />}
						{ownerModeratorType === ModeratorType.Normal &&
							moderatorType !== undefined &&
							moderatorType !== ModeratorType.Admin && (
								<Button
									data-tip={moderatorType === ModeratorType.Normal ? "Remove Moderator" : "Add Moderator"}
									image={moderatorType === ModeratorType.Normal ? RemoveModerator : AddModerator}
									onClick={() => {
										if (email) {
											requestModerator(
												email,
												moderatorType === ModeratorType.Normal ? ModeratorType.None : ModeratorType.Normal
											)
										}
									}}
								/>
							)}
						{ownerModeratorType === ModeratorType.Admin && moderatorType !== undefined && (
							<HorizontalGroup style={{ background: "rgba(128,128,128,0.2)", borderRadius: "10px" }}>
								<Button
									data-tip="Remove Moderator"
									style={{ background: moderatorType === ModeratorType.None ? "rgba(128,128,128,0.3)" : "none" }}
									image={RemoveModerator}
									onClick={() => {
										if (email) {
											requestModerator(email, ModeratorType.None)
										}
									}}
								/>
								<Button
									data-tip="Add Moderator"
									style={{ background: moderatorType === ModeratorType.Normal ? "rgba(128,128,128,0.3)" : "none" }}
									image={AddModerator}
									onClick={() => {
										if (email) {
											requestModerator(email, ModeratorType.Normal)
										}
									}}
								/>
								<Button
									data-tip="Add Admin"
									style={{ background: moderatorType === ModeratorType.Admin ? "rgba(128,128,128,0.3)" : "none" }}
									image={AddAdmin}
									onClick={() => {
										if (email) {
											requestModerator(email, ModeratorType.Admin)
										}
									}}
								/>
							</HorizontalGroup>
						)}
					</>
				)}
			</HorizontalGroup>
		)
	}
)

export default Participant
