import { VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import { P } from "../../assets/style"

// closeToast = false
interface NotificationProps {
	closeToast?: () => void
	message: string
	author: string | undefined
	style?: Properties
}

const Notification = ({ message, author, style }: NotificationProps) => {
	return (
		<VerticalGroup style={{ padding: "10px", ...style }}>
			<P style={{ fontWeight: 600, fontSize: "12px" }}>{author}</P>
			<P>{message}</P>
		</VerticalGroup>
	)
}

export default Notification
