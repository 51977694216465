import { toast } from "react-toastify"

export const showError = (error: Error) => {
	toast(`Error: ${error.message}`, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}
