import { useAuthStore } from "../stores/authStore"
import { showError } from "../utils/errorHandler"
import { history } from "../utils/History"
import { fetchApi } from "./fetch"

export const createToken = async (
	name: string,
	email: string | undefined = undefined,
	redirect: string | undefined
) => {
	try {
		const token = await fetchApi("/auth/create", { email, name }, "POST")
		if (token.message) {
			showError(new Error(token.message))
		} else {
			useAuthStore.getState().update(token)
			if (redirect) {
				history.push(redirect)
			}
		}
	} catch (e: any) {
		showError(e)
	}
}
