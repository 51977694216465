import { ChatMessage } from "@saysom/shared"
import { toast } from "react-toastify"
import { create } from "zustand"
import ChatNotification from "../components/notification/chatNotification"
import NotificationComponent from "../components/notification/notification"
import SpeakerNotification from "../components/notification/speakerNotification"
import { useAvatarStore } from "./avatarStore"

export type Notification = {
	author: string | undefined
	message: string
	receiver: string[] | undefined
}

type NotificationStore = {
	show: (message: Notification | string, persist?: boolean) => void
	showChatNotification: (chatMessage: ChatMessage) => void
	showError: (message: string) => void
	onSpeaker: (speakerId: string) => void
}

export const useNotificationStore = create<NotificationStore>((_set, _get) => {
	return {
		show(message: Notification | string, persist = false) {
			const _message = typeof message === "string" ? message : message.message
			const _author = typeof message === "string" ? undefined : message.author
			toast(<NotificationComponent message={_message} author={_author} />, {
				position: "top-right",
				autoClose: persist ? false : 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
		},

		showError(message: string) {
			toast(`Error: ${message}`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
		},

		showChatNotification(chatMessage: ChatMessage) {
			let authorName: string | undefined = undefined
			if (chatMessage.authorId) {
				const user = useAvatarStore.getState().users[chatMessage.authorId]
				if (user) authorName = user.name
			}

			toast(
				<ChatNotification
					message={chatMessage.message}
					authorId={chatMessage.authorId}
					name={authorName}
					timestamp={chatMessage.timestamp}
					receiverType={chatMessage.receiverType}
					receiverId={chatMessage.receiverId}
				/>,
				{
					position: "top-right",
					autoClose: 4000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					closeButton: false,
					progress: undefined,
				}
			)
		},

		onSpeaker: () => {
			if (!toast.isActive("speaker")) {
				toast(<SpeakerNotification />, {
					toastId: "speaker",
					position: "top-right",
					autoClose: false,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
					closeButton: false,
				})
			}
		},
	}
})
