import { HorizontalGroup, VerticalGroup } from "@saysom/shared"
import styled from "styled-components"
import { Form, Input } from "../../assets/style"

export const Container = styled(VerticalGroup)`
	position: absolute;
	left: 50%;
	bottom: 100px;
	z-index: 4;

	align-items: center;

	width: 500px;
	margin-left: -250px;

	pointer-events: auto;
`
export const SubmitContainer = styled(HorizontalGroup)`
	background-color: white;

	align-items: center;
	justify-content: space-between;

	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	padding: 12px;

	align-self: stretch;

	border-radius: 1000px;
`
export const HistoryContainer = styled.div`
	background-color: white;

	max-height: 500px;
	overflow-y: auto;

	align-items: center;
	justify-content: space-between;

	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	padding: 12px;

	align-self: stretch;

	border-radius: 20px;
`

export const MessageForm = styled(Form)``

export const MessageInput = styled(Input)`
	border: none;
	box-shadow: none;

	height: 42px;
	flex: 1;

	margin-top: 0px;
	padding: 0px 10px;
	border-radius: 100px;

	:focus {
		border: none;
	}
`

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
	border-radius: 10000px;
	border: 0;
	outline: 0;
	cursor: pointer;

	width: 42px;
	height: 42px;

	transition: all 200ms ease-in-out;

	:hover {
		transform: scale(1.05);
	}

	:active {
		transform: scale(0.9);
	}

	:disabled {
		pointer-events: none;
		color: rgb(180, 180, 180);
		opacity: 0.75;
	}
`

export const ButtonImage = styled.img.attrs({ draggable: false })`
	cursor: pointer;
`
