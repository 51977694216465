import { extend } from "@react-three/fiber"
import { ShaderMaterial, Texture, Vector2 } from "three"

const vertexShaderText = /* glsl */ `
	varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`

const fragmentShaderText = /* glsl */ `
	//precision highp float;
	varying vec2 vUv;
	uniform vec2 shift;
	uniform sampler2D tex;
	
    void main() {
		vec2 uv = vUv  * 0.8 + vec2(0.1, 0.1);
		vec4 _tex = texture2D(tex, uv + shift * 0.01 * vec2(-1.0, 1.0));
		// vec4 _tex = texture2D(tex, uv + shift * 0.1 * vec2(-1.0, 1.0));
		
		gl_FragColor = _tex;
    }`

class ParallaxMaterial extends ShaderMaterial {
	constructor(width: number, height: number) {
		console.log(width, height)
		super({
			defines: {
				WIDTH: width + 0.001,
				HEIGHT: height + 0.001,
			},
			uniforms: {
				tex: { value: undefined },
				shift: { value: new Vector2(0, 0) },
			},
			vertexShader: vertexShaderText,
			fragmentShader: fragmentShaderText,
			transparent: true,
		})
	}

	set tex(v: Texture) {
		this.uniforms.tex.value = v
	}

	set shift(v: Vector2) {
		this.uniforms.shift.value = v
	}
}

extend({ ParallaxMaterial })
