import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { PanelType } from "./../components/interface/interface"

type InterfaceStore = {
	selectedPanel: PanelType
	updateSelectedPanel: (panel: PanelType) => void

	isScreenshareFullscreen: boolean
	toggleScreenshareFullscreen: () => void

	screenShareUsers: Set<string>
	setScreenShareUsers: (users: string[]) => void
}

export const useInterfaceStore = create(
	immer<InterfaceStore>((set, _get) => ({
		selectedPanel: PanelType.None,
		updateSelectedPanel: (panel) => {
			set((state) => {
				state.selectedPanel = panel
			})
		},

		isScreenshareFullscreen: false,
		toggleScreenshareFullscreen: () => {
			set((state) => {
				state.selectedPanel = PanelType.None
				state.isScreenshareFullscreen = !state.isScreenshareFullscreen
			})
		},

		screenShareUsers: new Set(),
		setScreenShareUsers: (users) => {
			set((state) => {
				state.screenShareUsers = new Set(users)
			})
		},
	}))
)
