export const volumeFalloff = (dist, maxHearDist, minFullHearDist) => {
	if (dist >= maxHearDist) return 0
	if (dist <= minFullHearDist) return 1

	// Inverted sigmoid falloff
	// Sigmoid y -> 0 for dist -> maxHearDist
	// Sigmoid y -> 1 for dist -> minFullHearDist
	// Sigmoid fix point always at (maxHearDist + minFullHearDist) / 2
	// Sigmoid linear part streches over [minFullHearDist, maxHearDist]

	const sigmoidCenterX = (maxHearDist + minFullHearDist) / 2
	const sigmoidLinearScale = (maxHearDist - minFullHearDist) / 4

	const invSigmoid = 0.5 * Math.tanh((-dist + sigmoidCenterX) / sigmoidLinearScale) + 0.5

	return invSigmoid
}
