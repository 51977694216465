import { AreaContentType, Button, HorizontalGroup, Input, VerticalGroup } from "@saysom/shared"
import { toast } from "react-toastify"
import Back from "../../assets/images/back.svg"
import { H3 } from "../../assets/style"
import { useAreaStore } from "../../stores/areaStore"
import { useInputStore } from "../../stores/inputStore"
import { checkIfCanBeInsideIframe, parseAndCreateBestFitUrl } from "../../utils/urlParser"
import { CancelButton, default as ActionButton } from "../button/button"
import ImagePicker from "../imagePicker/imagePicker"
import AreaContent from "./areaContent"

interface AreaEditorProps {
	id: string
	onBack: () => void
}

const AreaEditor = ({ id, onBack }: AreaEditorProps) => {
	// Stores
	const actions = useInputStore((state) => state.actions)
	const position = useAreaStore((state) => state.areas[id]?.position)
	const size = useAreaStore((state) => state.areas[id]?.size)
	const iconUrl = useAreaStore((state) => state.areas[id]?.iconUrl)
	const iconImage = useAreaStore((state) => state.areas[id]?.iconImage)
	const title = useAreaStore((state) => state.areas[id]?.title)
	const description = useAreaStore((state) => state.areas[id]?.description)
	const url = useAreaStore((state) => state.areas[id]?.url)
	const contentType = useAreaStore((state) => state.areas[id]?.contentType)

	const updatePosition = useAreaStore((state) => state.updateArea.position)
	const updateSize = useAreaStore((state) => state.updateArea.size)
	const updateIconImage = useAreaStore((state) => state.updateArea.iconImage)
	const updateTitle = useAreaStore((state) => state.updateArea.title)
	const updateUrl = useAreaStore((state) => state.updateArea.url)
	const updateDescription = useAreaStore((state) => state.updateArea.description)
	const updateContentType = useAreaStore((state) => state.updateArea.contentType)
	const requestChange = useAreaStore((state) => state.requestArea.change)
	const reset = useAreaStore((state) => state.selectArea.update.reset)
	const didChange = useAreaStore((state) => state.selectArea.didChange)

	const handleFoucs = () => {
		actions.updateInputFocus(true)
	}

	const handleBlur = () => {
		actions.updateInputFocus(false)
	}

	return (
		<>
			<HorizontalGroup justifyContent="space-between" alignItems="center">
				<HorizontalGroup alignItems="center" spacing={5}>
					<Button
						image={Back}
						onClick={onBack}
						style={{
							width: didChange ? "0px" : "36px",
							transitionProperty: "width",
							transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
							transitionDuration: "150ms",
						}}
					/>
					<H3>{title ? (title.length > 0 ? title : "Area") : "Area"}</H3>
				</HorizontalGroup>

				<HorizontalGroup spacing={5} className={`transition-opacity ${didChange ? "opacity-100" : "opacity-0"}`}>
					<CancelButton
						title="Cancel"
						onClick={() => {
							reset()
							onBack()
						}}
					/>
					<ActionButton
						title="Save"
						onClick={() => {
							requestChange(id)
							onBack()
						}}
					/>
				</HorizontalGroup>
			</HorizontalGroup>

			<div
				style={{ flex: 1, flexGrow: 1, alignSelf: "stretch", overflowY: "scroll", WebkitOverflowScrolling: "touch" }}
			>
				{id && (
					<div className="flex h-full justify-between flex-col">
						<div className="flex space-y-4 flex-col">
							<VerticalGroup className="space-y-2">
								<HorizontalGroup className="space-x-2">
									<VerticalGroup>
										<label className="text-black text-opacity-50">Icon</label>
										<ImagePicker
											existing={iconImage ?? iconUrl}
											onChange={(file: File) => {
												updateIconImage(id, file, true)
											}}
										/>
									</VerticalGroup>

									<VerticalGroup className="flex-1 space-y-2">
										<label className="text-black text-opacity-50">Title</label>
										<Input
											type="text"
											value={title ?? ""}
											placeholder={"Title for the area"}
											onChange={(value) => updateTitle(id, value, true)}
											onFocus={handleFoucs}
											onBlur={handleBlur}
											style={{ margin: 0 }}
										/>

										<label className="text-black text-opacity-50">Description</label>
										<Input
											type="text"
											value={description ?? ""}
											placeholder={"Optional description"}
											onChange={(value) => updateDescription(id, value, true)}
											onFocus={handleFoucs}
											onBlur={handleBlur}
											style={{ margin: 0 }}
										/>

										<label className="text-black text-opacity-50"> Website </label>
										<Input
											type="text"
											value={url ?? ""}
											placeholder={"Website displayed while on area"}
											onChange={(value) => {
												updateUrl(id, value, true)
											}}
											onFocus={handleFoucs}
											onBlur={async () => {
												handleBlur()

												const parsedUrl = parseAndCreateBestFitUrl(url)
												const isPossible = await checkIfCanBeInsideIframe(parsedUrl)

												if (isPossible) {
													updateUrl(id, parsedUrl, true)
												} else {
													updateUrl(id, "", true)

													toast("This website can't be displayed inside an iframe.")
												}
											}}
											style={{ margin: 0 }}
										/>
									</VerticalGroup>
								</HorizontalGroup>

								<HorizontalGroup className="space-x-2" alignItems="center">
									<label className="text-black text-opacity-50" style={{ width: "54px" }}>
										Position
									</label>
									<label className="text-black text-opacity-50 w-3">X</label>
									<Input
										placeholder="x"
										type="number"
										value={position?.x}
										onChange={(value) => {
											updatePosition(id, { x: value, y: position.y }, true)
										}}
										onFocus={handleFoucs}
										onBlur={handleBlur}
										className="w-0 flex-1"
									/>
									<label className="text-black text-opacity-50 w-3">Y</label>
									<Input
										placeholder="y"
										type="number"
										value={position ? (`${position.y}` === "" ? position?.y : -position?.y) : ""}
										onChange={(value) => {
											updatePosition(id, { x: position.x, y: value === "" ? value : -value }, true)
										}}
										onFocus={handleFoucs}
										onBlur={handleBlur}
										className="min-w-0 flex-1"
									/>
								</HorizontalGroup>

								<HorizontalGroup className="space-x-2" alignItems="center">
									<label className="text-black text-opacity-50" style={{ width: "54px" }}>
										Size
									</label>
									<label className="text-black text-opacity-50 w-3">W</label>
									<Input
										placeholder="Width"
										type="number"
										value={size?.width}
										min={0}
										onChange={(value) => updateSize(id, { width: value, height: size.height }, true)}
										onFocus={handleFoucs}
										onBlur={handleBlur}
										className="w-0 flex-1"
									/>
									<label className="text-black text-opacity-50 w-3">H</label>
									<Input
										placeholder="Height"
										type="number"
										value={size?.height}
										min={0}
										onChange={(value) => updateSize(id, { width: size.width, height: value }, true)}
										onFocus={handleFoucs}
										onBlur={handleBlur}
										className="w-0 flex-1"
									/>
								</HorizontalGroup>
							</VerticalGroup>

							<VerticalGroup className="space-y-2">
								<H3>Content</H3>

								<select
									className="outline-none p-2 rounded-lg"
									value={contentType}
									onChange={({ target: { value } }) => {
										updateContentType(id, parseInt(value), true)
									}}
								>
									<option value={AreaContentType.None}>Empty</option>
									<option value={AreaContentType.Color}>Color</option>
									<option value={AreaContentType.Image}>Image</option>
									<option value={AreaContentType.Disco}>Disco</option>
									{/* <option value={AreaContentType.Speeddating}>Speed Dating</option> */}
								</select>

								<AreaContent contentType={contentType ?? AreaContentType.None} id={id} />
							</VerticalGroup>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default AreaEditor
