import { HorizontalGroup, Shadows, VerticalGroup } from "@saysom/shared"
import styled from "styled-components"

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	z-index: 10;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, 0.6);
`

export const Container = styled(VerticalGroup)`
	width: 800px;
	min-height: 400px;
	background-color: rgba(245, 245, 245);

	box-shadow: ${Shadows.highlighted};

	border-radius: 10px;
	align-items: stretch;
`

export const Header = styled.div`
	justify-content: space-between;
	align-items: center;
	padding: 10px;
`

export const Content = styled(HorizontalGroup)`
	flex: 1;
	align-items: flex-start;
`

export const Left = styled.div`
	flex: 1;
	background-color: white;
`

export const Right = styled(VerticalGroup)`
	flex: 3;
	align-items: stretch;

	padding: 0 20px;
`

export const ListItem = styled.p<{ selected: boolean }>`
	padding: 14px 10px;

	background-color: ${(props) => (props.selected ? "white" : "rgb(240,240,240)")};

	:hover {
		background-color: white;
	}
`
export const Select = styled.select`
	appearance: none;
	width: 100%;
	border-radius: 5px;

	padding: 14px 24px;
`

export const Option = styled.option`
	padding: 14px 24px;
`
