// @flow

import LinkifyIt, { Match } from "linkify-it"
import { cloneElement, isValidElement, PropsWithChildren, ReactElement } from "react"
import tlds from "tlds"

const linkify = new LinkifyIt()
linkify.tlds(tlds)

const defaultComponentDecorator = (decoratedHref: string, decoratedText: string, key: number) => {
	return (
		<a href={decoratedHref} key={key}>
			{decoratedText}
		</a>
	)
}

const defaultHrefDecorator = (href: string): string => {
	return href
}

const defaultMatchDecorator = (text: string): Match[] | null => {
	return linkify.match(text)
}

const defaultTextDecorator = (text: string): string => {
	return text
}

type Props = {
	componentDecorator?: (a: string, b: string, c: number) => ReactElement
	hrefDecorator?: (value: string) => string
	matchDecorator?: (value: string) => Match[] | null
	textDecorator?: (value: string) => string
}

export const Linkify = ({
	children,
	componentDecorator = defaultComponentDecorator,
	hrefDecorator = defaultHrefDecorator,
	matchDecorator = defaultMatchDecorator,
	textDecorator = defaultTextDecorator,
}: PropsWithChildren<Props>) => {
	const parseString = (value: string) => {
		if (value === "") {
			return value
		}

		const matches = matchDecorator(value)
		if (!matches) {
			return value
		}

		const elements: (string | ReactElement)[] = []
		let lastIndex = 0
		matches.forEach((match, i) => {
			// Push preceding text if there is any
			if (match.index > lastIndex) {
				elements.push(value.substring(lastIndex, match.index))
			}

			const decoratedHref = hrefDecorator(match.url)
			const decoratedText = textDecorator(match.text)
			const decoratedComponent = componentDecorator(decoratedHref, decoratedText, i)
			elements.push(decoratedComponent)

			lastIndex = match.lastIndex
		})

		// Push remaining text if there is any
		if (value.length > lastIndex) {
			elements.push(value.substring(lastIndex))
		}

		return elements.length === 1 ? elements[0] : elements
	}

	const parse = (children: any, key: number = 0) => {
		if (typeof children === "string") {
			return parseString(children)
		} else if (isValidElement(children) && children.type !== "a" && children.type !== "button") {
			// @ts-ignore
			return cloneElement(children, { key: key }, parse(children.props.children))
		} else if (Array.isArray(children)) {
			return children.map((child, i) => parse(child, i))
		}

		return children
	}

	return <>{parse(children)}</>
}

export default Linkify
