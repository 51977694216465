import { HorizontalGroup, VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import React from "react"
import { P } from "../../assets/style"
import { useSettingsStore } from "../../stores/settingsStore"
import GitCommit from "../../utils/_git_commit"
import { Option, Select } from "./settings_style"

const Settings = ({ style }: { style?: Properties }) => {
	const debug = useSettingsStore((state) => state.debug)
	const updateDebug = useSettingsStore((state) => state.updateDebug)
	const isNameTagEnabled = useSettingsStore((state) => state.isNameTagEnabled)
	const updateIsNameTagEnabled = useSettingsStore((state) => state.updateIsNameTagEnabled)

	const microphones = useSettingsStore((state) => state.microphones)
	const speakers = useSettingsStore((state) => state.speakers)
	const cameras = useSettingsStore((state) => state.cameras)

	const selectedMicrophone = useSettingsStore((state) => state.selectedMicrophone)
	const selectedSpeaker = useSettingsStore((state) => state.selectedSpeaker)
	const selectedCamera = useSettingsStore((state) => state.selectedCamera)

	const setMicrophone = useSettingsStore((state) => state.updateMicrophone)
	const setSpeaker = useSettingsStore((state) => state.updateSpeaker)
	const setCamera = useSettingsStore((state) => state.updateCamera)

	return (
		<VerticalGroup style={style}>
			<VerticalGroup alignItems="flex-start">
				<P style={{ marginBottom: "5px" }}>Microphone</P>
				<Select value={selectedMicrophone} onChange={({ target: { value } }) => setMicrophone(value)}>
					{microphones.map((v) => (
						<Option value={v.deviceId} key={v.deviceId}>
							{v.label}
						</Option>
					))}
				</Select>
			</VerticalGroup>

			<VerticalGroup style={{ marginTop: "10px" }} alignItems="flex-start">
				<P style={{ marginBottom: "5px" }}>Speaker</P>
				{speakers.length > 0 ? (
					<Select value={selectedSpeaker} onChange={({ target: { value } }) => setSpeaker(value)}>
						{speakers.map((v) => (
							<Option value={v.deviceId} key={v.deviceId}>
								{v.label}
							</Option>
						))}
					</Select>
				) : (
					<P>Change your speaker in your device settings</P>
				)}
			</VerticalGroup>

			<VerticalGroup style={{ marginTop: "10px" }} alignItems="flex-start">
				<P style={{ marginBottom: "5px" }}>Camera</P>
				<Select value={selectedCamera} onChange={({ target: { value } }) => setCamera(value)}>
					{cameras.map((v) => (
						<Option value={v.deviceId} key={v.deviceId}>
							{v.label}
						</Option>
					))}
				</Select>
			</VerticalGroup>

			<HorizontalGroup style={{ marginTop: "20px" }} alignItems="center" justifyContent="space-between">
				<P style={{ marginBottom: "10px" }}>Debug</P>
				<input type="checkbox" checked={debug} onChange={() => updateDebug(!debug)} />
			</HorizontalGroup>

			<HorizontalGroup style={{ marginTop: "5px" }} alignItems="center" justifyContent="space-between">
				<P style={{ marginBottom: "10px" }}>Show name tags</P>
				<input type="checkbox" checked={isNameTagEnabled} onChange={() => updateIsNameTagEnabled(!isNameTagEnabled)} />
			</HorizontalGroup>

			<P style={{ marginTop: "5px" }}>Version: {GitCommit.logMessage?.split(" ")[0]}</P>
		</VerticalGroup>
	)
}

export default Settings
