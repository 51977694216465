import { Button, HorizontalGroup, Spacer, VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import { Leva, LevaInputs, useControls } from "leva"
import { shallow } from "zustand/shallow"
import Add from "../../assets/images/add.svg"
import Back from "../../assets/images/back.svg"
import Pause from "../../assets/images/pause.svg"
import Play from "../../assets/images/play.svg"
import Save from "../../assets/images/save.svg"
import { useAvatarStore } from "../../stores/avatarStore"
import { useBotAnimationStore } from "../../stores/botAnimationStore"
import BotEdit from "./botEdit"

const BotEditor = ({ style }: { style?: Properties }) => {
	// Stores
	const actions = useAvatarStore((state) => state.botActions)
	const play = useBotAnimationStore((state) => state.play)
	const pause = useBotAnimationStore((state) => state.pause)
	const reset = useBotAnimationStore((state) => state.reset)
	const save = useBotAnimationStore((state) => state.save)
	const isPlaying = useBotAnimationStore((state) => state.isPlaying)
	const timeIntervall = useBotAnimationStore((state) => state.timeIntervall)
	const updateTimeIntervall = useBotAnimationStore((state) => state.updateTimeIntervall)

	const botIds = useAvatarStore((state) => Object.keys(state.users), shallow).filter(
		(user) => user.slice(0, 3) === "bot"
	)
	const colors = {
		elevation1: "none",
		elevation2: "none",
		elevation3: "rgba(0,0,0,0.05)",
		accent1: "rgba(0,0,0,0.1)",
		accent2: "orange",
		accent3: "black",
		highlight1: "black",
		highlight2: "black",
		highlight3: "black",
		vivid1: "orange",
		toolTipBackground: "green",
		toolTipText: "black",
	}

	const handleTimeIntervallChange = (value: [startTime: number, endTime: number]) => {
		updateTimeIntervall(value)
	}

	useControls(
		{
			duration: {
				value: timeIntervall,
				type: LevaInputs.INTERVAL,
				onChange: (value) => {
					handleTimeIntervallChange(value)
				},
				min: 0,
				max: 120,
			},
		},
		[timeIntervall]
	)

	return (
		<VerticalGroup spacing={10} style={{ ...style, overflow: "hidden" }} flex={1}>
			<div
				style={{ flex: 1, flexGrow: 1, alignSelf: "stretch", overflowY: "scroll", WebkitOverflowScrolling: "touch" }}
			>
				<VerticalGroup spacing={10}>
					{botIds.map((botId) => (
						<BotEdit key={botId} id={botId} />
					))}
				</VerticalGroup>
				<Leva flat fill titleBar={false} theme={{ colors }} />
			</div>

			<HorizontalGroup>
				<Button
					onClick={() => {
						isPlaying ? pause() : play()
					}}
					image={isPlaying ? Pause : Play}
				/>
				<Button onClick={() => save()} image={Save} />
				<Button onClick={() => reset()} image={Back} />
				<Spacer />
				<Button
					onClick={() => {
						actions.addBot()
					}}
					image={Add}
				/>
			</HorizontalGroup>
		</VerticalGroup>
	)
}

export default BotEditor
