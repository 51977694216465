import styled from "styled-components"

export const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 3;
	pointer-events: none;

	overflow: hidden;
`
