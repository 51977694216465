import { EffectComposer, SelectiveBloom } from "@react-three/postprocessing"
import { AreaContentType } from "@saysom/shared"
import { shallow } from "zustand/shallow"
import Area from "../../entities/area/area"
import { useAreaStore } from "../../stores/areaStore"
import { Owner, useAvatarStore } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"

const AreaManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Area Manager Reload")

	// Stores
	const areas = useAreaStore((state) => Object.keys(state.areas), shallow)
	const ownerAreaId = useAvatarStore((state) => (state.users["owner"] as Owner)?.areaId)
	const ownerArea = useAreaStore((state) => (ownerAreaId ? state.areas[ownerAreaId] : undefined))

	return (
		<>
			{areas.map((id) => {
				return <Area key={id} id={id} />
			})}
			{ownerArea?.contentType === AreaContentType.Disco && (
				<EffectComposer>
					<SelectiveBloom luminanceThreshold={0.0} luminanceSmoothing={0.3} height={2000} selectionLayer={2} />
				</EffectComposer>
			)}
		</>
	)
}

export default AreaManager
