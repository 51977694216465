import { extend } from "@react-three/fiber"
import * as THREE from "three"

export const dotFragmentShader = /* glsl */ ` 
	precision highp float;
	varying vec2 vUv;
	varying vec4 pos; 
	uniform vec2 meshScale; 


    void main() {
		float meshScaleMin = min(meshScale[0], meshScale[1]);

		float radius = 0.06;
		float radiusFalloff = 0.05;
		float frequency = meshScaleMin ;

		vec2 nearest =  2.0* fract(frequency * vUv ) - 1.0;
		float dist = length(nearest);
		float alpha = mix(0.0, 0.4, clamp(((radius - radiusFalloff) - (dist - radiusFalloff)) / (radius - radiusFalloff), 0.0, 1.0));

		vec4 none = vec4(0.0, 0.0, 0.0, 0.0);
		vec4 black = vec4(0.0, 0.0, 0.0, alpha);

		vec4 color = mix(black, none, step(radius, dist));
		gl_FragColor = color;
    }`

export const dotVertexShader = /* glsl */ `
	varying vec2 vUv;
	varying vec4 pos; 
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
		pos = gl_Position; 
    }`

class DotMaterial extends THREE.ShaderMaterial {
	constructor() {
		super({
			uniforms: {
				meshScale: { value: new THREE.Vector2(1, 1) },
			},
			vertexShader: dotVertexShader,
			fragmentShader: dotFragmentShader,
			transparent: true,
		})
	}

	set meshScale(v: THREE.Vector2) {
		this.uniforms.meshScale.value = v
	}
}

// register element in r3f (<fade />)
extend({ DotMaterial })
