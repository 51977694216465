import { Html } from "@react-three/drei"
import { Colors, ModeratorType } from "@saysom/shared"
import { memo, Suspense, useCallback, useEffect, useRef, useState } from "react"
import { MathUtils, Mesh, Vector3 } from "three"
import Connecting from "../../assets/images/connecting.svg"
import BotTile from "../../components/botTile/botTile"
import { useScaleFactor } from "../../hooks/useScaleFactor"
import BotActionManager from "../../manager/botManager/botActionManager"
import BotMovementManager from "../../manager/movementManager/botMovementManager"
import UserMovementManager from "../../manager/movementManager/userMovementManager"
import { Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSpaceStore } from "../../stores/spaceStore"
import { useSpeakerStore } from "../../stores/speakerStore"
import AvatarOverlay from "./avatarOverlay"
import AvatarTag from "./avatarTag"
import AvatarTile, { PresenceState, VideoQuality } from "./avatarTile"
import { Circle, LoadingIndictatorImage, Video } from "./avatar_style"

interface AvatarProps {
	id: string
}

const Avatar = memo<AvatarProps>(({ id }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Avatar Reload")

	// Properties
	const isOwner = id === "owner"
	const scaleFactor = useScaleFactor()
	const name = useAvatarStore((state) => state.users[id].name)
	const userId = useAvatarStore((state) => state.users[id].id)
	const isBot = useAvatarStore((state) => state.users[id].isBot)
	const position = useAvatarStore((state) => state.users[id].position)
	const moderatorType = useAvatarStore((state) => (state.users[id] as Owner | User)?.moderatorType)
	const presenceState = useAvatarStore((state) => (state.users[id] as Owner | User)?.media?.presenceState)
	const isAudioMuted = useAvatarStore((state) => (state.users[id] as Owner | User)?.media?.isAudioMuted)
	const isVideoMuted = useAvatarStore((state) => (state.users[id] as Owner | User)?.media?.isVideoMuted)
	const avatarImageUrl = useAvatarStore((state) => state.users[id].avatarImageUrl)
	const isSpeaker = useSpeakerStore((state) => state.speaker[id] !== undefined)
	const moderatorColor = useSpaceStore((state) => state.space?.colorScheme?.moderator ?? "white")
	const videoQuality = useAvatarStore((state) => (state.users[id] as Owner | User)?.media?.videoQuality)
	const updateMesh = useAvatarStore((state) => state.actions.updateMesh)
	const targetId = useSceneStore((state) => state.target.id)
	const isInOwnerGroup = useAvatarStore((state) => (state.users[id] as User)?.isInOwnerGroup)
	const isNameTagEnabled = useSettingsStore((state) => state.isNameTagEnabled)

	//if (!isOwner) console.log("Id: " + id + ", is Bot: " + isBot)

	// Actions
	const updatePresenceState = useAvatarStore((state) => state.actions.updatePresenceState)
	const updateIsAudioMuted = useAvatarStore((state) => state.actions.updateIsAudioMuted)
	const updateIsVideoMuted = useAvatarStore((state) => state.actions.updateIsVideoMuted)

	const rotation: [x: number, y: number, z: number] = [
		MathUtils.degToRad(-90),
		MathUtils.degToRad(0),
		MathUtils.degToRad(0),
	]

	// States
	const [hover, setHover] = useState(false)

	const setRef = useCallback(
		(newRef: Mesh | null) => {
			if (newRef === null) {
				updateMesh(id, undefined)
			} else {
				updateMesh(id, newRef)
			}
		},
		[updateMesh, id]
	)

	// Tile Update
	const ownerMesh = useRef<Mesh | undefined>(undefined)
	useEffect(() => useAvatarStore.subscribe((state) => (ownerMesh.current = state.users["owner"]?.mesh)))

	const v1 = new Vector3()
	const overrideMainCalculatePosition = (el, camera, size) => {
		const objectPos = v1.setFromMatrixPosition(el.matrixWorld)
		objectPos.project(camera)
		const widthHalf = size.width / 2
		const heightHalf = size.height / 2
		return [
			Math.min(size.width - 80, Math.max(80, objectPos.x * widthHalf + widthHalf)),
			Math.min(size.height - 80, Math.max(80, -(objectPos.y * heightHalf) + heightHalf)),
		]
	}

	const v2 = new Vector3()
	const overrideTagCalculatePosition = (el, camera, size) => {
		const objectPos = v2.setFromMatrixPosition(el.matrixWorld)
		objectPos.project(camera)
		const widthHalf = size.width / 2
		const heightHalf = size.height / 2
		return [
			Math.min(size.width - 80, Math.max(80, objectPos.x * widthHalf + widthHalf)),
			Math.min(size.height - 160, Math.max(160, -(objectPos.y * heightHalf) + heightHalf)),
		]
	}

	return (
		<Suspense fallback={null}>
			{position && (
				<mesh ref={setRef} position={[position[0], 0, position[1]]} rotation={rotation}>
					<Html
						center
						distanceFactor={0.01}
						style={{ willChange: "transform", pointerEvents: "none" }}
						zIndexRange={isOwner ? [1, 1] : [0, 0]}
						calculatePosition={isOwner ? overrideMainCalculatePosition : undefined}
					>
						<Circle
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
							color={
								targetId === id
									? Colors.action
									: moderatorType !== ModeratorType.None
									? moderatorColor
										? moderatorColor
										: "white"
									: "white"
							}
						>
							<Video onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
								{isBot ? (
									<BotTile id={id} />
								) : (
									<AvatarTile
										id={userId}
										isOwner={isOwner}
										isHearable={isInOwnerGroup}
										isPresent={!isSpeaker}
										isMegaphoneSpeaker={isSpeaker}
										isScreenShareSpeaker={false}
										videoQuality={videoQuality}
										onPresenceStateChange={(newState) => {
											if (presenceState !== newState) {
												updatePresenceState(id, newState)
											}
										}}
										onAudioMute={(isMuted) => {
											if (isMuted !== isAudioMuted) {
												updateIsAudioMuted(id, isMuted)
											}
										}}
										onVideoMute={(isMuted) => {
											if (isMuted !== isVideoMuted) {
												updateIsVideoMuted(id, isMuted)
											}
										}}
									/>
								)}
							</Video>

							{(isVideoMuted || presenceState !== PresenceState.Connecting || videoQuality === VideoQuality.Off) && (
								<AvatarOverlay id={id} avatarImageUrl={avatarImageUrl} />
							)}
							{presenceState === PresenceState.Connecting && <LoadingIndictatorImage src={Connecting} />}
						</Circle>
					</Html>
					<Html
						center
						distanceFactor={scaleFactor}
						zIndexRange={[2, 2]}
						position={[0, 0.65, 1]}
						style={{ willChange: "transform", pointerEvents: "none" }}
						calculatePosition={isOwner ? overrideTagCalculatePosition : undefined}
					>
						{isNameTagEnabled && (
							<AvatarTag
								id={id}
								name={name}
								hover={hover}
								isAudioMuted={isAudioMuted ? isAudioMuted : false}
								isVideoMuted={isVideoMuted ? isVideoMuted : false}
								isModerator={moderatorType !== ModeratorType.None}
								presenceState={presenceState}
							/>
						)}
					</Html>
				</mesh>
			)}

			{!isOwner &&
				(isBot ? (
					<>
						<BotMovementManager id={id} />
						<BotActionManager id={id} />{" "}
					</>
				) : (
					<UserMovementManager id={id} />
				))}
		</Suspense>
	)
})

export default Avatar
