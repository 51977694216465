import {
	Area as AreaData,
	AreaContentType,
	AreaUpdateChange,
	AreaUpdateDelete,
	AreaUpdateInit,
	AreaUpdateType,
	slugString,
} from "@saysom/shared"
import { RefObject } from "react"
import { Mesh } from "three"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { fetchApiAuthorized } from "../network/fetch"
import { legacyAreas } from "../scenes/legacy/legacyAreas"
import { getAwsUrlForKey } from "../utils/aws"
import { useSocketStore } from "./socketStore"
import { useSpaceStore } from "./spaceStore"

export interface Area extends AreaData {
	mesh?: RefObject<Mesh>
	image?: File
	iconImage?: File
}

type UpdateArea = {
	add: (areaId: string, area: Area) => void
	url: (areaId: string, url: string, local: boolean) => void
	title: (areaId: string, title: string, local: boolean) => void
	image: (areaId: string, image: File, local: boolean) => void
	imageUrl: (areaId: string, imageUrl: string, local: boolean) => void
	iconUrl: (areaId: string, iconUrl: string, local: boolean) => void
	iconImage: (areaId: string, iconImage: File, local: boolean) => void
	backgroundColor: (areaId: string, backgroundColor: string, local: boolean) => void
	position: (areaId: string, position: { x: number; y: number }, local: boolean) => void
	size: (areaId: string, size: { width: number; height: number }, local: boolean) => void
	description: (areaId: string, description: string, local: boolean) => void
	contentType: (areaId: string, contentType: AreaContentType, local: boolean) => void
	mesh: (areaId: string, mesh: RefObject<Mesh> | undefined) => void
	delete: (areaId: string) => void
}

type RequestArea = {
	init: (area: {
		title?: string
		url?: string
		imageUrl?: string
		iconUrl?: string
		description?: string
		position: { x: number; y: number }
		size: { width: number; height: number }
		backgroundColor: string
	}) => void
	change: (areaId: string) => void
	delete: (areaId: string) => void
}

type SelectArea = {
	id?: string
	copy?: Area
	didChange: boolean
	update: {
		set: (areaId: string) => void
		reset: () => void
	}
}

type AreaStore = {
	areas: Record<string, Area>

	selectArea: SelectArea
	updateArea: UpdateArea
	requestArea: RequestArea

	loadLeagacyAreas: (spaceName: string) => void
}

export const useAreaStore = create(
	immer<AreaStore>((set, get) => ({
		areas: {},

		selectArea: {
			id: undefined,
			copy: undefined,
			didChange: false,

			update: {
				set(areaId) {
					set((state) => {
						state.selectArea.id = areaId
						state.selectArea.didChange = false
						state.selectArea.copy = { ...state.areas[areaId] }
					})
				},
				reset() {
					set((state) => {
						if (state.selectArea.didChange && state.selectArea.id && state.selectArea.copy) {
							state.areas[state.selectArea.id] = state.selectArea.copy
						}

						state.selectArea.id = undefined
						state.selectArea.copy = undefined
						state.selectArea.didChange = false
					})
				},
			},
		},
		requestArea: {
			init(area) {
				const areaUpdateInit: AreaUpdateInit = {
					type: AreaUpdateType.Init,
					title: area.title,
					url: area.url,
					imageUrl: area.imageUrl,
					iconUrl: area.iconUrl,
					description: area.description,
					position: area.position,
					size: area.size,
					backgroundColor: area.backgroundColor,
				}

				useSocketStore.getState().emit.area(areaUpdateInit)
			},

			async change(areaId) {
				const { areas, selectArea } = get()

				let area = { ...areas[areaId] }
				if (area) {
					if (area.image || area.iconImage) {
						const space = useSpaceStore.getState().space

						if (space) {
							const formData = new FormData()
							if (area.image) {
								formData.append("image", area.image)

								area.imageUrl = getAwsUrlForKey(`spaces/${space.code}/${area.key}/image${slugString(area.image.name)}`)
							}

							if (area.iconImage) {
								formData.append("icon", area.iconImage)

								area.iconUrl = getAwsUrlForKey(
									`spaces/${space.code}/${area.key}/icon${slugString(area.iconImage.name)}`
								)
							}

							await fetchApiAuthorized(`/spaces/${space.code}/${area.key}`, formData, "PUT")
						}
					}

					const _area = { ...area }
					delete _area.image
					delete _area.iconImage

					const areas = {}
					areas[areaId] = _area

					const areaUpdateChange: AreaUpdateChange = {
						type: AreaUpdateType.Change,
						areas: areas,
					}

					selectArea.update.set(areaId)
					useSocketStore.getState().emit.area(areaUpdateChange)
				}
			},

			delete(areaId) {
				const areaUpdateDelete: AreaUpdateDelete = {
					type: AreaUpdateType.Delete,
					key: areaId,
				}

				// TODO: test
				const space = useSpaceStore.getState().space
				if (space) {
					fetchApiAuthorized(`/spaces/${space.code}/${areaId}`, undefined, "DELETE")
				}

				useSocketStore.getState().emit.area(areaUpdateDelete)
			},
		},

		updateArea: {
			add(areaId, area) {
				const { areas, updateArea } = get()
				const existingArea = areas[areaId]
				if (existingArea) {
					if (area.title && area.title !== existingArea.title) {
						updateArea.title(areaId, area.title, false)
					}
					if (area.description && area.description !== existingArea.description) {
						updateArea.description(areaId, area.description, false)
					}
					if (area.imageUrl && area.imageUrl !== existingArea.imageUrl) {
						updateArea.imageUrl(areaId, area.imageUrl, false)
					}
					if (area.iconUrl && area.iconUrl !== existingArea.iconUrl) {
						updateArea.iconUrl(areaId, area.iconUrl, false)
					}
					if (area.contentType && area.contentType !== existingArea.contentType) {
						updateArea.contentType(areaId, area.contentType, false)
					}
					if (area.position.x !== existingArea.position.x || area.position.y !== existingArea.position.y) {
						updateArea.position(areaId, area.position, false)
					}
					if (area.size.width !== existingArea.size.width || area.size.height !== existingArea.size.height) {
						updateArea.size(areaId, area.size, false)
					}
				} else {
					set((state) => {
						state.areas[areaId] = area
					})
				}
			},
			title(areaId, title, local) {
				set((state) => {
					state.areas[areaId].title = title
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.title = title
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			url(areaId, url, local) {
				set((state) => {
					state.areas[areaId].url = url
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.url = url
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			imageUrl(areaId, imageUrl, local) {
				set((state) => {
					state.areas[areaId].imageUrl = imageUrl
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.imageUrl = imageUrl
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			image(areaId, image, local) {
				set((state) => {
					state.areas[areaId].image = image
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.image = image
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			backgroundColor(areaId, backgroundColor, local) {
				set((state) => {
					state.areas[areaId].backgroundColor = backgroundColor
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.backgroundColor = backgroundColor
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			contentType(areaId, contentType, local) {
				set((state) => {
					state.areas[areaId].contentType = contentType
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.contentType = contentType
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			iconUrl(areaId, iconUrl, local) {
				set((state) => {
					state.areas[areaId].iconUrl = iconUrl
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.iconUrl = iconUrl
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			iconImage(areaId, iconImage, local) {
				set((state) => {
					state.areas[areaId].iconImage = iconImage
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.iconImage = iconImage
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			position(areaId, position, local) {
				set((state) => {
					state.areas[areaId].position = position
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.position = position
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			size(areaId, size, local) {
				set((state) => {
					state.areas[areaId].size = size
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.size = size
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			description(areaId, description, local) {
				set((state) => {
					state.areas[areaId].description = description
					if (!local && areaId === state.selectArea.id && state.selectArea.copy) {
						state.selectArea.copy.description = description
					} else {
						state.selectArea.didChange = true
					}
				})
			},
			mesh(areaId, mesh) {
				set((state) => {
					state.areas[areaId].mesh = mesh
				})
			},
			delete(areaId) {
				const { selectArea } = get()
				if (areaId === selectArea.id) {
					selectArea.update.reset()
				}

				set((state) => {
					delete state.areas[areaId]
				})
			},
		},

		loadLeagacyAreas(spaceName) {
			const areas = legacyAreas[spaceName]
			set((state) => {
				if (areas) state.areas = areas
			})
		},
	}))
)
