import { create } from "zustand"
import { persist } from "zustand/middleware"

export interface AuthData extends Record<string, unknown> {
	token: string
	expiresIn: number
}

interface AuthStore extends Record<string, unknown> {
	token?: string
	expiresIn?: number

	update: (data: AuthData) => void
	remove: () => void
}

export const useAuthStore = create(
	persist<AuthStore>(
		(set, _get) => {
			return {
				token: undefined,
				expiresIn: undefined,

				update(data: AuthData) {
					set({
						token: data.token,
						expiresIn: data.expiresIn,
					})
				},

				remove() {
					set({
						token: undefined,
						expiresIn: undefined,
					})
				},
			}
		},
		{
			name: "auth",
		}
	)
)
