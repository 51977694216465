import { buttonGroup, folder, LevaInputs, useControls } from "leva"
import React, { memo } from "react"
import { Bot, useAvatarStore } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useInputStore } from "../../stores/inputStore"
import { useSettingsStore } from "../../stores/settingsStore"
import BotEditPosition from "./botEditPosition"

interface BotEditProps {
	id: string
}

const BotEdit = memo<BotEditProps>(({ id }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Bot Reload")

	// Store
	const actions = useInputStore((state) => state.actions)
	const updateName = useAvatarStore((state) => state.botActions.updateName)
	const updateVideoUrl = useAvatarStore((state) => state.botActions.updateVideoUrl)
	const updateAvatarUrl = useAvatarStore((state) => state.botActions.updateAvatarUrl)
	const addRow = useAvatarStore((state) => state.botActions.addRow)
	const removeLastRow = useAvatarStore((state) => state.botActions.removeLastRow)
	const removeBot = useAvatarStore((state) => state.botActions.removeBot)
	const name = useAvatarStore((state) => state.users[id]?.name)
	const avatarImageUrl = useAvatarStore((state) => state.users[id]?.avatarImageUrl)
	const videoUrl = useAvatarStore((state) => (state.users[id] as Bot)?.videoUrl)
	const positions = useAvatarStore((state) => (state.users[id] as Bot)?.positions)

	const handleNameChange = (value: string) => {
		updateName(id, value)
	}
	const handeVideoURLChange = (value: string) => {
		updateVideoUrl(id, value)
	}
	const handelAvatarImageURLChange = (value: string) => {
		updateAvatarUrl(id, value)
	}

	const handleFoucs = () => {
		actions.updateInputFocus(true)
	}

	const handleBlur = () => {
		actions.updateInputFocus(false)
	}

	useControls({
		[`${id}`]: folder({
			" ": buttonGroup({
				//Duplicate: () => duplicateBot(id),
				Delete: () => removeBot(id),
				[`Add Row`]: () => addRow(id),
				[`Remove Row`]: () => removeLastRow(id),
			}),
			[`name`]: {
				value: name,
				type: LevaInputs.STRING,
				onChange: (value) => {
					handleNameChange(value)
				},
				onEditStart: () => {
					handleFoucs()
				},
				onEditEnd: () => {
					handleBlur()
				},
			},
			[`image`]: {
				value: avatarImageUrl ? avatarImageUrl : "",
				type: LevaInputs.STRING,
				onChange: (value) => {
					handelAvatarImageURLChange(value)
				},
				onEditStart: () => {
					handleFoucs()
				},
				onEditEnd: () => {
					handleBlur()
				},
			},
			[`video`]: {
				value: videoUrl ? videoUrl : "",
				type: LevaInputs.STRING,
				onChange: (value) => {
					handeVideoURLChange(value)
				},
				onEditStart: () => {
					handleFoucs()
				},
				onEditEnd: () => {
					handleBlur()
				},
			},
		}),
	})

	return (
		<div>
			{positions.map((_positions, idx) => (
				<BotEditPosition key={idx} id={id} idx={idx} isLast={idx === positions.length} />
			))}
		</div>
	)
})

export default BotEdit
