import { useAuthStore } from "../stores/authStore"
import { showError } from "../utils/errorHandler"
import formatUrl, { formatUrlWithBaseUrl } from "../utils/formatUrl"

// General

const formatConfig = (body?: BodyInit | Record<string, unknown>, method?: string, token?: string) => {
	const config: RequestInit = {
		method,
	}
	const headers = {}

	if (token) {
		headers["Authorization"] = `Bearer ${token}`
	}

	if (body) {
		if (body instanceof FormData) {
			config.body = body as BodyInit
		} else {
			config.body = JSON.stringify(body)
		}
	}

	if ((config.body && typeof config.body === "string") || !config.body) {
		headers["Content-Type"] = "application/json"
	}

	config.headers = headers

	return config
}

// Normal fetching

export const fetchNormal = async (
	url: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET",
	token?: string
) => {
	try {
		const result = await fetch(url, formatConfig(body, method, token))
		const json = await result.json()
		if (json.message) {
			throw new Error(json.message)
		} else {
			return json
		}
	} catch (error: any) {
		showError(error)
	}
}

export const fetchApi = async (url: string, body?: BodyInit | Record<string, unknown>, method = "GET") => {
	return fetchNormal(formatUrl(url), body, method)
}

export const fetchApiAuthorized = async (url: string, body?: BodyInit | Record<string, unknown>, method = "GET") => {
	const token = useAuthStore.getState().token

	if (!token) {
		throw new Error("No token present")
	}

	return fetchNormal(formatUrl(url), body, method, token)
}

export const fetchApiForUrl = async (
	baseUrl: string,
	url: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	return fetchNormal(formatUrlWithBaseUrl(baseUrl, url), body, method)
}
