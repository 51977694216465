// @ts-ignore
import { Perf } from "r3f-perf"
import React from "react"
import { useSettingsStore } from "../../stores/settingsStore"
import Tweaks from "./tweaks"

const Debug = () => {
	const debug = useSettingsStore((state) => state.debug)

	return (
		<>
			{debug && (
				<>
					<Tweaks />
					<Perf className="stats" />
				</>
			)}
		</>
	)
}

export default Debug
