import styled from "styled-components"

export const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	/* background-color: rgba(255, 255, 255, 0.5); */
	/* backdrop-filter: blur(80px) saturate(1); */
`
