import { create } from "zustand"
import { persist } from "zustand/middleware"

type LocalStore = {
	name: string | undefined
	isModerator: boolean | undefined
	avatarImageUrl: string | undefined
	visitedSpaces: string[]

	update: (name: string | undefined, isModerator: boolean | undefined, avatarImageUrl: string | undefined) => void
}

export const useLocalStore = create(
	persist<LocalStore>(
		(set, _get) => ({
			name: undefined,
			isModerator: undefined,
			avatarImageUrl: undefined,

			visitedSpaces: [],

			update: (name, isModerator, avatarImageUrl) => {
				set({ name, isModerator, avatarImageUrl })
			},
		}),
		{ name: "user" }
	)
)
