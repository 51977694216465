import { Colors, Shadows } from "@saysom/shared"
import { Property } from "csstype"
import styled from "styled-components"

// colors
export const Gradient = "linear-gradient(103.87deg, #FA8BFF 0%, #6867FF 100%)"
export const Flowy = "linear-gradient(58.22deg, #521AFF 0%, #7E1AFF 16.31%, #DF738E 79.5%, #F9BAA9 97.85%)"
export const FlowyWhite =
	"linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 47.4%, rgba(255, 255, 255, 0) 100%), linear-gradient(58.22deg, #521AFF 0%, #7E1AFF 16.31%, #DF738E 79.5%, #F9BAA9 97.85%)"

// shadow
export const BorderRadius = "8px"

interface ColorProps {
	color?: Property.Color
}

export const H1 = styled.h1<ColorProps>`
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	line-height: 46px;

	color: ${(props) => (props.color ? props.color : Colors.black)};

	@media (max-width: 500px) {
		font-size: 24px;
		margin-bottom: 20px;
	}
`

export const H2 = styled.h2<ColorProps>`
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;

	color: ${(props) => (props.color ? props.color : Colors.black)};

	@media (max-width: 500px) {
		font-size: 18px;
	}
`

export const H3 = styled.h3<ColorProps>`
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;

	color: ${(props) => (props.color ? props.color : Colors.black)};

	@media (max-width: 500px) {
		font-size: 16px;
	}
`

export const H4 = styled.h4<ColorProps>`
	font-style: normal;
	font-weight: 600;

	font-size: 16px;
	line-height: 20px;

	color: ${(props) => (props.color ? props.color : Colors.black)};
`

interface FontSizeProps extends ColorProps {
	fontSize?: Property.FontSize
	fontWeight?: Property.FontWeight
}

export const P = styled.p<FontSizeProps>`
	font-style: normal;
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
	color: ${(props) => (props.color ? props.color : Colors.black)};
	margin: 0px;
	line-height: 20px;
`

interface ButtonProps {
	defaultBackground?: boolean
	background?: Property.Background
}

export const Button = styled.button<ButtonProps>`
	background: #ffffff;
	box-shadow: ${Shadows.highlighted};
	border-radius: 200px;
	border: 0;
	outline: 0;
	cursor: pointer;

	font-style: normal;
	font-weight: normal;
	font-size: 16px;

	color: #333333;
	transition: all 200ms ease-in-out;

	padding: 10px 22px;

	:hover {
		color: white;
		transform: scale(1.05);
		background: ${(props) => !props.defaultBackground && (props.background ? props.background : "#ffffff")};
		color: ${(props) => !props.defaultBackground && (props.background ? "#ffffff" : Colors.black)};
	}

	:active {
		transform: scale(0.9);
	}

	:disabled {
		pointer-events: none;
		color: rgb(180, 180, 180);
	}

	@media (max-width: 500px) {
		font-size: 14px;
	}
`

export const OutlineButton = styled.button``

export const BlackButton = styled.button`
	border-radius: 30px;
	box-shadow: ${Shadows.normal};
	border: none;
	padding: 10px 35px 9px 35px;
	color: white;
	font-weight: 700;
	outline: none;
	cursor: pointer;
	font-size: 18px;
	background-color: ${Colors.black};
	transition: transform 200ms ease-in-out;

	:disabled {
		pointer-events: none;
		background: lightgrey;
	}

	:active {
		transform: scale(0.9);
	}
`

export const WhiteButton = styled(BlackButton)`
	background-color: white;
	color: ${Colors.black};
`

export const GradientButton = styled(BlackButton)`
	background: ${Gradient};
	color: white;
`

interface ButtonIconProps {
	image?: Property.MaskImage
}

export const ButtonIcon = styled.div<ButtonIconProps>`
	background-color: ${(props) => (props.color ? props.color : Colors.black)};
	width: 20px;
	height: 20px;
	mask: url(${(props) => props.image}) no-repeat center;
`

// Forms

export const Form = styled.form`
	display: flex;
	flex-direction: column;
`

export const Label = styled.label`
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	font-size: 14px;
`

export const Input = styled.input`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: 14px;

	flex: 1;
	margin-top: 5px;
	padding: 15px 10px;
	border-radius: ${BorderRadius};
	border: 1px solid ${Colors.black};
	outline: none;

	border: 1px solid #efefef;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

	:focus {
		border: 1px solid #c4c4c4;
	}

	::placeholder {
		color: #808c99;
	}
`

export const TextAreaInput = styled.textarea`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	flex: 1;
	margin-top: 5px;
	padding: 15px 10px;
	border-radius: ${BorderRadius};
	border: 1px solid ${Colors.black};
	outline: none;

	border: 1px solid #efefef;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

	:focus {
		border: 1px solid #c4c4c4;
	}

	::placeholder {
		color: #808c99;
	}
`

// Box

interface CenterProps {
	width?: Property.Width
}

export const CenterContainer = styled.div<CenterProps>`
	align-self: center;
	justify-self: center;
	width: ${(props) => (props.width ? props.width : "initial")};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: ${(props) => props.width}) {
		width: 100%;
		padding: 0 20px;
		/* min-width: 100%;
		max-width: 100%;
		width: 100%; */
	}
`
