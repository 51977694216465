import { VerticalGroup } from "@saysom/shared"
import { P } from "../../../assets/style"
import AvatarTile, { VideoQuality } from "../../../entities/avatar/avatarTile"
import { useAvatarStore } from "../../../stores/avatarStore"

interface MutlipleSpeakerProps {
	speakerId: string
}

const Multiple = ({ speakerId }: MutlipleSpeakerProps) => {
	const ownerId = useAvatarStore((state) => state.users["owner"]?.id)
	const _speakerId = speakerId === ownerId ? "owner" : speakerId

	const name = useAvatarStore((state) => state.users[_speakerId]?.name)
	const id = useAvatarStore((state) => state.users[_speakerId]?.id)

	return (
		<VerticalGroup style={{ cursor: "default", overflow: "hidden" }}>
			<div style={{ paddingTop: "100%", position: "relative", overflow: "hidden", borderRadius: "10000px" }}>
				<div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
					<AvatarTile
						id={id}
						isHearable={true}
						videoQuality={VideoQuality.High}
						isOwner={_speakerId === "owner"}
						isPresent={true}
						isMegaphoneSpeaker={true}
						isScreenShareSpeaker={false}
					/>
				</div>
			</div>
			<P style={{ fontWeight: 600, fontSize: "14px", marginBottom: "10px", marginTop: "5px", alignSelf: "center" }}>
				{name}
			</P>
		</VerticalGroup>
	)
}

export default Multiple
