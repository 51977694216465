import React, { forwardRef, useState } from "react"
import { ChromePicker, CirclePicker } from "react-color"

interface ColorPickerProps {
	color: string
	onClose: () => void
	onPickColor: (color: string) => void
}

const ColorPicker = forwardRef<HTMLDivElement, ColorPickerProps>(({ color, onClose, onPickColor }, ref) => {
	const [isAdvanced, setIsAdvancend] = useState(false)

	return (
		<div className="absolute -right-0.5 -top-0.5 z-10 flex flex-col items-end">
			<div className="bg-white p-1 w-6 h-7 rounded-t-full" onClick={onClose}>
				<div style={{ background: color }} className="rounded-full w-full aspect-w-1 aspect-h-1" />
			</div>

			<div className="bg-white rounded-b-lg rounded-tl-lg p-2 shadow-lg flex flex-col space-y-2" ref={ref}>
				{isAdvanced ? (
					<ChromePicker
						color={"red"}
						onChange={(_color) => {
							onPickColor(_color.hex)
						}}
						disableAlpha={true}
					/>
				) : (
					<CirclePicker
						color={"red"}
						onChangeComplete={(_color) => {
							onPickColor(_color.hex)
							onClose()
						}}
					/>
				)}

				<div
					className="cursor-pointer rounded-md text-center bg-black bg-opacity-0 hover:bg-opacity-10 transition-all p-0.5"
					onClick={() => setIsAdvancend((v) => !v)}
				>
					{isAdvanced ? "Less colors" : "More colors"}
				</div>
			</div>
		</div>
	)
})

export default ColorPicker
