import { Button, SpeakerType } from "@saysom/shared"
import { Properties } from "csstype"
import React, { useEffect, useState } from "react"
import MegaphoneOff from "../../assets/images/megaphoneOff.svg"
import MegaphoneOn from "../../assets/images/megaphoneOn.svg"
import { useAvatarStore } from "../../stores/avatarStore"

import { useSpeakerStore } from "../../stores/speakerStore"

const MegaphoneControls = ({ style }: { style?: Properties }) => {
	const [requestingMegaphone, setRequestingMegaphone] = useState(false)

	const requestChange = useSpeakerStore((state) => state.request.change)
	const requestDelete = useSpeakerStore((state) => state.request.delete)
	const ownerId = useAvatarStore((state) => state.users["owner"]?.id)
	const ownerIsMegaphoneSpeaker = useSpeakerStore((state) => state.speaker["owner"] !== undefined)

	const handleMegaphone = () => {
		if (!requestingMegaphone) {
			if (ownerId) {
				if (ownerIsMegaphoneSpeaker) {
					requestDelete(ownerId)
				} else {
					requestChange(ownerId, SpeakerType.Megaphone)
				}
			}

			setRequestingMegaphone(true)
		}
	}

	useEffect(() => {
		setRequestingMegaphone(false)
	}, [ownerIsMegaphoneSpeaker])

	return (
		<Button
			data-tip={ownerIsMegaphoneSpeaker ? "Disable Megaphone" : "Enable Megaphone"}
			onClick={() => handleMegaphone()}
			disabled={requestingMegaphone}
			image={ownerIsMegaphoneSpeaker ? MegaphoneOff : MegaphoneOn}
			style={style}
		/>
	)
}

export default MegaphoneControls
