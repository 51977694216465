import React, { memo } from "react"
import Disconnected from "../../assets/images/disconnected.svg"
import Megaphone from "../../assets/images/megaphoneOn.svg"
import MicrophoneOffSvg from "../../assets/images/microphoneOff.svg"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { PresenceState } from "./avatarTile"
import { StatusIndicator, StatusIndicatorImage } from "./avatar_style"

interface AvatarStatusProps {
	id: string
	isAudioMuted: boolean
	presenceState: PresenceState
}

const AvatarStatus = memo<AvatarStatusProps>(({ isAudioMuted, presenceState }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Avatar Status Reload")

	return (
		<StatusIndicator>
			<StatusIndicatorImage
				src={
					presenceState === PresenceState.Blocked
						? Disconnected
						: presenceState === PresenceState.Megaphone
						? Megaphone
						: isAudioMuted
						? MicrophoneOffSvg
						: undefined
				}
			/>
		</StatusIndicator>
	)
})

export default AvatarStatus
