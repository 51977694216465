import React from "react"

const Button = ({ onClick, className, title }: { onClick: () => void; title: string; className?: string }) => {
	return (
		<button
			className={`w-full flex justify-center bg-black text-white shadow-md rounded-md px-3 py-2 text-sm font-semibold select-none cursor-pointer hover:bg-action transition-colors ${className}`}
			onClick={onClick}
		>
			{title}
		</button>
	)
}

export const CancelButton = ({
	onClick,
	className,
	title,
}: {
	onClick: () => void
	title: string
	className?: string
}) => {
	return (
		<button
			className={`w-full flex justify-center bg-opacity-50 bg-white text-black shadow-md rounded-md px-3 py-2 text-sm font-semibold select-none cursor-pointer hover:bg-action hover:text-white transition-colors ${className}`}
			onClick={onClick}
		>
			{title}
		</button>
	)
}

export default Button
