import { HorizontalContainer } from "@saysom/shared"
import React, { memo, useState } from "react"
import { Gradient, P } from "../../assets/style"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import AvatarStatus from "./avatarStatus"
import { PresenceState } from "./avatarTile"

interface AvatarTagProps {
	id: string
	name: string
	hover: boolean
	isAudioMuted: boolean
	isVideoMuted: boolean
	isModerator: boolean
	presenceState: PresenceState
}

const AvatarTag = memo<AvatarTagProps>(({ id, name, isAudioMuted, presenceState, isModerator = false }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Avatar Tag Reload")

	// Properties
	const isOwner = id === "owner"
	const [hover, setHover] = useState(false)

	return (
		<HorizontalContainer
			style={{
				paddingRight: "16px",
				paddingTop: "8px",
				paddingBottom: "8px",
				zIndex: 2,
				borderRadius: "100px",
				background: isOwner ? Gradient : "white",
				willChange: "transform",
				pointerEvents: "none",
			}}
			alignItems="center"
			spacing={10}
		>
			{(presenceState === PresenceState.Blocked || presenceState === PresenceState.Megaphone || isAudioMuted) && (
				<AvatarStatus id={id} isAudioMuted={isAudioMuted} presenceState={presenceState} />
			)}
			<P
				style={{
					willChange: "transfrom",
					whiteSpace: "nowrap",
					color: isOwner ? "white" : "black",
					pointerEvents: "all",
				}}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{isOwner ? "You" : name}
				{hover && isModerator && <span style={{ color: "red", marginLeft: "5px" }}>👮</span>}
			</P>
		</HorizontalContainer>
	)
})

export default AvatarTag
