import {
	AreaUpdate,
	AreaUpdateChange,
	AreaUpdateDelete,
	AreaUpdateType,
	ChatMessage,
	ChatUpdateChange,
	ChatUpdateInit,
	ChatUpdateType,
	LiveSpaceUpdateChange,
	LiveSpaceUpdateFull,
	LiveSpaceUpdateInit,
	LiveSpaceUpdateOver,
	LiveSpaceUpdateType,
	ModeratorType,
	ModeratorUpdateAdd,
	ModeratorUpdateInit,
	ModeratorUpdateRemove,
	ModeratorUpdateType,
	MovementUpdate,
	SpeakerType,
	SpeakerUpdatDelete,
	SpeakerUpdateChange,
	SpeakerUpdateInit,
	SpeakerUpdateType,
	UserUpdate,
	UserUpdateType,
} from "@saysom/shared"
import io, { Socket } from "socket.io-client"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { config } from "../config"
import { BASE_URL } from "../constants/BaseUrl"
import { Ping } from "../utils/ping"
import { TimeSyncer } from "../utils/time"
import { useAreaStore } from "./areaStore"
import { useAuthStore } from "./authStore"
import { useAvatarStore } from "./avatarStore"
import { useChatStore } from "./chatStore"
import { useCommunicationStore } from "./communicationStore"
import { GroupUpdate, GroupUpdateType, useGroupStore } from "./groupStore"
import { useLocalStore } from "./localStore"
import { useNotificationStore } from "./notificationStore"
import { useSceneStore } from "./sceneStore"
import { useSpaceStore } from "./spaceStore"
import { useSpeakerStore } from "./speakerStore"

export type LiveSpaceUpdate = LiveSpaceUpdateInit | LiveSpaceUpdateChange | LiveSpaceUpdateOver | LiveSpaceUpdateFull

type SocketEmitter = {
	position: (pos: number[]) => void

	joinGroup: (userId: string) => void
	leaveGroup: (userId: string) => void
	privateGroup: (privatise: boolean) => void

	chat: (chatMessage: ChatMessage) => void
	area: (areaUpdate: AreaUpdate) => void
	space: (spaceUpdate: LiveSpaceUpdateChange) => void
	speaker: (speakerUpdate: SpeakerUpdateChange | SpeakerUpdatDelete) => void
	moderator: (moderatorUpdate: ModeratorUpdateAdd | ModeratorUpdateRemove) => void
}

type SocketStore = {
	spaceId?: string
	communicationToken?: string

	ping?: Ping
	timeSyncer?: TimeSyncer

	socket?: Socket

	join: () => void
	leave: () => void
	emit: SocketEmitter

	resetStore: () => void
}

export const useSocketStore = create(
	immer<SocketStore>((set, get) => ({
		spaceId: undefined,
		communicationToken: undefined,

		ping: undefined,
		timeSyncer: undefined,

		socket: undefined,

		resetStore() {
			const { socket } = get()
			if (socket) socket.disconnect()

			set((state) => {
				state.ping = undefined
				state.socket = undefined
				state.spaceId = undefined
				state.timeSyncer = undefined
				state.communicationToken = undefined
			})
		},

		join: () => {
			const { spaceId } = get()
			const token = useAuthStore.getState().token

			if (!token) return

			let query: Record<string, any> = { token, spaceId }

			const avatarImageUrl = useLocalStore.getState().avatarImageUrl
			if (avatarImageUrl !== undefined) {
				query.avatarImageUrl = avatarImageUrl
			}

			const servers = config.servers
			let url = BASE_URL
			if (spaceId && servers[spaceId]) {
				url = servers[spaceId]
			}
			console.log("CONNECTING TO SERVER: ", url)

			const socket = io(url, {
				transports: ["websocket"],
				query,
			})
			// console.log("connecting to socket…")

			// custom errors from the api
			socket.on("apiError", ({ message }: Record<string, string>) => {
				useNotificationStore.getState().showError(message)
			})

			socket.on("pongs", () => {
				var { ping } = get()
				if (ping) ping.retreivePing()
			})

			// Receive server user update
			socket.on("space", (spaceUpdate: LiveSpaceUpdate) => {
				if (spaceUpdate) {
					switch (spaceUpdate.type) {
						case LiveSpaceUpdateType.Init:
							useSpaceStore.getState().updateSpace.init(spaceUpdate.liveSpace)

							set((state) => {
								state.ping = new Ping(1000, () => {
									socket.emit("pings", {})
								})
							})

							break

						case LiveSpaceUpdateType.Change:
							useSpaceStore.getState().updateSpace.title(spaceUpdate.liveSpace.title, false)
							if (spaceUpdate.liveSpace.logoUrl)
								useSpaceStore.getState().updateSpace.logoUrl(spaceUpdate.liveSpace.logoUrl, false)
							if (spaceUpdate.liveSpace.startDate)
								useSpaceStore.getState().updateSpace.startDate(spaceUpdate.liveSpace.startDate, false)
							useSpaceStore.getState().updateSpace.isPrivate(spaceUpdate.liveSpace.isPrivate, false)
							useSpaceStore.getState().updateSpace.moderatorColor(spaceUpdate.liveSpace.colorScheme.moderator, false)
							useSpaceStore
								.getState()
								.updateSpace.roomColorBottomRight(spaceUpdate.liveSpace.colorScheme.roomColor[0], false)
							useSpaceStore
								.getState()
								.updateSpace.roomColorTopLeft(spaceUpdate.liveSpace.colorScheme.roomColor[1], false)
							break

						case LiveSpaceUpdateType.Over:
							window.location.href = `/${useSocketStore.getState().spaceId}/over`
							useNotificationStore.getState().showError("This event is over")
							break

						case LiveSpaceUpdateType.Full:
							// TODO: @Cornelius redirect
							useNotificationStore.getState().showError("This event is full")

							break
					}
				}
			})

			// Receive server user update
			socket.on("user", (userUpdate: UserUpdate) => {
				const actions = useAvatarStore.getState().actions

				if (userUpdate) {
					switch (userUpdate.type) {
						case UserUpdateType.Init:
							actions.updateLiveUserData(userUpdate.users)
							break

						case UserUpdateType.Change:
							actions.updateLiveUserData(userUpdate.users)
							break

						case UserUpdateType.Delete:
							actions.removeUser(userUpdate.id)
							break
					}
				}
			})

			socket.on("moderator", (moderatorUpdate: ModeratorUpdateInit | ModeratorUpdateAdd | ModeratorUpdateRemove) => {
				const actions = useAvatarStore.getState().actions

				if (moderatorUpdate) {
					switch (moderatorUpdate.type) {
						case ModeratorUpdateType.Init:
							moderatorUpdate.moderators.forEach(({ email, type }) => {
								actions.updateModeratorType(email, type)
							})
							break

						case ModeratorUpdateType.Add:
							moderatorUpdate.moderators.forEach(({ email, type }) => {
								actions.updateModeratorType(email, type)
							})
							break

						case ModeratorUpdateType.Remove:
							moderatorUpdate.moderators.forEach((email) => {
								const speaker = useSpeakerStore.getState().speaker

								if (speaker["owner"]) {
									const removeSpeaker = useSpeakerStore.getState().request.delete
									const room = useCommunicationStore.getState().room
									if (speaker["owner"].speakerType === SpeakerType.ScreenShare) {
										room?.stopScreenShare()
									}
									removeSpeaker("owner")
								}

								actions.updateModeratorType(email, ModeratorType.None)
							})
							break
					}
				}
			})

			// Receive move update
			socket.on("move", (movementUpdate: MovementUpdate) => {
				var { ping, timeSyncer } = get()

				if (timeSyncer === undefined) {
					set((state) => {
						state.timeSyncer = new TimeSyncer(config.server.tickRate)
					})
				}

				if (ping && ping.latency && timeSyncer) {
					timeSyncer.updateTime(ping.latency, movementUpdate.time)
				}

				if (Object.values(movementUpdate).length === 0) return

				const actions = useAvatarStore.getState().actions
				actions.updatePositionBuffers(movementUpdate.positions)
			})

			// Group updates
			socket.on("group", (data: GroupUpdate[]) => {
				data.forEach((groupUpdate: GroupUpdate) => {
					switch (groupUpdate.type) {
						case GroupUpdateType.create:
							useGroupStore.getState().create(groupUpdate)

							break

						case GroupUpdateType.join:
							useGroupStore.getState().join(groupUpdate)
							break

						case GroupUpdateType.leave:
							useGroupStore.getState().leave(groupUpdate)
							break

						case GroupUpdateType.destroy:
							useGroupStore.getState().destroy(groupUpdate)
							break

						case GroupUpdateType.private:
							useGroupStore.getState().private(groupUpdate)
							break

						default:
						// TODO: Throw error
					}

					// TODO: Renable
					// if (groupUpdate && useSettingsStore.getState().debug) useGroupStore.getState().log(groupUpdate)
				})
			})

			socket.on("chat", (chatUpdate: ChatUpdateInit | ChatUpdateChange) => {
				if (chatUpdate) {
					switch (chatUpdate.type) {
						case ChatUpdateType.Init:
							useChatStore.getState().initHistory(chatUpdate.all, chatUpdate.group, chatUpdate.private)
							break

						case ChatUpdateType.Change:
							useChatStore.getState().addChatToHistory(chatUpdate.chatMessage)
							break
					}
				}
			})

			socket.on("area", (areaUpdate: AreaUpdateChange | AreaUpdateDelete) => {
				if (areaUpdate) {
					switch (areaUpdate.type) {
						case AreaUpdateType.Change:
							Object.entries(areaUpdate.areas).forEach(([id, area]) => {
								useAreaStore.getState().updateArea.add(id, area)
							})
							break

						case AreaUpdateType.Delete:
							useAreaStore.getState().updateArea.delete(areaUpdate.key)
							break
					}
				}
			})

			socket.on("communication", (token: string) => {
				set((state) => {
					state.communicationToken = token
					if (state.spaceId && state.communicationToken) {
						useCommunicationStore.getState().join(state.spaceId, state.communicationToken)
					}
				})
			})

			socket.on("speaker", (speakerUpdate: SpeakerUpdateInit | SpeakerUpdateChange | SpeakerUpdatDelete) => {
				if (speakerUpdate) {
					switch (speakerUpdate.type) {
						case SpeakerUpdateType.Init:
							useSpeakerStore.getState().update.change(speakerUpdate.speaker)
							break

						case SpeakerUpdateType.Change:
							useSpeakerStore.getState().update.change(speakerUpdate.speaker)
							break

						case SpeakerUpdateType.Delete:
							useSpeakerStore.getState().update.delete(speakerUpdate.id)
							break
					}
				}
			})
			set((state) => {
				// @ts-ignore
				state.socket = socket
			})
		},

		leave: () => {
			const { socket } = get()
			if (socket) socket.disconnect()

			const actions = useAvatarStore.getState().actions
			actions.removeUser("owner")

			useCommunicationStore.getState().leave()

			useSceneStore.setState({
				isInitial: true,
				isInitialZoom: true,
				zoom: config.camera.initialZoom,
			})

			set((state) => {
				state.socket = undefined
			})
		},

		emit: {
			space(spaceUpdate) {
				var { socket } = get()

				if (socket) {
					socket.emit("space", spaceUpdate)
				}
			},
			position(pos) {
				var { socket } = get()

				if (socket) {
					socket.emit("move", pos)
					// @ts-ignore
					//socket.volatile.emit("move", { data: pos })
					//console.log(socket)
					// TODO: Send less often
				}
			},
			joinGroup(userId) {
				var { socket } = get()

				if (socket) {
					socket.emit("joinGroup", { otherUserId: userId })
				}
			},
			leaveGroup(userId) {
				var { socket } = get()

				if (socket) {
					socket.emit("leaveGroup", { otherUserId: userId })
				}
			},
			privateGroup(privatise) {
				var { socket } = get()

				if (socket) {
					socket.emit("privateGroup", { private: privatise })
				}
			},
			chat(chatMessage) {
				var { socket } = get()

				if (socket) {
					const chatUpdateChange: ChatUpdateChange = { type: ChatUpdateType.Change, chatMessage: chatMessage }
					socket.emit("chat", chatUpdateChange)
				}
			},
			area(areaUpdate) {
				var { socket } = get()

				if (socket) {
					socket.emit("area", areaUpdate)
				}
			},
			speaker(speakerUpdate) {
				var { socket } = get()

				if (socket) {
					socket.emit("speaker", speakerUpdate)
				}
			},
			moderator(moderatorUpdate) {
				var { socket } = get()

				if (socket) {
					socket.emit("moderator", moderatorUpdate)
				}
			},
		},
	}))
)
