import { Button, HorizontalContainer } from "@saysom/shared"
import React from "react"
import Pin from "../../assets/images/pin.svg"
import { useAvatarStore } from "../../stores/avatarStore"
import { Target, useSceneStore } from "../../stores/sceneStore"

const ActiveFunctionInfoBar = ({ panelOpen }: { panelOpen: boolean }) => {
	const targetId = useSceneStore((state) => state.target.id)
	const setTarget = useSceneStore((state) => state.target.setTarget)

	const users = useAvatarStore((state) => state.users)

	return (
		<div
			className="absolute top-2.5 left-2.5 flex justify-center transition-all h-16 items-stretch"
			style={{
				right: panelOpen ? "370px" : "10px",
			}}
		>
			{targetId !== undefined && (
				<HorizontalContainer alignItems="center">
					<div className="p-2 bg-gray-300 rounded-lg">
						<img src={Pin} alt="" />
					</div>

					<div className="ml-2 mr-5">
						<p className="text-sm font-bold leading-tight">Observing:</p>
						<p className="text-base leading-tight">{users[targetId].name}</p>
					</div>

					<div className="bg-gray-200 rounded-lg">
						<Button title="×" onClick={() => setTarget(undefined, Target.Object)} className="text-2xl relative pb-1" />
					</div>
				</HorizontalContainer>
			)}
		</div>
	)
}

export default ActiveFunctionInfoBar
