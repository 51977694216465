import { Shadows, VerticalGroup } from "@saysom/shared"
import styled from "styled-components"

export const Container = styled(VerticalGroup)`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: white;
	z-index: 2;

	align-items: center;
	justify-content: center;
`

export const Overlay = styled.div`
	background-color: rgba(51, 51, 51, 0.3);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 4;

	display: flex;
	align-items: center;
	justify-content: center;
`

export const PermissionInstruction = styled(VerticalGroup)`
	background-color: white;
	padding: 15px;
	box-shadow: ${Shadows.highlighted};
	border-radius: 10px;

	width: 500px;

	@media (max-width: 500px) {
		width: 90vw;
	}
`

export const Warning = styled.img`
	width: 20px;
	margin-right: 10px;
`

export const Description = styled(VerticalGroup)`
	padding-left: 30px;
	margin-top: 10px;
`
