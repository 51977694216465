import { Vector4 } from "three"

export class PositionSnapshot {
	t: number
	pos: { x: number; y: number }
	tpos: { x: number; y: number }

	constructor(data: number[]) {
		this.t = data[4]
		this.pos = { x: data[0], y: data[1] }
		this.tpos = { x: data[2], y: data[3] }
	}

	toVec4(): Vector4 {
		return new Vector4(this.pos.x, this.pos.y, this.tpos.x, this.tpos.y)
	}
}

export class PositionBuffer {
	maxSize: number
	posSnaps: PositionSnapshot[]

	constructor(tickRate: number, tickLag: number) {
		this.maxSize = Math.ceil((tickLag * 2) / tickRate) + 2
		this.posSnaps = []
	}

	add(posSnap: PositionSnapshot): void {
		this.posSnaps.push(posSnap)
		if (this.posSnaps.length >= this.maxSize) this.posSnaps.shift()
	}

	current(): PositionSnapshot | undefined {
		if (this.posSnaps.length === 0) return undefined

		return this.posSnaps[this.posSnaps.length - 1]
	}

	interpolate(t: number): PositionSnapshot | undefined {
		if (this.posSnaps.length <= 1) return undefined

		if (t > this.posSnaps[this.posSnaps.length - 1].t) return undefined

		// TODO: Check if in previous time frame to be faster

		let bt = 0 // before timestamp
		let at = 0 // after timestamp
		let btPosSnap: PositionSnapshot | undefined = undefined // before timestamp position snapshot
		let atPosSnap: PositionSnapshot | undefined = undefined // after timestamp position snapshot

		let tInBuffer = false
		let sliceIndex = -1

		for (var i = 1; i < this.posSnaps.length; i++) {
			bt = this.posSnaps[i - 1].t
			at = this.posSnaps[i].t

			if (bt <= t && t <= at) {
				tInBuffer = true
				btPosSnap = this.posSnaps[i - 1]
				atPosSnap = this.posSnaps[i]
				break
			} else {
				sliceIndex = i - 1
			}
		}

		if (sliceIndex >= 0) this.posSnaps.slice(sliceIndex, this.posSnaps.length)

		if (!tInBuffer) {
			console.log("Timestep is not cotained in buffer, to late")
			return undefined
		}

		if (!(atPosSnap && btPosSnap)) return undefined

		const timeDiffRatio = (t - bt) / (at - bt)
		const atPosSnapFlat = atPosSnap?.toVec4()
		const btPosSnapFlat = btPosSnap?.toVec4()

		btPosSnapFlat.lerp(atPosSnapFlat, timeDiffRatio)

		return new PositionSnapshot([...btPosSnapFlat.toArray(), t])
	}
}
