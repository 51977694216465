import { Button } from "@saysom/shared"
import { Properties } from "csstype"
import React, { useEffect, useState } from "react"
import LockClosed from "../../assets/images/lockClosed.svg"
import LockOpen from "../../assets/images/lockOpen.svg"
import { useGroupStore } from "../../stores/groupStore"
import { useSocketStore } from "../../stores/socketStore"

const GroupControls = ({ style }: { style?: Properties }) => {
	const [requestPrivacy, setRequestPrivacy] = useState(false)

	const ownerGroupPrivacy = useGroupStore((state) => state.ownerGroupPrivacy)

	const handlePrivacy = () => {
		if (ownerGroupPrivacy !== undefined) {
			// FIXME:
			// @ts-ignore: Fix later
			useSocketStore.getState().emit.privateGroup(!ownerGroupPrivacy)
			setRequestPrivacy(true)
		}
	}

	useEffect(() => {
		setRequestPrivacy(false)
	}, [ownerGroupPrivacy])

	return (
		<>
			{ownerGroupPrivacy !== undefined && (
				<>
					<div style={{ width: "1px", backgroundColor: "lightgrey", alignSelf: "stretch", ...style }} />

					<Button
						data-tip={"Open Group"}
						image={ownerGroupPrivacy ? LockClosed : LockOpen}
						onClick={() => handlePrivacy()}
						disabled={requestPrivacy}
						style={style}
					/>
				</>
			)}
		</>
	)
}

export default GroupControls
