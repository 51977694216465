import { HorizontalGroup } from "@saysom/shared"
import React, { memo, useCallback, useEffect, useState } from "react"
import WarningIcon from "../../assets/images/warning.svg"
import { H3, P } from "../../assets/style"
import { useLocalStore } from "../../stores/localStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSocketStore } from "../../stores/socketStore"
import { Description, Overlay, PermissionInstruction, Warning } from "./onboarding_style"
import Welcome from "./welcome"

interface OnboardingProps {
	id: string
}

const Onboarding = memo<OnboardingProps>(({ id }) => {
	const [showOverlay, setShowOverlay] = useState(true)
	const [showPermissionOverlay, setShowPermissionOverlay] = useState(false)
	const [hasPermissions, setHasPermissions] = useState(false)

	const didVisitSpaceBefore = useLocalStore((state) => state.visitedSpaces.includes(id))

	// const [connecting, setConnecting] = useState(false)

	const join = useSocketStore((state) => state.join) as Function
	const updateDevices = useSettingsStore((state) => state.updateIO)

	const checkPermission = useCallback(async () => {
		let hasPermissions = false
		const devices = await navigator.mediaDevices.enumerateDevices()
		devices.forEach((device) => {
			if (device.label && device.label.length > 0) {
				hasPermissions = true
			}
		})

		setHasPermissions(hasPermissions)
		setShowPermissionOverlay(!hasPermissions)

		if (hasPermissions) {
			updateDevices(devices)
		}
	}, [updateDevices])

	const askForPermission = useCallback(async () => {
		await navigator.mediaDevices.getUserMedia({ audio: true, video: true })

		checkPermission()
	}, [checkPermission])

	useEffect(() => {
		checkPermission()

		askForPermission()
	}, [checkPermission, askForPermission])

	const connect = useCallback(async () => {
		try {
			join()
			setShowOverlay(false)
		} catch (e) {
			console.log(e)
			alert(e)
		}
	}, [join])

	useEffect(() => {
		// connect()
	}, [hasPermissions, connect])

	useEffect(() => {
		const onDeviceChange = async () => {
			const devices = await navigator.mediaDevices.enumerateDevices()
			updateDevices(devices)
		}

		if (!showOverlay) {
			navigator.mediaDevices.addEventListener("devicechange", onDeviceChange)
		}

		return () => navigator.mediaDevices.removeEventListener("devicechange", onDeviceChange)
	}, [showOverlay, updateDevices])

	/* useEffect(() => {
		console.log(didVisitSpaceBefore, showPermissionOverlay, showOverlay)
		if ((showOverlay || !didVisitSpaceBefore) && !showPermissionOverlay) setContext()
	}, [didVisitSpaceBefore, showPermissionOverlay, showOverlay, setContext])*/

	// TODO: merge with tutorial
	return (
		<>
			{showOverlay && <Welcome onJoin={connect} hasPermissions={hasPermissions} />}

			{showPermissionOverlay && (
				<Overlay>
					<PermissionInstruction>
						<HorizontalGroup>
							<Warning src={WarningIcon} />
							<H3>SaySom requires permission</H3>
						</HorizontalGroup>
						<Description spacing={10}>
							<P>
								{/* SaySom requires access to your microphone and camera to enable you to join. You can disable microphone
								and video inside the app. The SaySom team thanks you for your understanding. */}
								Saysom requires access to your micprohone and camera to enable you to join. You can disable microphone
								and video inside the app. The Saysom Team thanks you for your understanding. SaySom acts as a processor
								for the Eventhoster and processes all data (including video and audio data) for the sole purpose of
								providing the networking feature.
							</P>
							<P>
								Accidentaly clicked no? See{" "}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://help.doxy.me/en/articles/95877-allow-access-to-camera-mic-for-all-browsers-operating-systems"
								>
									on how to enable access
								</a>
							</P>
						</Description>
					</PermissionInstruction>
				</Overlay>
			)}
		</>
	)
})

export default Onboarding
