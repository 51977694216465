import { Shadows } from "@saysom/shared"
import styled from "styled-components"

export const Container = styled.div`
	position: relative;

	transition: all 400ms ease;

	display: flex;
	align-items: center;
	pointer-events: auto;

	background-color: rgba(255, 255, 255, 0.4);
	backdrop-filter: blur(10px);

	z-index: 20;

	overflow: hidden;
`

export const Speaker = styled.div`
	position: absolute;
	bottom: 10px;
	right: 10px;

	width: 77px;
	height: 77px;

	border-radius: 1000px;
	overflow: hidden;

	border: 2px solid white;
	box-shadow: ${Shadows.highlighted};
`
