import { useEffect, useState } from "react"
import { useSceneStore } from "../stores/sceneStore"

export const useScaleFactor = (normalScaleFactor = 0.02, threshold = 49) => {
	const [scaleFactor, setScaleFactor] = useState<number | undefined>(undefined)

	useEffect(
		() =>
			useSceneStore.subscribe((state) => {
				const zoom = state.zoom

				if (zoom > threshold && scaleFactor) {
					setScaleFactor(undefined)
				} else if (zoom <= threshold && !scaleFactor) {
					setScaleFactor(normalScaleFactor)
				}
			}),
		[scaleFactor, normalScaleFactor, threshold]
	)

	return scaleFactor
}
