import { useFrame } from "@react-three/fiber"
import { MutableRefObject, RefObject, useEffect, useRef } from "react"
import SimplexNoise from "simplex-noise"
import { Material, MathUtils, Mesh, Raycaster, Vector2, Vector3, Vector4 } from "three"
import "../../components/material/emptyMaterial"
import "../../components/material/metaballMaterial"
import { Bot, Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { Group, useGroupStore } from "../../stores/groupStore"
import { useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"

export type MetaballObject = {
	ref?: RefObject<Mesh>
	scale: number
	layer: boolean
	position: Vector2
}

const MetaballPlane = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Metaball Plane Reload")

	// Stores
	const debugStore = useDebugStore((state) => state)
	const clock = useSceneStore((state) => state.clock)
	const layerMask = useSceneStore((state) => state.layerMask)
	const bounds = useSceneStore((state) => state.floor.bounds)
	const radiusUser = useSceneStore((state) => state.metaball.radiusUser)
	const radiusTarget = useSceneStore((state) => state.metaball.radiusTarget)
	const updateFloorMesh = useSceneStore((state) => state.actions.updateFloorMesh)
	const visibleUsersCount = useAvatarStore((state) => state.visibleUsersCount)
	const updateIsSignaling = useAvatarStore((state) => state.actions.updateIsSignaling)
	const updateSignalStartElapsedTime = useAvatarStore((state) => state.actions.updateSignalStartElapsedTime)

	// Refs
	const mesh = useRef<Mesh>()
	const material = useRef<Material>()
	const simplex = new SimplexNoise()

	// State
	const signalMaxTime = 3
	const enableMetaball = !(debug && !debugStore.scene.metaball)
	const rotation: [x: number, y: number, z: number] = [
		MathUtils.degToRad(-90),
		MathUtils.degToRad(0),
		MathUtils.degToRad(0),
	]

	// Subscribe Store
	const users: MutableRefObject<Record<string, User | Owner | Bot>> = useRef({})
	useEffect(() => {
		useAvatarStore.subscribe((state) => (users.current = state.users))
	}, [])

	const groups: MutableRefObject<Record<string, Group>> = useRef({})
	useEffect(() => {
		useGroupStore.subscribe((state) => (groups.current = state.groups))
	}, [])

	const setMesh = (newRef: Mesh | null) => {
		if (newRef) {
			mesh.current = newRef
			updateFloorMesh(newRef)
			mesh.current.layers.set(layerMask)
		}
	}

	const searchFloorIntersection = (position: THREE.Vector3) => {
		if (!mesh || !mesh.current) return

		// Lift raystart over ground
		position.setY(0.01)

		// Raycast
		var raycaster = new Raycaster()
		raycaster.set(position, new Vector3(0, -1, 0))
		raycaster.layers.set(layerMask)

		// Intersection with background
		var intersects = raycaster.intersectObject(mesh.current, false)

		// First intersection
		return intersects[0]
	}

	const updateMetaballMaterial = (time: number) => {
		if (!mesh?.current) return
		if (!users?.current) return
		if (!material?.current) return

		// Material position array
		var matAvatarPos = new Array(visibleUsersCount).fill(new Vector4(10, 10, 10, 10))
		var matAvatarOpt = new Array(visibleUsersCount).fill(new Vector3(0, 0, 1))

		var counter = 0
		Object.entries(users.current).forEach(([id, user]) => {
			if (!user.isVisible) return
			if (counter >= visibleUsersCount) return
			if (!(user.mesh && user.meshTarget)) return

			// Convert target to direction
			const userPosClone = user.mesh.position.clone()
			let userTargetPosition = user.meshTarget.position.clone()
			const distanceScalar = Math.min(user.meshTarget.position.distanceTo(userPosClone), 5) / 6
			userTargetPosition = userPosClone.add(
				userTargetPosition.sub(userPosClone).normalize().multiplyScalar(distanceScalar)
			)

			var int = searchFloorIntersection(user.mesh.position)
			var intTar = searchFloorIntersection(userTargetPosition)

			if (!(int?.uv && intTar?.uv)) return

			let isPrivate = 0
			if (user.groupId && groups?.current) {
				const group = groups.current[user.groupId]
				if (group) isPrivate = group.private ? 1 : 0
			}

			let signalTime = 0
			if (user.isSignaling) {
				if (!user.signalStartElapsedTime) {
					updateSignalStartElapsedTime(id, time)
				} else {
					if (time - signalMaxTime < user.signalStartElapsedTime) {
						signalTime = time - user.signalStartElapsedTime
					} else {
						updateSignalStartElapsedTime(id, undefined)
						updateIsSignaling(id, false)
					}
				}
			}

			let metaballRadiusOffset = 0
			if (user.isBot) {
				isPrivate = (user as Bot).isInPrivateGroup ? 1 : 0
				if ((user as Bot).isTalking) {
					metaballRadiusOffset = simplex.noise2D(time, time + id.charCodeAt(5)) * 0.0005
				}
			}

			matAvatarOpt[counter] = new Vector3(metaballRadiusOffset, isPrivate, signalTime)
			matAvatarPos[counter] = new Vector4(int.uv.x, int.uv.y, intTar.uv.x, intTar.uv.y)

			counter += 1
		})
		// @ts-ignore
		material.current.time = time
		// @ts-ignore
		material.current.avatarOptions = matAvatarOpt
		// @ts-ignore
		material.current.avatarPositions = matAvatarPos
	}

	useFrame(() => {
		const time = clock.getElapsedTime() // (!) Do not change
		updateMetaballMaterial(time)
	})

	return (
		<mesh name="metaball" ref={setMesh} rotation={rotation}>
			<planeGeometry attach="geometry" args={bounds} />

			{enableMetaball ? (
				// @ts-ignores
				<metaballMaterial
					ref={material}
					attach="material"
					args={[bounds[0], bounds[1], visibleUsersCount, radiusUser, radiusTarget, signalMaxTime]}
				/>
			) : (
				// @ts-ignore
				<emptyMaterial />
			)}
		</mesh>
	)
}

export default MetaballPlane
