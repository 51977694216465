import { LiveSpace, LiveSpaceUpdateChange, LiveSpaceUpdateType, slugString } from "@saysom/shared"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { fetchApiAuthorized } from "../network/fetch"
import { getAwsUrlForKey } from "../utils/aws"
import { useSocketStore } from "./socketStore"

export interface Space extends LiveSpace {
	logoImage?: File
}

type Update = {
	copy?: Space
	didChange: boolean
	init: (liveSpace: LiveSpace) => void
	title: (title: string, local: boolean) => void
	logoUrl: (logoUrl: string, local: boolean) => void
	logoImage: (logoImage: File, local: boolean) => void
	isPrivate: (isPrivate: boolean, local: boolean) => void
	startDate: (startDate: Date, local: boolean) => void
	roomColorTopLeft: (roomColor: string, local: boolean) => void
	roomColorBottomRight: (roomColor: string, local: boolean) => void
	moderatorColor: (color: string, local: boolean) => void
	reset: () => void
}

type Request = {
	update: () => void
}

type SpaceStore = {
	space?: Space

	updateSpace: Update
	requestSpace: Request
}

export const useSpaceStore = create(
	immer<SpaceStore>((set, get) => ({
		space: undefined,

		requestSpace: {
			async update() {
				const { space } = get()
				if (space) {
					const spaceUpdateChange: LiveSpaceUpdateChange = {
						type: LiveSpaceUpdateType.Change,
						liveSpace: {
							title: space.title,
							logoUrl: space.logoUrl,
							startDate: space.startDate,

							isPrivate: space.isPrivate,
							colorScheme: space.colorScheme,
						},
					}

					if (space.logoImage) {
						const formData = new FormData()
						formData.append("logo", space.logoImage)

						await fetchApiAuthorized(`/spaces/${space.code}`, formData, "PUT")

						spaceUpdateChange.liveSpace.logoUrl = getAwsUrlForKey(
							`spaces/${space.code}/logo${slugString(space.logoImage.name)}`
						)
					}

					useSocketStore.getState().emit.space(spaceUpdateChange)
				}

				set((state) => {
					state.updateSpace.didChange = false
				})
			},
		},

		updateSpace: {
			didChange: false,
			init(liveSpace) {
				set((state) => {
					state.space = liveSpace
					state.updateSpace.copy = { ...state.space }
					state.updateSpace.didChange = false
				})
			},
			title(title, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							if (state.updateSpace.copy.title !== title) state.updateSpace.copy.title = title
						}
					}
					if (state.space) state.space.title = title
				})
			},
			logoUrl(logoUrl, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							if (state.updateSpace.copy.logoUrl !== logoUrl) state.updateSpace.copy.logoUrl = logoUrl
						}
					}
					if (state.space) state.space.logoUrl = logoUrl
				})
			},
			logoImage(logoImage, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							state.updateSpace.copy.logoImage = logoImage
						}
					}
					if (state.space) state.space.logoImage = logoImage
				})
			},
			isPrivate(isPrivate, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							if (state.updateSpace.copy.isPrivate !== isPrivate) state.updateSpace.copy.isPrivate = isPrivate
						}
					}
					if (state.space) state.space.isPrivate = isPrivate
				})
			},
			startDate(startDate, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							if (state.updateSpace.copy.startDate !== startDate) {
								state.updateSpace.copy.startDate = startDate
							}
						}
					}
					if (state.space) state.space.startDate = startDate
				})
			},
			roomColorBottomRight(roomColor, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							if (state.updateSpace.copy.colorScheme?.roomColor[0] !== roomColor)
								state.updateSpace.copy.colorScheme.roomColor = [
									roomColor,
									state.updateSpace.copy.colorScheme.roomColor[1],
								]
						}
					}
					if (state.space) state.space.colorScheme.roomColor = [roomColor, state.space.colorScheme.roomColor[1]]
				})
			},
			roomColorTopLeft(roomColor, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							if (state.updateSpace.copy.colorScheme?.roomColor[1] !== roomColor)
								state.updateSpace.copy.colorScheme.roomColor = [
									state.updateSpace.copy.colorScheme.roomColor[0],
									roomColor,
								]
						}
					}
					if (state.space) state.space.colorScheme.roomColor = [state.space.colorScheme.roomColor[0], roomColor]
				})
			},
			moderatorColor(moderatorColor, local) {
				set((state) => {
					if (local) {
						state.updateSpace.didChange = true
					} else {
						if (state.updateSpace.copy === undefined) {
							if (state.space) state.updateSpace.copy = { ...state.space }
						} else {
							if (state.updateSpace.copy.colorScheme?.moderator !== moderatorColor)
								state.updateSpace.copy.colorScheme.moderator = moderatorColor
						}
					}
					if (state.space) state.space.colorScheme.moderator = moderatorColor
				})
			},
			reset() {
				set((state) => {
					if (state.updateSpace.didChange && state.updateSpace.copy) {
						state.space = { ...state.updateSpace.copy }
						state.updateSpace.didChange = false
						state.updateSpace.copy = { ...state.space }
					}
				})
			},
		},
	}))
)
