import { HorizontalGroup, VerticalGroup } from "@saysom/shared"
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { BlackButton, H2, H3, H4, P } from "../../assets/style"
import { fetchApiForUrl } from "../../network/fetch"
import { useAuthStore } from "../../stores/authStore"
import { useLocalStore } from "../../stores/localStore"
import { useSceneStore } from "../../stores/sceneStore"
import { urlForSpaceId } from "../../utils/formatUrl"

interface SpaceJoinerProps {
	spaceId: string
	eventName?: string
	small?: boolean
	reload?
}

const SpaceJoiner = ({ spaceId, small = false, eventName = undefined }: SpaceJoinerProps) => {
	const [onlineUsers, setOnlineUser] = useState<Record<string, unknown>[] | undefined>(undefined)
	const [title, setTitle] = useState<string>("")
	const [logo, setLogo] = useState<string | undefined>(undefined)
	const [isOver, setIsOver] = useState<boolean | undefined>(undefined)

	const token = useAuthStore((state) => state.token)

	const history = useHistory()
	const name = useLocalStore((state) => state.name)
	const isModerator = useLocalStore((state) => state.isModerator)
	const leaveScene = useSceneStore((state) => state.leaveScene)

	const joinSpace = useCallback(
		async (spaceId: string) => {
			const result = await fetchApiForUrl(urlForSpaceId(spaceId), `/spaces/${spaceId}`)
			const onlineUsers = result?.onlineUsers

			if (onlineUsers === undefined || onlineUsers.length < 100) {
				leaveScene()

				if (token !== undefined) {
					history.push(`/${spaceId}`)
				} else if (name !== undefined) {
					history.push(`/${spaceId}/join?name=${name}&isModerator=${isModerator ? isModerator : 0}`)
				} else {
					history.push(`/${spaceId}/join`)
				}
				window.location.reload()
			} else {
				throw new Error("Space full")
			}
		},
		[history, isModerator, name, leaveScene, token]
	)

	const fetchSpace = useCallback(async (id: string) => {
		const result = await fetchApiForUrl(urlForSpaceId(id), `/spaces/${id}`)
		setOnlineUser(result?.onlineUsers)
		setLogo(result?.logoUrl)
		setTitle(result?.title)
		setIsOver(result?.isOver)
	}, [])

	useEffect(() => {
		fetchSpace(spaceId)
	}, [spaceId, fetchSpace])

	return (
		<HorizontalGroup justifyContent="space-between" alignItems="center">
			<VerticalGroup spacing={8}>
				{eventName &&
					(!small ? (
						<H2
							style={{
								flex: "0",
								whiteSpace: "nowrap",
							}}
						>
							{eventName}
						</H2>
					) : (
						<H3
							style={{
								flex: "0",
								whiteSpace: "nowrap",
							}}
						>
							{eventName}
						</H3>
					))}
				{!small ? (
					<H3> {title ? title : "Information unavailable"} </H3>
				) : (
					<H4> {title ? title : "Information unavailable"} </H4>
				)}
			</VerticalGroup>
			<VerticalGroup alignItems="center" spacing={8}>
				<BlackButton
					style={{ fontSize: "12pt", fontWeight: 600, padding: "4px 10px 4px 10px" }}
					onClick={() => {
						joinSpace(spaceId)
					}}
				></BlackButton>
				{isOver && <P> Space is over </P>}
				<P> {onlineUsers ? onlineUsers.length : "-"} / 100 </P>
			</VerticalGroup>
		</HorizontalGroup>
	)
}

export default SpaceJoiner
