import { useFrame } from "@react-three/fiber"
import { memo, MutableRefObject, useEffect, useRef } from "react"
import { config } from "../../config"
import { useAvatarStore, User } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSocketStore } from "../../stores/socketStore"
import { TimeSyncer } from "../../utils/time"

interface UserMovementManagerProps {
	id: string
}

const UserMovementManager = memo<UserMovementManagerProps>(({ id }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("User Movement Manager Reload")

	// Stores
	const movementDebug = useDebugStore((state) => state.movement)
	const settings = useSettingsStore((state) => state)
	const timeSyncer = useSocketStore((state) => state.timeSyncer)

	const user: MutableRefObject<User | undefined> = useRef(undefined)

	useEffect(() => {
		useAvatarStore.subscribe((state) => (user.current = state.users[id] as User))
	}, [id])

	const moveUser = () => {
		if (user.current && user.current.mesh && user.current.meshTarget) {
			const posSnap = user.current.posBuffer.current()
			if (posSnap) {
				user.current.mesh.position.set(posSnap.pos.x, 0, posSnap.pos.y)
				user.current.meshTarget.position.set(posSnap.tpos.x, 0, posSnap.tpos.y)
			}
		}
	}

	const interpolateUser = () => {
		if (timeSyncer && user.current && user.current.mesh && user.current.meshTarget) {
			const now = (timeSyncer as TimeSyncer).serverTime

			if (now) {
				const t = now - config.server.tickLag

				let posSnap = user.current.posBuffer.interpolate(t)
				if (!posSnap) posSnap = user.current.posBuffer.current()

				if (posSnap) {
					user.current.mesh.position.set(posSnap.pos.x, 0, posSnap.pos.y)
					user.current.meshTarget.position.set(posSnap.tpos.x, 0, posSnap.tpos.y)
				} else {
					// No interpolation past server tick
					// TODO: Extrapolation
				}
			}
		}
	}

	useFrame(() => {
		if (settings.debug && !movementDebug.userInterpolation) {
			moveUser()
		} else {
			interpolateUser()
		}
	})

	return <> </>
})

export default UserMovementManager
