import { HorizontalGroup, VerticalGroup } from "@saysom/shared"
import styled from "styled-components"
import { BorderRadius } from "../../assets/style"

export const Container = styled(VerticalGroup)`
	z-index: 1;

	background-color: white;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	border-radius: 10px;

	pointer-events: auto;
	overflow: hidden;

	transition: max-height 200ms ease;
`

export const Header = styled(HorizontalGroup)`
	padding: 10px;

	cursor: pointer;
`

export const List = styled(VerticalGroup)`
	flex: 1;

	overflow: scroll;
	border-radius: ${BorderRadius};
`
