import { ChatMessage, ChatReceiver } from "@saysom/shared"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { useAvatarStore } from "./avatarStore"
import { useNotificationStore } from "./notificationStore"
import { useSocketStore } from "./socketStore"

export type ChatHistory = {
	all: ChatMessage[]
	group: Record<string, ChatMessage[]>
	private: Record<string, ChatMessage[]>
}

type ChatStore = {
	isGroupChat?: boolean
	updateIsGroupChat: (isGroupChat: boolean) => void

	openChatId?: string
	updateOpenChatId: (id?: string) => void

	chatHistory: ChatHistory
	initHistory: (
		all: ChatMessage[] | undefined,
		group: Record<string, ChatMessage[]> | undefined,
		_private: Record<string, ChatMessage[]> | undefined
	) => void
	addChatToHistory: (chatMessage: ChatMessage) => void

	sendChatMessage: (message: string, receiverType: ChatReceiver, receiverId?: string) => void
}

export const useChatStore = create(
	immer<ChatStore>((set, get) => ({
		chatHistory: { all: [], private: {}, group: {} },

		initHistory(all, group, _private) {
			set((state) => {
				state.chatHistory.all = all ? all : []
				state.chatHistory.group = group ? group : {}
				state.chatHistory.private = _private ? _private : {}
			})
		},
		updateIsGroupChat(isGroupChat) {
			set((state) => {
				state.isGroupChat = isGroupChat
			})
		},
		updateOpenChatId(id) {
			set((state) => {
				state.openChatId = id
			})
		},

		addChatToHistory(chatMessage: ChatMessage) {
			const { openChatId } = get()

			if (chatMessage.authorId) {
				switch (chatMessage.receiverType) {
					case ChatReceiver.All:
						if (openChatId !== "all") useNotificationStore.getState().showChatNotification(chatMessage)
						break

					case ChatReceiver.User:
						if (
							openChatId !== chatMessage.authorId &&
							chatMessage.authorId !== useAvatarStore.getState().users["owner"].id
						)
							useNotificationStore.getState().showChatNotification(chatMessage)
						break

					case ChatReceiver.Group:
						if (
							openChatId !== chatMessage.receiverId &&
							chatMessage.authorId !== useAvatarStore.getState().users["owner"].id
						)
							useNotificationStore.getState().showChatNotification(chatMessage)
						break
				}
			}

			set((state) => {
				const users = useAvatarStore.getState().users

				switch (chatMessage.receiverType) {
					case ChatReceiver.All:
						state.chatHistory.all = [...state.chatHistory.all, chatMessage]
						break

					case ChatReceiver.User:
						if (chatMessage.receiverId && chatMessage.authorId) {
							if (chatMessage.receiverId !== users["owner"].id) {
								const userChat = state.chatHistory.private[chatMessage.receiverId]
								if (userChat) {
									state.chatHistory.private[chatMessage.receiverId] = [...userChat, chatMessage]
								} else {
									state.chatHistory.private[chatMessage.receiverId] = [chatMessage]
								}
							} else {
								const userChat = state.chatHistory.private[chatMessage.authorId]
								if (userChat) {
									state.chatHistory.private[chatMessage.authorId] = [...userChat, chatMessage]
								} else {
									state.chatHistory.private[chatMessage.authorId] = [chatMessage]
								}
							}
						}
						break

					case ChatReceiver.Group:
						if (chatMessage.receiverId && chatMessage.authorId) {
							const groupChat = state.chatHistory.group[chatMessage.receiverId]
							if (groupChat) {
								state.chatHistory.group[chatMessage.receiverId] = [...groupChat, chatMessage]
							} else {
								state.chatHistory.group[chatMessage.receiverId] = [chatMessage]
							}
						}
						break
				}
			})
		},

		sendChatMessage(message: string, receiverType: ChatReceiver, receiverId?: string) {
			const chatMessage = {
				message,
				receiverType,
				receiverId,
			}
			useSocketStore.getState().emit.chat(chatMessage)
		},
	}))
)
