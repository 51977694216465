import { useEffect, useRef } from "react"
import * as THREE from "three"
import { Mesh, PlaneGeometry } from "three"
import { useDebugStore } from "../../stores/debugStore"
import { useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"
import "./dotMaterial"

const DotPlane = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Dot Plane Reload")

	// Refs
	const mesh = useRef<Mesh>(null)
	const geometry = useRef<PlaneGeometry>(null)
	const material = useRef()

	// Stores
	const bounds = useSceneStore((state) => state.floor.bounds)

	// Options
	const position: [x: number, y: number, z: number] = [0.5, -0.01, 0.5]
	const rotation: [x: number, y: number, z: number] = [
		THREE.MathUtils.degToRad(-90),
		THREE.MathUtils.degToRad(0),
		THREE.MathUtils.degToRad(0),
	]

	useEffect(() => {
		if (!material || !material.current) return
		if (!geometry || !geometry.current) return

		// @ts-ignore
		material.current.meshScale = new THREE.Vector2(
			// @ts-ignore
			geometry.current.parameters.width,
			// @ts-ignore
			geometry.current.parameters.height
		)
	}, [material, geometry])

	return (
		<mesh name="cirlce" ref={mesh} rotation={rotation} position={position}>
			<planeGeometry ref={geometry} attach="geometry" args={bounds} />
			{
				// @ts-ignore
				<dotMaterial ref={material} attach="material" />
			}
		</mesh>
	)
}

export default DotPlane
