import { VerticalGroup, Button, HorizontalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import React, { useState } from "react"
import { H3, H4, P } from "../../assets/style"
import { text } from "../../constants/GameText"
import Suffle from "../../assets/images/shuffle.svg"

const GameSelection = ({ style }: { style?: Properties }) => {
	const icebreaker = text.icebreaker
	const explainSubject = text.explainSubject
	const findPerson = text.findPerson

	const [icebreakerQuesion, setIcebreakerQuesion] = useState(icebreaker[Math.floor(Math.random() * icebreaker.length)])
	const [explainSubjectItem, setExplainSubjectItem] = useState(
		explainSubject[Math.floor(Math.random() * explainSubject.length)]
	)
	const [findPersonItem, setFindPersonItem] = useState(findPerson[Math.floor(Math.random() * findPerson.length)])

	const handleIcebreaker = () => {
		const randomIndex = Math.floor(Math.random() * icebreaker.length)
		const item = icebreaker[randomIndex]
		setIcebreakerQuesion(item)
	}

	const handleExplainSubject = () => {
		const randomIndex = Math.floor(Math.random() * explainSubject.length)
		const item = explainSubject[randomIndex]
		setExplainSubjectItem(item)
	}

	const handleFindPerson = () => {
		const randomIndex = Math.floor(Math.random() * findPerson.length)
		const item = findPerson[randomIndex]
		setFindPersonItem(item)
	}

	return (
		<VerticalGroup spacing={10} style={{ ...style, overflow: "hidden" }} flex={1}>
			<div
				style={{
					flex: 1,
					flexGrow: 1,
					alignSelf: "stretch",
					overflowY: "scroll",
					WebkitOverflowScrolling: "touch",
				}}
			>
				<VerticalGroup spacing={50}>
					<VerticalGroup spacing={10}>
						<VerticalGroup spacing={4}>
							<H4>Icebreaker</H4>
							<P>
								Often it isn't so easy to get a conversation started, right? Ask the other person a simple question!
							</P>
						</VerticalGroup>
						<HorizontalGroup alignItems="center" justifyContent="space-between">
							<P>{icebreakerQuesion}</P>
							<Button image={Suffle} style={{ minWidth: "36px" }} onClick={() => handleIcebreaker()} />
						</HorizontalGroup>
					</VerticalGroup>
					<VerticalGroup spacing={10}>
						<VerticalGroup spacing={4}>
							<H4>Tabu</H4>
							<P>You do not know what to talk about? Try to explain the other the following subject.</P>
						</VerticalGroup>
						<HorizontalGroup alignItems="center" justifyContent="space-between">
							<P>{explainSubjectItem}</P>
							<Button image={Suffle} style={{ minWidth: "36px" }} onClick={() => handleExplainSubject()} />
						</HorizontalGroup>
					</VerticalGroup>
					<VerticalGroup spacing={10}>
						<VerticalGroup spacing={4}>
							<H4>Findling</H4>
							<P>Try to find a person that matches the following description.</P>
						</VerticalGroup>
						<HorizontalGroup alignItems="center" justifyContent="space-between">
							<P>{findPersonItem}</P>
							<Button image={Suffle} style={{ minWidth: "36px" }} onClick={() => handleFindPerson()} />
						</HorizontalGroup>
					</VerticalGroup>
				</VerticalGroup>
			</div>
			<> </>
		</VerticalGroup>
	)
}

export default GameSelection
