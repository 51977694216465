import { VerticalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import { useState } from "react"
import { H3, H4 } from "../../assets/style"
import SpaceItem from "../spaceSelector/spaceItem"

interface SpaceSelectorProps {
	spaces: string[]
	small?: boolean
	onSelect: (id: string) => void
	style?: Properties
}

const SpaceSelector = ({ spaces, onSelect, small = false, style }: SpaceSelectorProps) => {
	const [selectedRoom, setSelectedRoom] = useState<string>(spaces[0])

	return (
		<VerticalGroup style={{ overflow: "hidden", ...style }} flex={1} spacing={10}>
			{!small ? <H3>Space Selection</H3> : <H4>Space Selection</H4>}

			<div
				style={{
					flex: 1,
					flexGrow: 1,
					alignSelf: "stretch",
					overflowY: "scroll",
					WebkitOverflowScrolling: "touch",
					borderRadius: "10px",
				}}
			>
				{spaces.map((spaceId, index) => (
					<SpaceItem
						key={spaceId}
						spaceId={spaceId}
						onSelect={() => {
							setSelectedRoom(spaceId)
							onSelect(spaceId)
						}}
						alternateRow={index % 2 === 0}
						selected={selectedRoom === spaceId}
					/>
				))}
			</div>
		</VerticalGroup>
	)
}

export default SpaceSelector
