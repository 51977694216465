import styled from "styled-components"

export const Container = styled.div`
	width: 100vw;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	flex-direction: column;

	@media (max-width: 700px) {
		justify-content: flex-start;

		padding-top: 40px;
		min-height: inherit;
	}
`
