import { useEffect } from "react"
import { useSpeakerStore } from "../../stores/speakerStore"
import Multiple from "./speaker/multiple"
import Single from "./speaker/single"

// closeToast = false
interface SpeakerNotificationProps {
	closeToast?: () => void
}

const SpeakerNotification = ({ closeToast }: SpeakerNotificationProps) => {
	const speakers = useSpeakerStore((state) => Object.values(state.speaker))

	useEffect(() => {
		useSpeakerStore.subscribe((state) => {
			const count = Object.keys(state.speaker).length

			if (count === 0) {
				if (closeToast) {
					closeToast()
				}
			}
		})
	}, [closeToast])

	return speakers.length === 1 ? (
		<Single speakerId={speakers[0].userId} />
	) : (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gap: "10px",
			}}
		>
			{speakers.map((speaker) => (
				<Multiple speakerId={speaker.userId} />
			))}
		</div>
	)
}

export default SpeakerNotification
