import { Button, HorizontalGroup, VerticalContainer } from "@saysom/shared"
import { useCallback } from "react"
import { Tooltip } from "react-tooltip"
import { P } from "../../assets/style"
import { config } from "../../config"
import { useSceneStore } from "../../stores/sceneStore"

const ZoomControlBar = () => {
	const zoom = useSceneStore(
		(state) => state.zoom,
		(a, b) => {
			if (typeof b === "number") {
				return Math.round(a) === Math.round(b)
			} else {
				return true
			}
		}
	)
	const zoomBounds = config.camera.zoomBounds

	const setZoomLevel = useCallback(
		(up: boolean) => {
			const current = Math.round((zoom - zoomBounds[0]) / 25)
			const next = zoomBounds[0] + (up ? current * 25 + 25 : current * 25 - 25)

			if (next >= zoomBounds[0] && next <= zoomBounds[1]) {
				useSceneStore.setState({ zoom: next })
			}
		},
		[zoom, zoomBounds]
	)

	return (
		<>
			<Tooltip place="top" variant="dark" />
			<VerticalContainer alignItems="center" spacing={5}>
				<Button data-tip={"Zoom In"} onClick={() => setZoomLevel(true)} disabled={Math.round(zoom) === 300} title="+" />
				<HorizontalGroup>
					<P style={{ width: "25px", textAlign: "right" }}>{Math.round(zoom)}</P>
					<P>%</P>
				</HorizontalGroup>
				<Button
					data-tip={"Zoom Out"}
					onClick={() => setZoomLevel(false)}
					disabled={Math.round(zoom) === 25}
					title="-"
				/>
			</VerticalContainer>
		</>
	)
}

export default ZoomControlBar
