import { Button, HorizontalGroup, VerticalGroup } from "@saysom/shared"
import { useEffect } from "react"
import Bell from "../../assets/images/bell.svg"
import Circle from "../../assets/images/circle.svg"
import Close from "../../assets/images/close.svg"
import Movement from "../../assets/images/movement.svg"
import Settings from "../../assets/images/settings.svg"
import Users from "../../assets/images/users.svg"
import Zoom from "../../assets/images/zoom.svg"
import { H3, P } from "../../assets/style"
import { useSpaceStore } from "../../stores/spaceStore"
import Emoji from "../../utils/emoji"
import { Color, Divider, Icon, IconImage, TextContainer } from "./notification_style"

interface TutotialItemProp {
	icon?: string
	color?: string
	texts: string[]
	margin: number
	isButton?: boolean
}

const TutotialItem = ({ icon, color, texts, margin, isButton = false }: TutotialItemProp) => {
	return (
		<HorizontalGroup alignItems="center" style={{ marginTop: margin + "px" }}>
			{(icon || color) && (
				<Icon border={isButton}>
					{icon && <IconImage src={icon} />}
					{color && <Color color={color} />}
				</Icon>
			)}

			<TextContainer style={{ paddingLeft: icon || color ? "15px" : "0px" }}>
				{texts.map((text) => (
					<P key={text}> {text} </P>
				))}
			</TextContainer>
		</HorizontalGroup>
	)
}

// closeToast = false
interface TutorialNotificationProps {
	closeToast?: () => void
}
const TutorialNotification = ({ closeToast }: TutorialNotificationProps) => {
	const moderatorColor = useSpaceStore((state) => state.space?.colorScheme?.moderator ?? "white")

	useEffect(() => {
		if (closeToast !== undefined) {
		}
	}, [closeToast])

	return (
		<VerticalGroup style={{ padding: "10px" }}>
			<HorizontalGroup justifyContent="space-between" alignItems="center">
				<H3>
					<Emoji symbol={"👋  "} /> - Welcome to SaySom
				</H3>
				<Button
					image={Close}
					onClick={() => {
						if (closeToast) closeToast()
					}}
				/>
			</HorizontalGroup>
			<TutotialItem
				margin={20}
				texts={["We noticed thats your first time here, lets get started with a few tips and tricks!"]}
			/>
			<Divider />
			<TutotialItem margin={15} icon={Movement} texts={["Click and drag to navigate", "or use the arrow keys"]} />
			<TutotialItem margin={15} icon={Zoom} texts={["Scroll to zoom", "or use the +/- in the bottom"]} />
			<Divider />
			<TutotialItem
				margin={15}
				icon={Users}
				texts={["Start conversations by moving and connecting to other participants"]}
			/>
			<Divider />
			<TutotialItem margin={15} icon={Settings} texts={["Change microphone and camera"]} isButton />
			<TutotialItem margin={15} icon={Bell} texts={["Send a message to everyone"]} isButton />
			<Divider />
			<TutotialItem margin={15} icon={Circle} texts={["Roles are indicated by color"]} isButton />
			<TutotialItem margin={15} color={moderatorColor} texts={["Moderator and admin color"]} isButton />
			<TutotialItem margin={15} color={"white"} texts={["Normal participant color"]} isButton />
		</VerticalGroup>
	)
}

export default TutorialNotification
