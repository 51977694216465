import { useFrame } from "@react-three/fiber"
import { memo, MutableRefObject, useEffect, useRef } from "react"
import { Bot, useAvatarStore } from "../../stores/avatarStore"
import { useBotAnimationStore } from "../../stores/botAnimationStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"

interface BotMovementManagerProps {
	id: string
}

const BotMovementManager = memo<BotMovementManagerProps>(({ id }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Bot Movement Manager Reload")

	// Store
	const currentTime = useBotAnimationStore((state) => state.currentTime)
	const interpolatePosition = useBotAnimationStore((state) => state.interpolatePosition)

	const bot: MutableRefObject<Bot | undefined> = useRef(undefined)
	useEffect(() => {
		useAvatarStore.subscribe((state) => (bot.current = state.users[id] as Bot))
	}, [id])

	const moveBot = () => {
		if (bot.current && bot.current.mesh && bot.current.meshTarget) {
			const pos = interpolatePosition(currentTime(), bot.current.positions)
			const posTar = interpolatePosition(currentTime(100), bot.current.positions)

			if (pos) {
				bot.current.mesh.position.set(pos[0], 0, pos[1])
				if (posTar) {
					const dir = [posTar[0] - pos[0], posTar[1] - pos[1]]
					bot.current.meshTarget.position.set(pos[0] + dir[0] * 10, 0, pos[1] + dir[1] * 10)
				}
			}
		}
	}

	useFrame(() => {
		moveBot()
	})

	return <> </>
})

export default BotMovementManager
