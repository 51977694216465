import { MutableRefObject } from "react"
import { toast } from "react-toastify"
import { Clock, Mesh } from "three"
import { create } from "zustand"
import TutorialNotification from "../components/notification/tutorialNotification"
import { config } from "../config"
import { useAvatarStore } from "./avatarStore"
import { useBotAnimationStore } from "./botAnimationStore"
import { useCommunicationStore } from "./communicationStore"
import { useGroupStore } from "./groupStore"
import { useInputStore } from "./inputStore"
import { useLocalStore } from "./localStore"
import { useSocketStore } from "./socketStore"

export enum Target {
	Object,
	Owner,
	User,
}

type SceneStore = {
	ref: MutableRefObject<Mesh | undefined> | undefined
	layerMask: number

	clock: Clock
	zoom: number
	isInitial: boolean
	isInitialZoom: boolean
	initialize: (spaceId: string) => void
	initializeZoom: () => void
	resetStore: () => void
	leaveScene: () => void

	target: {
		type: Target
		id: string | undefined
		mesh: Mesh | undefined
		setTarget: (ref: Mesh | undefined, type: Target) => void
		setTargetFromId: (id: string) => void
	}

	floor: {
		mesh?: Mesh
		bounds: [height: number, width: number]
	}

	metaball: {
		radiusUser: number
		radiusTarget: number
	}

	actions: {
		updateFloorMesh: (mesh: Mesh) => void
		updateZoomLevel: (delta: number) => void
	}
}

export const useSceneStore = create<SceneStore>((set, get) => {
	return {
		ref: undefined,
		layerMask: 2,

		// Camera
		clock: new Clock(),
		zoom: config.camera.initialZoom,
		isInitial: true,
		isInitialZoom: true,

		resetStore() {
			var { floor, target } = get()
			floor.mesh = undefined
			target.type = Target.Object
			target.id = undefined
			target.mesh = undefined

			set({
				ref: undefined,
				layerMask: 2,
				zoom: config.camera.initialZoom,
				isInitial: true,
				isInitialZoom: true,
				floor: floor,
				target: target,
			})
		},

		leaveScene() {
			const { resetStore } = get()
			useSocketStore.getState().resetStore()
			useCommunicationStore.getState().resetStore()
			useAvatarStore.getState().resetStore()
			useGroupStore.getState().resetStore()
			useInputStore.getState().resetStore()
			resetStore()
		},

		initializeZoom() {
			var { isInitialZoom } = get()
			if (isInitialZoom) {
				set({ isInitialZoom: false })
				set({ zoom: 100 })
			}
		},

		initialize(spaceId) {
			set({ isInitial: false })
			const { visitedSpaces } = useLocalStore.getState()
			const { botActions } = useAvatarStore.getState()
			const { serializedBots } = useBotAnimationStore.getState()

			serializedBots.forEach((bot) => {
				botActions.addBot(bot.name, bot.positions, bot.imageUrl, bot.videoUrl)
			})

			const { initializeZoom } = get()
			initializeZoom()

			if (visitedSpaces.length === 0) {
				toast(<TutorialNotification />, {
					toastId: "tutorial",
					position: "top-right",
					autoClose: false,
					hideProgressBar: true,
					closeOnClick: true,
					draggable: true,
					progress: undefined,
					closeButton: false,
				})
				useLocalStore.setState({ visitedSpaces: [spaceId, ...visitedSpaces] })
			}
		},

		target: {
			id: undefined,
			mesh: undefined,
			type: Target.Owner,

			setTarget(mesh, type) {
				var { target } = get()
				target.mesh = mesh
				target.type = type
				target.id = undefined
				set({ target })
			},

			setTargetFromId(id) {
				var { target } = get()

				const user = useAvatarStore.getState().users[id]
				if (user) {
					target.mesh = user.mesh
					target.type = Target.User
					target.id = id
					set({ target })
				}
			},
		},

		floor: {
			bounds: [40, 40],
		},

		metaball: {
			radiusUser: 0.01,
			radiusTarget: 0.001,
		},

		actions: {
			updateFloorMesh(mesh) {
				var { floor } = get()
				floor.mesh = mesh
			},

			updateZoomLevel(delta) {
				const { zoomMultiplier, zoomBounds } = config.camera
				const { zoom } = get()

				const zoomLevel = zoom + delta * zoomMultiplier

				if (zoomBounds[0] <= zoomLevel && zoomLevel <= zoomBounds[1]) {
					set({ zoom: zoomLevel })
				}
			},
		},
	}
})
