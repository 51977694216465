import styled from "styled-components"

export const Container = styled.div`
	margin-bottom: -4px;
`

export const Icon = styled.div<{ border: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;

	background: #ffffff;
	box-shadow: ${(props) => (props.border ? "0px 0px 10px rgba(0, 0, 0, 0.08)" : "none")};
	border-radius: 10000px;
	border: 0;
	outline: 0;
	overflow: hidden;

	width: 34px;
	height: 34px;
`

export const Color = styled.div<{ color: string }>`
	background: ${(props) => (props.color ? props.color : "white")};

	width: 100%;
	height: 100%;
`

export const IconImage = styled.img.attrs({ draggable: false })``

export const TextContainer = styled.div`
	flex: 1;
	padding-right: 15px;
`

export const Divider = styled.hr`
	margin-top: 15px;
	padding-left: 20px;
	padding-left: 20px;
	border-top: 2px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
`
