import { useFrame, useThree } from "@react-three/fiber"
import { Speaker } from "@saysom/shared"
import { MutableRefObject, useEffect, useRef } from "react"
import { Frustum, Matrix4, Sphere, Vector3 } from "three"
import { config } from "../../config"
import { VideoQuality } from "../../entities/avatar/avatarTile"
import { Bot, Owner, useAvatarStore, User, UserBase } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { useInterfaceStore } from "../../stores/interfaceStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSpeakerStore } from "../../stores/speakerStore"
import { removeUndefined } from "../../utils/filterUndefined"

const VisibilityManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Visibility Manager Reload")

	const frustum = useRef(new Frustum())

	// Stores
	const groupId = useAvatarStore((state) => state.users["owner"]?.groupId)
	const updateIsVisible = useAvatarStore((state) => state.actions.updateIsVisible)
	const updateVideoQuality = useAvatarStore((state) => state.actions.updateVideoQuality)
	const updateVisibleUsersCount = useAvatarStore((state) => state.actions.updateVisibleUsersCount)
	const setScreenShareUsers = useInterfaceStore((state) => state.setScreenShareUsers)

	// Constants
	const maxVideoOnCount = config.video.maxVideoOnCount
	const maxVideoHighQualityCount = config.video.maxVideoHighQualityCount

	// Properties
	const visibleUsersCount: MutableRefObject<number> = useRef(0)
	useEffect(() => {
		useAvatarStore.subscribe((state) => (visibleUsersCount.current = state.visibleUsersCount))
	}, [])

	const users: MutableRefObject<Record<string, User | Owner | Bot>> = useRef({})
	useEffect(() => {
		useAvatarStore.subscribe((state) => (users.current = state.users))
	}, [])

	const speaker: MutableRefObject<Record<string, Speaker>> = useRef({})
	useEffect(() => {
		useSpeakerStore.subscribe((state) => (speaker.current = state.speaker))
	}, [])

	const { camera } = useThree()

	useFrame(() => {
		frustum.current.setFromProjectionMatrix(
			new Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
		)

		const screenshareUsers: string[] = []

		// Visibility
		if (!users?.current) return
		const cameraPosition = new Vector3(camera.position.x, 0, camera.position.z)
		const visibleUsers: string[] = Object.entries(users.current)
			.map(([id, user]): [number, string] | undefined => {
				if (!user?.mesh) return undefined
				if (!frustum.current.intersectsSphere(new Sphere(user.mesh.position, 0.5))) {
					if (user.isVisible) updateIsVisible(id, false)
					return undefined
				} else {
					const distance = cameraPosition.distanceTo(user.mesh.position)
					if (!user.isVisible) updateIsVisible(id, true)
					return [distance, id]
				}
			})
			.filter(removeUndefined)
			.sort((a: [number, string], b: [number, string]) => a[0] - b[0])
			.map(([_distance, id]): string => {
				return id
			})

		// Count
		if (visibleUsersCount.current !== visibleUsers.length) {
			updateVisibleUsersCount(visibleUsers.length)
		}

		// Video Quality
		let usersVideoQualityHighCount = 0
		let usersVideoQualityLowCount = 0

		// Megaphone
		if (speaker?.current) {
			Object.keys(speaker.current).forEach((speakerId) => {
				const user = users.current[speakerId]
				const index = visibleUsers.indexOf(speakerId)
				if (index >= 0) visibleUsers.splice(index, 1)

				if (user && !user.isBot) {
					if (usersVideoQualityHighCount < maxVideoHighQualityCount) {
						if ((user as Owner | User).media.videoQuality !== VideoQuality.High) {
							updateVideoQuality(speakerId, VideoQuality.High)
							usersVideoQualityHighCount += 1
						}
					} else if (usersVideoQualityLowCount < maxVideoOnCount) {
						if ((user as Owner | User).media.videoQuality !== VideoQuality.Low) {
							updateVideoQuality(speakerId, VideoQuality.Low)
							usersVideoQualityLowCount += 1
						}
					} else {
						if ((user as Owner | User).media.videoQuality !== VideoQuality.Off) {
							updateVideoQuality(speakerId, VideoQuality.Off)
						}
					}
				}
			})
		}

		// Group
		if (groupId) {
			;[...visibleUsers].forEach((id) => {
				const user = users.current[id]
				if (user && groupId === user.groupId && !user.isBot) {
					if ((user as UserBase).hasScreenshare) {
						screenshareUsers.push(user.id)
					}

					const index = visibleUsers.indexOf(id)
					if (index >= 0) visibleUsers.splice(index, 1)

					if (usersVideoQualityHighCount < maxVideoHighQualityCount) {
						if ((user as Owner | User).media.videoQuality !== VideoQuality.High) {
							updateVideoQuality(id, VideoQuality.High)
							usersVideoQualityHighCount += 1
						}
					} else if (usersVideoQualityLowCount < maxVideoOnCount) {
						if ((user as Owner | User).media.videoQuality !== VideoQuality.Low) {
							updateVideoQuality(id, VideoQuality.Low)
							usersVideoQualityLowCount += 1
						}
					} else {
						if ((user as Owner | User).media.videoQuality !== VideoQuality.Off) {
							updateVideoQuality(id, VideoQuality.Off)
						}
					}
				}
			})
		}

		visibleUsers.forEach((id) => {
			const user = users.current[id]
			if (user && id !== "owner" && !user.isBot) {
				if (id === "owner") return
				if (usersVideoQualityLowCount < maxVideoOnCount) {
					if ((user as Owner | User).media.videoQuality !== VideoQuality.Low) {
						updateVideoQuality(id, VideoQuality.Low)
						usersVideoQualityLowCount += 1
					}
				} else {
					if ((user as Owner | User).media.videoQuality !== VideoQuality.Off) {
						updateVideoQuality(id, VideoQuality.Off)
					}
				}
			}
		})

		setScreenShareUsers(screenshareUsers)
	})

	return <group />
}

export default VisibilityManager
