import { AreaContentType, Speaker, SpeakerType } from "@saysom/shared"
import React, { useEffect, useRef } from "react"
import { useAreaStore } from "../../stores/areaStore"
import { Owner, useAvatarStore } from "../../stores/avatarStore"
import { useCommunicationStore } from "../../stores/communicationStore"
import { useDebugStore } from "../../stores/debugStore"
import { useInterfaceStore } from "../../stores/interfaceStore"
import { useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSocketStore } from "../../stores/socketStore"
import { useSpeakerStore } from "../../stores/speakerStore"
import InteractableIFrameView from "../interactableView/interactableIFrameView"
import InteractableSpeedDatingView from "../interactableView/interactableSpeedDatingView"
import InteractableView from "../interactableView/interactableView"
import InteractableYoutubeView from "../interactableView/interactableYoutubeView"
import { Container } from "./interactablesList_style"

const InteractablesList = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Interactable List Reload")

	const spaceId = useSocketStore((state) => state.spaceId)

	const ownerAreaId = useAvatarStore((state) => (state.users["owner"] as Owner)?.areaId)
	const ownerAreaContentType = useAreaStore((state) =>
		ownerAreaId ? state.areas[ownerAreaId]?.contentType : undefined
	)
	const ownerAreaUrl = useAreaStore((state) => (ownerAreaId ? state.areas[ownerAreaId]?.url : undefined))

	// Store
	const ownerId = useAvatarStore((state) => state.users["owner"]?.id)
	const screenShareSpeaker = useSpeakerStore((state) =>
		Object.values(state.speaker).filter((speaker) => {
			return speaker.speakerType === SpeakerType.ScreenShare
		})
	)
	const isInitial = useSceneStore((state) => state.isInitial)
	const screenShareUsers = useInterfaceStore(
		(state) => state.screenShareUsers,
		(oldValues, newValues) => {
			return oldValues.size === newValues.size ? true : oldValues === newValues
		}
	)
	const ownerHasScreenShare = useCommunicationStore((state) => state.participants[ownerId]?.screenVideoTrack)

	const screenShareSpeakerBefore = useRef<Speaker[]>([])

	useEffect(() => {
		if (screenShareSpeakerBefore.current.length > 0 && screenShareSpeaker.length === 0) {
			useInterfaceStore.setState({ isScreenshareFullscreen: false })
		}
		screenShareSpeakerBefore.current = screenShareSpeaker
	}, [screenShareSpeaker])

	return (
		<Container>
			{!isInitial && spaceId && spaceId === "f1U4xCKFK2" && <InteractableYoutubeView />}
			{screenShareSpeaker.map(
				(id) => ownerId !== undefined && <InteractableView key={`interactableview-${id.userId}`} id={id.userId} />
			)}
			{Array.from(screenShareUsers).map(
				(id) => ownerId !== undefined && <InteractableView key={`interactableview-${id}`} id={id} />
			)}
			{ownerHasScreenShare !== undefined && <InteractableView key={`interactableview-${ownerId}`} id={ownerId} />}
			{ownerAreaContentType === AreaContentType.Speeddating && <InteractableSpeedDatingView />}
			{ownerAreaUrl && <InteractableIFrameView url={ownerAreaUrl} />}
		</Container>
	)
}

export default InteractablesList
