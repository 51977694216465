import React, { useEffect, useState } from "react"
import { useSocketStore } from "../../stores/socketStore"
import { Container } from "./interactableView_style"

const InteractableSpeedDatingView = () => {
	const timeSyncer = useSocketStore((state) => state.timeSyncer)
	const [currentDate, setCurrentDate] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			if (timeSyncer && timeSyncer.serverTime) setCurrentDate(timeSyncer.serverTime % 1000)
		}, 200)

		return () => {
			clearInterval(interval)
		}
	}, [timeSyncer])

	return <Container style={{ marginBottom: "-4px" }}>{timeSyncer && <p> {currentDate} </p>}</Container>
}

export default InteractableSpeedDatingView
