import React, { useEffect, useRef, useState } from "react"
import ColorPicker from "./colorPicker"

interface ImageTileProps {
	color: string | undefined
	onChange: (color: string) => void
}

const ImageTile = ({ color, onChange }: ImageTileProps) => {
	const [isPickerVisible, setIsPickerVisible] = useState(false)
	const picker = useRef<HTMLDivElement>(null)

	const handleClickOutside = (e) => {
		if (picker.current && picker.current.contains(e.target)) {
			return
		}

		setIsPickerVisible(false)
	}

	useEffect(() => {
		if (isPickerVisible) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [isPickerVisible])

	return (
		<div className="relative cursor-pointer">
			<div
				style={{ background: color }}
				className="border border-black border-solid w-5 h-5 rounded-full"
				onClick={() => setIsPickerVisible((v) => !v)}
			/>

			{isPickerVisible && (
				<ColorPicker
					ref={picker}
					color={color ?? "white"}
					onClose={() => setIsPickerVisible(false)}
					onPickColor={onChange}
				/>
			)}
		</div>
	)
}

export default ImageTile
