import React, { memo, useEffect, useState } from "react"
import { config, customSpaceForSpaceCode } from "../../config"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSpaceStore } from "../../stores/spaceStore"
import Emoji from "../../utils/emoji"
import { CircleOverlay } from "./avatar_style"

interface AvatarOverlayProps {
	id: string
	avatarImageUrl?: string
}

const AvatarOverlay = memo<AvatarOverlayProps>(({ id, avatarImageUrl = undefined }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Avatar Overlay Reload")

	// Stores
	const noCameraEmojis = config.avatar.noCameraEmojis
	const space = useSpaceStore((state) => state.space)

	// Store
	useEffect(() => {
		if (space && space.code) {
			const customSpace = customSpaceForSpaceCode(space.code)
			if (customSpace?.noCameraEmojis) {
				setImageUrl(customSpace.noCameraEmojis[id.charCodeAt(0) % customSpace.noCameraEmojis.length])
			}
		}
	}, [space, id])

	// State
	const [imageUrl, setImageUrl] = useState(avatarImageUrl)
	const emojiString = noCameraEmojis[id.charCodeAt(0) % noCameraEmojis.length]

	return (
		<CircleOverlay style={{ backgroundImage: imageUrl && `url(${imageUrl})`, willChange: "transform" }}>
			{!imageUrl && <Emoji size={50} symbol={emojiString} />}
		</CircleOverlay>
	)
})

export default AvatarOverlay
