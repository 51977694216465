import { ChatReceiver } from "@saysom/shared"
import Bell from "../../assets/images/bell.svg"
import Chat from "../../assets/images/chat.svg"
import Users from "../../assets/images/users.svg"
import { H4 } from "../../assets/style"
import { useChatStore } from "../../stores/chatStore"
import { useInterfaceStore } from "../../stores/interfaceStore"
import { PanelType } from "../interface/interface"

// closeToast = false
interface ChatNotificationProps {
	closeToast?: () => void
	message: string
	authorId?: string
	receiverId?: string
	name?: string
	timestamp?: number
	receiverType: ChatReceiver
}

const ChatNotification = ({ closeToast, authorId, receiverId, message, name, receiverType }: ChatNotificationProps) => {
	// Stores
	const updateSelectedPanel = useInterfaceStore((state) => state.updateSelectedPanel)
	const updateOpenChatId = useChatStore((state) => state.updateOpenChatId)
	const updateIsGroupChat = useChatStore((state) => state.updateIsGroupChat)

	const handleClick = () => {
		switch (receiverType) {
			case ChatReceiver.All:
				updateIsGroupChat(false)
				updateOpenChatId("all")
				updateSelectedPanel(PanelType.Announcements)
				break
			case ChatReceiver.User:
				if (authorId) {
					updateIsGroupChat(false)
					updateOpenChatId(authorId)
					updateSelectedPanel(PanelType.Chat)
					if (closeToast) closeToast()
				}
				break
			case ChatReceiver.Group:
				if (receiverId) {
					updateIsGroupChat(true)
					updateOpenChatId(receiverId)
					updateSelectedPanel(PanelType.Chat)
					if (closeToast) closeToast()
				}
				break
		}
	}

	return (
		<div className="flex flex-row p-2 space-x-3 items-start" onClick={() => handleClick()}>
			{receiverType === ChatReceiver.All && (
				<div
					className="flex h-12 w-8  items-center justify-center rounded-lg"
					style={{ background: "rgba(0,0,0, 0.1)" }}
				>
					<img alt="" className="w-5" src={Bell} />
				</div>
			)}
			<div className="flex flex-col justify-center space-y-2" onClick={() => handleClick()}>
				<div className="flex flex-row items-center space-x-2">
					{receiverType !== ChatReceiver.All && (
						<img alt="" className=" w-3.5" src={receiverType === ChatReceiver.User ? Chat : Users} />
					)}
					<H4> {name} </H4>
				</div>
				<p className="line-clamp-2 text-justify"> {message} </p>
			</div>
		</div>
	)
}

export default ChatNotification
