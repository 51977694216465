import { FullGestureState } from "react-use-gesture/dist/types"
import { create } from "zustand"
import { useSceneStore } from "./sceneStore"

type InputStore = {
	window: {
		focus: boolean
		inputFocus: boolean
	}

	mouse: {
		pos: number[]
		pressed: boolean
	}
	keyboard: {
		keysDown: Set<string>
	}

	actions: {
		updateMouseDown: (down: FullGestureState<"drag">) => void
		updateMouseUp: (down: FullGestureState<"drag">) => void
		updateMouseMove: (state: FullGestureState<"wheel">) => void
		updateWheel: (state: FullGestureState<"move">) => void
		updatePinch: (state: FullGestureState<"pinch">) => void
		updateKeydown: (event: KeyboardEvent) => void
		updateKeyup: (event: KeyboardEvent) => void
		updateInputFocus: (focus: boolean) => void
		updateFocus: (focus: boolean) => void
	}

	resetStore: () => void
}

export declare type Vector2 = [number, number]

export const useInputStore = create<InputStore>((set, get) => {
	return {
		window: {
			focus: true,
			inputFocus: false,
		},

		mouse: {
			pos: [0, 0],
			pressed: false,
		},
		keyboard: {
			keysDown: new Set(),
		},

		resetStore() {
			set({
				window: {
					focus: true,
					inputFocus: false,
				},

				mouse: {
					pos: [0, 0],
					pressed: false,
				},
				keyboard: {
					keysDown: new Set(),
				},
			})
		},

		actions: {
			updateMouseDown(state: FullGestureState<"drag">) {
				var { mouse } = get()

				if (state.event) {
					const target = (state.event as PointerEvent).target

					// FIXME:
					if (target) {
						mouse.pressed = state.down && ((target as HTMLElement).tagName === "CANVAS" || mouse.pressed)
						// ((target as HTMLElement).children[0] as HTMLElement).style.pointerEvents === "none")
					}
				}
			},
			updateMouseUp(_state: FullGestureState<"drag">) {
				var { mouse } = get()

				mouse.pressed = false
			},
			updateMouseMove(state: FullGestureState<"move">) {
				var { mouse } = get()

				// Position
				mouse.pos[0] = (state.xy[0] / window.innerWidth) * 2 - 1
				mouse.pos[1] = -(state.xy[1] / window.innerHeight) * 2 + 1
			},
			updateWheel(state: FullGestureState<"wheel">) {
				const actions = useSceneStore.getState().actions

				if (state.event) {
					const target = (state.event as PointerEvent).target

					if (target && (target as HTMLElement).tagName === "CANVAS") {
						actions.updateZoomLevel(-state.delta[1] / 100)
					}
				}
			},
			updatePinch(state: FullGestureState<"pinch">) {
				console.log("start")
				console.log(state)
			},
			updateKeydown(event: KeyboardEvent) {
				var { keyboard, window } = get()
				if (window.inputFocus) return
				keyboard.keysDown.add(event.key.toLowerCase())
			},
			updateKeyup(event: KeyboardEvent) {
				var { keyboard } = get()
				keyboard.keysDown.delete(event.key.toLowerCase())
			},
			updateInputFocus(focus: boolean) {
				var { window } = get()
				window.inputFocus = focus
			},
			updateFocus(focus: boolean) {
				var { window, keyboard, mouse } = get()
				window.focus = focus

				if (!focus) {
					keyboard.keysDown = new Set()
					set({ keyboard })
					mouse.pressed = false
				}
			},
		},
	}
})
