import { Route, Router, Switch } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import "react-tooltip/dist/react-tooltip.css"
import SubspaceOverview from "./components/subspaceOverview/subspaceOverview"
import { customSpaces } from "./config"
import "./index.css"
import Space from "./routes/[spaceId]"
import Feedback from "./routes/[spaceId]/feedback"
import Join from "./routes/[spaceId]/join"
import Over from "./routes/[spaceId]/over"
import { useAuthStore } from "./stores/authStore"
import { history } from "./utils/History"

const App = () => {
	// FIXME: working?
	const _token = useAuthStore((state) => state.token)

	return (
		<div>
			<Router history={history}>
				<Switch>
					{customSpaces
						.filter((customSpace) => customSpace.rooms.length > 1 && customSpace.customUrl !== undefined)
						.map(({ title, rooms, customUrl, colors }) => {
							return (
								<Route
									path={customUrl}
									exact
									component={() => (
										<SubspaceOverview title={title} spaces={rooms} url={customUrl as string} colors={colors} />
									)}
								/>
							)
						})}

					<Route path="/:spaceId/join" exact component={Join} />
					<Route path="/:spaceId/feedback" exact component={Feedback} />
					<Route path="/:spaceId/over" exact component={Over} />
					<Route path="/:spaceId" exact component={Space} />
				</Switch>
			</Router>
		</div>
	)
}

export default App
