import { Shadows, VerticalGroup } from "@saysom/shared"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import useSWR from "swr"
import { H2, H3, Label, P } from "../../assets/style"
import { fetchApi } from "../../network/fetch"
import { Space, SpaceIdParams } from "../../routes/[spaceId]/join"
import { useSettingsStore } from "../../stores/settingsStore"
import ContinueButton from "../button/continueButton"
import CameraPreview from "../cameraPreview/cameraPreview"
import { Option, Select } from "../settings/settings_style"
import { CameraContainer, Container, InnerContainer, InputContainer, InputGroup, Mobile } from "./welcome_style"

interface WelcomeProps {
	hasPermissions: boolean
	onJoin: () => void
}

const Welcome = ({ hasPermissions, onJoin }: WelcomeProps) => {
	const { spaceId } = useParams<SpaceIdParams>()
	const { data: space } = useSWR<Space | undefined>(`/spaces/${spaceId}`, fetchApi)
	const history = useHistory()

	const microphones = useSettingsStore((state) => state.microphones)
	const speakers = useSettingsStore((state) => state.speakers)
	const cameras = useSettingsStore((state) => state.cameras)

	const selectedMicrophone = useSettingsStore((state) => state.selectedMicrophone)
	const selectedSpeaker = useSettingsStore((state) => state.selectedSpeaker)
	const selectedCamera = useSettingsStore((state) => state.selectedCamera)

	const setMicrophone = useSettingsStore((state) => state.updateMicrophone)
	const setSpeaker = useSettingsStore((state) => state.updateSpeaker)
	const setCamera = useSettingsStore((state) => state.updateCamera)

	useEffect(() => {
		console.log("space", space)
		if (space?.isOver) {
			console.log("space is over")
			history.push(`/${spaceId}/over`)
		}
	}, [space])

	return (
		<Container>
			<Mobile>
				{/* // TODO: welcome to {space}, {name} */}

				<InnerContainer alignItems="center" spacing={40}>
					<VerticalGroup alignItems="center" spacing={10}>
						<H2>{space ? `Welcome to ${space.title}` : "Loading"}</H2>

						<P>Before joining, select your microphone and camera</P>
					</VerticalGroup>

					<CameraContainer>{hasPermissions && <CameraPreview />}</CameraContainer>

					<InputContainer spacing={10} alignItems="stretch">
						<InputGroup>
							<Label style={{ width: "100px", margin: 0 }}>Microphone</Label>
							<Select
								value={selectedMicrophone}
								onChange={({ target: { value } }) => setMicrophone(value)}
								style={{ boxShadow: Shadows.highlighted }}
							>
								{microphones.map((v) => (
									<Option value={v.deviceId} key={v.deviceId}>
										{v.label}
									</Option>
								))}
							</Select>
						</InputGroup>

						<InputGroup>
							<Label style={{ width: "100px", margin: 0 }}>Speaker</Label>
							{speakers.length > 0 ? (
								<Select
									value={selectedSpeaker}
									onChange={({ target: { value } }) => setSpeaker(value)}
									style={{ boxShadow: Shadows.highlighted }}
								>
									{speakers.map((v) => (
										<Option value={v.deviceId} key={v.deviceId}>
											{v.label}
										</Option>
									))}
								</Select>
							) : (
								<P>Change your speaker in your device settings</P>
							)}
						</InputGroup>

						<InputGroup>
							<Label style={{ width: "100px", margin: 0 }}>Camera</Label>
							<Select
								value={selectedCamera}
								onChange={({ target: { value } }) => setCamera(value)}
								style={{ boxShadow: Shadows.highlighted }}
							>
								{cameras.map((v) => (
									<Option value={v.deviceId} key={v.deviceId}>
										{v.label}
									</Option>
								))}
							</Select>
						</InputGroup>
					</InputContainer>

					<VerticalGroup>
						<H3>Tips:</H3>
						<P>Wear headphones</P>
						<P>Move around using the mouse or arrow keys</P>
						<P>Talk to new people!</P>
					</VerticalGroup>

					<ContinueButton onClick={onJoin} text="Join" className="text-lg" isBlack />
				</InnerContainer>
			</Mobile>
		</Container>
	)
}

export default Welcome
