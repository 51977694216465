import { extend } from "@react-three/fiber"
import * as THREE from "three"

const vertexShaderText = `
	varying vec2 vUv;
	varying vec4 pos; 
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
		pos = gl_Position; 
    }`

const fragmentShaderText = `
	precision highp float;
	varying vec2 vUv;

    void main() {
		gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
    }`

class EmptyMaterial extends THREE.ShaderMaterial {
	constructor() {
		super({
			vertexShader: vertexShaderText,
			fragmentShader: fragmentShaderText,
			transparent: true,
		})
	}
}

extend({ EmptyMaterial })
