import { extend } from "@react-three/fiber"
import { ShaderMaterial } from "three"

const vertexShaderText = /* glsl */ `
	varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`

const fragmentShaderText = /* glsl */ `
	//precision highp float;
	varying vec2 vUv;
	uniform float time; 
	uniform float on; 

    float rand(vec2 seed) {
    	return fract(sin(dot(seed, vec2(12.9898, 78.233))) * 43758.5453);
	}
 	

    void main() {
		float width = float(WIDTH);
		float height = float(HEIGHT);

		vec2 uv = vUv  * vec2(width, height);
		vec2 normUV = vec2(mod(floor(uv.x), width) / width , mod(floor(uv.y), height) / height);
		vec2 uvGrid = vec2(mod(uv.x, 1.0), mod(uv.y, 1.0));

		float barPadding = 0.02; 	

		vec4 col1 = vec4(0.980392, 0.545098, 1.0, 1.0);
		vec4 col2 = vec4(0.407843, 0.403922, 1.0 , 1.0);
		vec4 gradient = mix(col1, col2, (uvGrid.x + uvGrid.y) / 2.0);

		bool bars = uvGrid.x < barPadding || (1.0 - barPadding) <  uvGrid.x || uvGrid.y < barPadding || (1.0 - barPadding) <  uvGrid.y;
		bars = bars && barPadding < uv.x && uv.x < (width - barPadding);
		bars = bars && barPadding < uv.y && uv.y < (height - barPadding);
		
		float t = time * 0.00000023;
		vec4 color = vec4(0.0, 0.0, 0.0, 0.0);
		
		

		if(on > 0.0) {
			color = vec4(rand(normUV + t), rand(normUV + t + 2.0), rand(normUV + t  - 1.0), 1.0);
		} else {
			color = vec4(0.0, 0.0, 0.0, 0.5);
		}


		if(bars ) {
			gl_FragColor = vec4(1.0, 1.0, 1.0, 0.0);
		} else {
			gl_FragColor = color; 

		}



    }`

class DiscoMaterial extends ShaderMaterial {
	constructor(width: number, height: number) {
		super({
			defines: {
				WIDTH: width ? width : 0,
				HEIGHT: height ? height : 0,
			},
			uniforms: {
				time: {
					value: 0,
				},
				on: {
					value: 0,
				},
			},
			vertexShader: vertexShaderText,
			fragmentShader: fragmentShaderText,
			transparent: true,
		})
	}

	set time(v: number) {
		this.uniforms.time.value = v
	}
	set on(v: number) {
		this.uniforms.on.value = v
	}
}

extend({ DiscoMaterial })
