import { useFrame } from "@react-three/fiber"
import { MutableRefObject, useRef } from "react"
import { Camera, Mesh, OrthographicCamera, Raycaster, Vector2, Vector3 } from "three"
import { useDebugStore } from "../../stores/debugStore"
import { useInputStore } from "../../stores/inputStore"
import { Target, useSceneStore } from "../../stores/sceneStore"
import { useSettingsStore } from "../../stores/settingsStore"

const DragManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Owner Movement Manager Reload")

	// Stores
	const keysDown = useInputStore((state) => state.keyboard.keysDown)
	const mesh = useSceneStore((state) => state.floor.mesh)
	const layerMask = useSceneStore((state) => state.layerMask)
	const setTarget = useSceneStore((state) => state.target.setTarget)
	const meshTarget = useSceneStore((state) => state.target.mesh)
	const mouseIS = useInputStore((state) => state.mouse)

	// Ref
	const ref = useRef<Mesh>(null)
	const clonedCamera: MutableRefObject<Camera | undefined> = useRef()
	const draggingMode = useRef(false)
	const dragging = useRef(false)
	const draggingStartPosition = useRef(new Vector3(0, 0, 0))
	const draggingStartMousePosition = useRef(new Vector3(0, 0, 0))

	const dragModeStart = () => {
		draggingMode.current = true
		// @ts-ignore

		if (!(ref && ref.current && meshTarget)) return
		setTarget(ref.current, Target.Object)
		const pos = meshTarget.position
		ref.current.position.set(pos.x, pos.y, pos.z)
	}

	const dragModeEnd = () => {
		draggingMode.current = false
		setTarget(undefined, Target.Object)
	}

	const dragStart = (camera: Camera, pos: Vector3) => {
		dragging.current = true

		const ortho = camera as OrthographicCamera
		clonedCamera.current = new OrthographicCamera(0, 0, 0, 0)
		clonedCamera.current.copy(ortho)

		if (!(ref && ref.current)) return
		draggingStartPosition.current = ref.current.position.clone()
		draggingStartMousePosition.current = pos
	}

	const dragEnd = () => {
		dragging.current = false
		clonedCamera.current = undefined
	}

	const updateDrag = (camera, mouse) => {
		if (!(ref && ref.current)) return

		if (keysDown.has(" ")) {
			if (!draggingMode.current) {
				dragModeStart()
			}
		} else {
			if (draggingMode.current) {
				dragModeEnd()
			}
		}

		if (!draggingMode.current) return

		if (!mesh) return

		if (!mouseIS.pressed) {
			if (dragging.current) {
				dragEnd()
			}
		}

		let rayCamera = camera
		if (clonedCamera && clonedCamera.current) {
			//@ts-ignore
			rayCamera = clonedCamera.current
		}

		const mouseX = mouse.x
		const mouseY = mouse.y

		var raycaster = new Raycaster()
		raycaster.layers.set(layerMask)
		const mouseVector = new Vector2(mouseX, mouseY)
		raycaster.setFromCamera(mouseVector, rayCamera)

		var intersects = raycaster.intersectObject(mesh, false)

		const intesectedObj = intersects[0]
		if (intesectedObj) {
			if (mouseIS.pressed) {
				if (!dragging.current) {
					dragStart(camera, intesectedObj.point)
				}
			}

			if (!dragging.current) return

			const dir = new Vector3(
				intesectedObj.point.x - draggingStartMousePosition.current.x,
				intesectedObj.point.y - draggingStartMousePosition.current.y,
				intesectedObj.point.z - draggingStartMousePosition.current.z
			)

			const pos = new Vector3(draggingStartPosition.current.x - dir.x, 0.01, draggingStartPosition.current.z - dir.z)
			ref.current.position.set(pos.x, pos.y, pos.z)
		}
	}

	useFrame(({ camera, mouse }) => {
		updateDrag(camera, mouse)
	})

	return <mesh ref={ref}>{/* <sphereGeometry />
			<meshBasicMaterial color={"green"} /> */}</mesh>
}

export default DragManager
