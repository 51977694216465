import { HorizontalGroup } from "@saysom/shared"
import React, { useEffect, useRef, useState } from "react"

const Star = ({ selected = false, color = "#F189FF" }) => {
	return (
		<svg
			width="40"
			height="39"
			viewBox="0 0 40 39"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ cursor: "pointer" }}
		>
			<path
				d="M20.9446 1.7631L24.4763 11.9236C24.8883 13.1088 25.9944 13.9124 27.2489 13.938L38.0035 14.1571C38.9514 14.1765 39.3428 15.381 38.5873 15.9538L30.0154 22.4525C29.0155 23.2106 28.5931 24.5108 28.9564 25.7118L32.0713 36.0078C32.3459 36.9153 31.3212 37.6597 30.543 37.1182L21.7135 30.9741C20.6836 30.2574 19.3164 30.2574 18.2865 30.9741L9.457 37.1182C8.67876 37.6597 7.65411 36.9153 7.92867 36.0078L11.0436 25.7118C11.4069 24.5108 10.9844 23.2106 9.98455 22.4525L1.41274 15.9538C0.657207 15.381 1.04858 14.1765 1.99651 14.1571L12.7511 13.938C14.0056 13.9124 15.1117 13.1088 15.5237 11.9236L19.0554 1.7631C19.3667 0.867543 20.6333 0.867541 20.9446 1.7631Z"
				fill={selected ? color : ""}
				stroke={color}
				strokeWidth="2"
			/>
		</svg>
	)
}

const Stars = ({ onChange }) => {
	const [stars, setStars] = useState(-1)

	const container = useRef<HTMLDivElement>(null)

	useEffect(() => {
		onChange(stars)
	}, [stars, onChange])

	return (
		<HorizontalGroup
			ref={container}
			spacing={5}
			style={{ cursor: "pointer" }}
			onMouseMove={(event) => {
				if (!container.current) return

				const rect = container.current?.getBoundingClientRect()
				const x = event.clientX - rect.left

				const newStars = Math.round((x / rect.width) * 4)
				if (stars !== newStars) {
					setStars(newStars)
				}
			}}
		>
			<Star color="#F189FF" selected={stars >= 0} />
			<Star color="#D181FF" selected={stars > 0} />
			<Star color="#B47AFF" selected={stars > 1} />
			<Star color="#9472FF" selected={stars > 2} />
			<Star color="#786BFF" selected={stars === 4} />
		</HorizontalGroup>
	)
}

export default Stars
