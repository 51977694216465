import React, { useEffect, useMemo, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { customSpaceForSpaceCode } from "../../config"
import SceneManager from "../../manager/sceneManager/sceneManager"
import { fetchApi } from "../../network/fetch"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSocketStore } from "../../stores/socketStore"
import { useSpaceStore } from "../../stores/spaceStore"
import { Container } from "./index_style.js"
import { SpaceIdParams } from "./join"

const Space = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Space Reload")

	const { spaceId } = useParams<SpaceIdParams>()

	const history = useHistory()

	const [isLoading, setIsLoading] = useState(true)
	const [spaceData, setSpaceData] = useState<Record<string, unknown> | undefined>(undefined)
	const roomColor = useSpaceStore((state) => state.space?.colorScheme?.roomColor)

	const { search } = useLocation()

	// TODO: leave never called
	const leave = useSocketStore((state) => state.leave)

	useEffect(() => {
		const onUnLoad = () => {
			leave()
		}

		window.addEventListener("beforeunload", onUnLoad)

		return () => window.removeEventListener("beforeunload", onUnLoad)
	}, [leave])

	useEffect(() => {
		useSocketStore.setState({ spaceId })
	}, [spaceId])

	useEffect(() => {
		if (spaceId === undefined) window.location.href = "https://saysom.app/404"

		const join = () => {
			// TODO: redirect to ticket page?
			history.push(`/${spaceId}/join${search}`)
		}

		// TODO: refactor
		fetchApi(`/spaces/${spaceId}`)
			.then((space) => {
				if (!space) {
					window.location.href = "https://saysom.app/404"
				} else {
					setSpaceData(space)

					const now = new Date().getTime() / 1000 + 60

					const _authString = localStorage.getItem("auth")
					let _auth: Record<string, Record<string, string | number> | undefined> = {}
					if (_authString) {
						_auth = JSON.parse(_authString)
					}

					const _token = _auth.state?.token
					const _expiresIn = _auth.state?.expiresIn

					if (_token !== undefined && _expiresIn !== undefined) {
						if (_expiresIn > now) {
							// all good
							if (search) {
								history.push(`/${spaceId}`)
							} else {
								setIsLoading(false)
							}
						} else {
							join()
						}
					} else {
						join()
					}
				}
			})
			.catch((error) => {
				console.error(error)

				//window.location.href = "https://saysom.app/404"
			})
	}, [spaceId, history, search])

	useEffect(() => {
		document.body.style.overflow = "hidden"

		return () => {
			document.body.style.overflow = "initial"
		}
	}, [])

	const showIframeWarning = useMemo(() => {
		const customSpace = customSpaceForSpaceCode(spaceId)
		if (customSpace) {
			return false
		}

		try {
			return window.self !== window.top
		} catch (e) {
			return false
		}
	}, [spaceId])

	console.log(showIframeWarning)

	return isLoading ? (
		<div>Loading…</div>
	) : showIframeWarning ? (
		<div style={{ display: "flex", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center" }}>
			Space was not unlocked for integration in an iFrame, please contact support.
		</div>
	) : (
		// @ts-ignore
		<Container background={roomColor ? `linear-gradient(135deg, ${roomColor[0]}, ${roomColor[1]});` : "none"}>
			<SceneManager />
		</Container>
	)
}

export default Space
