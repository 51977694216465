import { HorizontalGroup, VerticalGroup } from "@saysom/shared"
import { animated } from "react-spring"
import styled from "styled-components"

export const Header = styled(HorizontalGroup)`
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	width: 960px;

	@media (max-width: 960px) {
		width: initial;
		align-self: stretch;
	}
`

export const ImageContainer = styled(animated.div)`
	position: relative;
	width: 100%;
	border-radius: 10px;

	cursor: auto;
	z-index: -1;
	box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
	transition: box-shadow 0.5s;

	&:hover::before {
		content: "";
		position: absolute;
		top: -6px;
		left: -6px;
		right: -6px;
		bottom: -6px;
		background: linear-gradient(45deg, #fa8bff 0%, #6867ff 100%);
		z-index: -1;
		border-radius: 14px;
		box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
	}

	@media (max-width: 500px) {
		&::before {
			content: "";
			position: absolute;
			top: -6px;
			left: -6px;
			right: -6px;
			bottom: -6px;
			background: linear-gradient(45deg, #fa8bff 0%, #6867ff 100%);
			z-index: -1;
			border-radius: 10px;
		}
	}
`

export const FlexDirectionSwitcher = styled(VerticalGroup)`
	position: relative;
	right: 90px;
	width: 970px;

	flex-direction: row !important;

	@media (max-width: 1060px) {
		width: initial;
		right: 0;
	}

	@media (max-width: 700px) {
		flex-direction: column !important;
	}
`

export const WhiteContainer = styled(VerticalGroup)`
	padding: 50px 40px;
	background: rgba(255, 255, 255, 0.8);
	border-radius: 30px;
	width: 960px;
	justify-content: center;

	@media (max-width: 960px) {
		width: initial;
		align-self: stretch;
		margin: 0 10px;

		padding: 30px 20px;
	}
`

export const LeftPlane = styled.div`
	display: flex;
	flex: 1.4;
	align-self: flex-start;

	border-radius: 20px;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
	margin-right: 25px;

	position: relative;

	@media (max-width: 700px) {
		margin-right: 0;
		margin-bottom: 20px;
		align-self: stretch;
	}

	&::before {
		display: block;
		content: "";
		padding: 65.25% 0 0 0;
	}
`

export const Video = styled.iframe`
	position: absolute;
	border-radius: 15px;
	overflow: hidden;
	width: calc(100% - 30px);
	height: calc(100% - 30px);

	top: 15px;
	left: 15px;

	border: 1px solid white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
`
