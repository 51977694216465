import { Button, HorizontalGroup } from "@saysom/shared"
import { Properties } from "csstype"
import { memo } from "react"
import Delete from "../../assets/images/delete.svg"
import Edit from "../../assets/images/edit.svg"
import { P } from "../../assets/style"
import { useAreaStore } from "../../stores/areaStore"

interface AreaListElementProps {
	id: string
	style?: Properties
	alternateRow: boolean

	onSelectedAreaChange?: (areaId: string) => void
}

const AreaListElement = memo<AreaListElementProps>(({ id, alternateRow, style, onSelectedAreaChange }) => {
	// Stores
	const position = useAreaStore((state) => state.areas[id]?.position)
	const size = useAreaStore((state) => state.areas[id]?.size)
	const imageUrl = useAreaStore((state) => state.areas[id]?.imageUrl)
	const iconUrl = useAreaStore((state) => state.areas[id]?.iconUrl)
	const title = useAreaStore((state) => state.areas[id]?.title)
	const deleteArea = useAreaStore((state) => state.requestArea.delete)

	return (
		<HorizontalGroup
			justifyContent="space-between"
			alignItems="center"
			style={{
				background: alternateRow ? "#f7f7f7" : "white",
				padding: "10px",
				borderBottom: `1px solid ${alternateRow ? "white" : "#f3f3f3"}`,
				...style,
			}}
		>
			<P style={{ flex: 1 }}>{title}</P>

			{onSelectedAreaChange && <Button image={Edit} onClick={() => onSelectedAreaChange(id)} />}
			{deleteArea && <Button image={Delete} onClick={() => deleteArea(id)} />}
		</HorizontalGroup>
	)
})

export default AreaListElement
