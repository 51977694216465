import { Vector2 } from "three"

export const centroid = (positions: (Vector2 | undefined)[]): Vector2 => {
	let centroidCount = 0
	const centroidPos = new Vector2(0, 0)

	positions.forEach((position) => {
		if (!position) return
		centroidPos.add(position)
		centroidCount += 1
	})

	centroidPos.multiplyScalar(1 / centroidCount)
	return centroidPos
}
