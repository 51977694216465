import { Colors } from "@saysom/shared"
import styled from "styled-components"

export const Container = styled.div<{ isDragging: boolean }>`
	width: 54px;
	height: 54px;
	background-color: white;
	position: relative;
	overflow: hidden;
	border: ${(props) => (props.isDragging ? `2px dotted ${Colors.action}` : "1px solid #efefef")};
`

export const Preview = styled.img.attrs({ draggable: false })`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	width: 100%;
	height: 100%;

	pointer-events: none;
	object-fit: contain;
`

export const Select = styled.div<{ opacity: number }>`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	z-index: 2;
	transition: opacity 100ms ease-in-out;
	opacity: ${(props) => props.opacity};

	display: flex;
	align-items: center;
	justify-content: center;
`

export const FileInput = styled.input`
	display: none;
`
