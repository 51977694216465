import { Button, ModeratorType, VerticalContainer } from "@saysom/shared"
import { useMemo } from "react"
import Bell from "../../assets/images/bell.svg"
import Edit from "../../assets/images/edit.svg"
import Settings from "../../assets/images/settings.svg"
import Spaces from "../../assets/images/spaces.svg"
import Users from "../../assets/images/users.svg"
import { customSpaceForSpaceCode } from "../../config"
import { useSpaceStore } from "../../stores/spaceStore"
import { PanelType } from "../interface/interface"

interface PanelControlBarProps {
	moderatorType: ModeratorType
	selectedPanel: PanelType
	onSelectedPanelChange: (panelType: PanelType) => void
}

const PanelControlBar = ({
	moderatorType = ModeratorType.None,
	selectedPanel,
	onSelectedPanelChange,
}: PanelControlBarProps) => {
	const space = useSpaceStore((state) => state.space)

	const hasSubspaces = useMemo(() => {
		if (space && space?.code) {
			const customSpace = customSpaceForSpaceCode(space.code)
			if (customSpace && customSpace.rooms.length > 1) {
				return true
			}
		}

		return false
	}, [space])

	return (
		<VerticalContainer spacing={10}>
			<Button
				image={Users}
				onClick={() => onSelectedPanelChange(PanelType.Participants)}
				active={selectedPanel === PanelType.Participants}
			/>
			<Button
				image={Bell}
				onClick={() => onSelectedPanelChange(PanelType.Announcements)}
				active={selectedPanel === PanelType.Announcements}
			/>
			{moderatorType === ModeratorType.Admin && (
				<Button
					image={Edit}
					onClick={() => onSelectedPanelChange(PanelType.Space)}
					active={selectedPanel === PanelType.Space}
				/>
			)}
			{/* {isAdmin && (
				<Button
					image={Bots}
					onClick={() => onSelectedPanelChange(PanelType.Bots)}
					active={selectedPanel === PanelType.Bots}
				/>
			)} */}
			{hasSubspaces && (
				<Button
					image={Spaces}
					onClick={() => onSelectedPanelChange(PanelType.Spaces)}
					active={selectedPanel === PanelType.Spaces}
				/>
			)}
			<div
				style={{
					height: "1px",
					backgroundColor: "lightgrey",
					alignSelf: "stretch",
				}}
			/>
			<Button
				image={Settings}
				onClick={() => onSelectedPanelChange(PanelType.Settings)}
				active={selectedPanel === PanelType.Settings}
			/>
		</VerticalContainer>
	)
}

export default PanelControlBar
