export const getAwsUrlForKey = (key: string): string => {
	let _key = key
	if (process.env.NODE_ENV?.toLowerCase() === "development") {
		_key = `development/${key}`
	} else if (process.env.REACT_APP_STAGING === "1") {
		_key = `staging/${key}`
	}

	return "https://saysom.s3-eu-central-1.amazonaws.com/" + _key.replace(/ /g, "+")
}
