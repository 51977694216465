import { Spacer, VerticalGroup } from "@saysom/shared"
import React, { useEffect, useState } from "react"
import { Button, Flowy, FlowyWhite, H2, P } from "../../assets/style"
import SpaceJoiner from "../../components/spaceSelector/spaceJoiner"
import SpaceSelector from "../../components/spaceSelector/spaceSelector"
import { createToken } from "../../network/auth"
import { useLocalStore } from "../../stores/localStore"
import { useQuery } from "../../utils/UseQuery"
import { FlexDirectionSwitcher, Header, LeftPlane, Video, WhiteContainer } from "./subspaceOverview_style"

const SubspaceOverview = ({
	title,
	spaces,
	url,
	colors,
}: {
	title
	spaces: string[]
	url: string
	colors?: string[]
}) => {
	const [selectedRoom, setSelectedRoom] = useState<string>(spaces[Math.floor(Math.random() * spaces.length)])

	const query = useQuery()
	const updateLocalStore = useLocalStore((state) => state.update)

	useEffect(() => {
		const _spaceId = query.get("spaceId") as string
		if (_spaceId && _spaceId.length > 0 && _spaceId === undefined) {
			setSelectedRoom(_spaceId)
		}
	}, [query, selectedRoom])

	useEffect(() => {
		const name = query.get("name")
		const avatar = query.get("avatar") ?? undefined

		if (name) {
			const safeName = decodeURI(name)
			updateLocalStore(safeName, undefined, avatar)
			createToken(safeName, undefined, url)
		}
	}, [query, updateLocalStore, url])

	return (
		<VerticalGroup
			style={{
				background: colors
					? `linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 47.4%, rgba(255, 255, 255, 0) 100%), linear-gradient(58.22deg, ${colors[0]} 0%, ${colors[1]} 100%)`
					: FlowyWhite,
				minHeight: "100vh",
			}}
			alignItems="center"
			justifyContent="space-between"
		>
			<Header>
				<H2>
					SaySom <span style={{ fontWeight: 200 }}>Spaces</span>
				</H2>

				<Button style={{ display: "flex", alignItems: "center" }} onClick={() => window.open("https://saysom.app")}>
					<P>Learn More</P>
				</Button>
			</Header>

			<Spacer />

			<WhiteContainer spacing={25}>
				<SpaceJoiner spaceId={selectedRoom} eventName={title} />

				<FlexDirectionSwitcher>
					<LeftPlane
						style={{
							background: colors ? `linear-gradient(58.22deg, ${colors[0]} 0%, ${colors[1]} 100%)` : Flowy,
						}}
					>
						<Video
							src="https://www.youtube.com/embed/yXuBOhD3R-U"
							title="YouTube video player"
							allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
						/>
					</LeftPlane>

					<SpaceSelector
						spaces={spaces}
						onSelect={(id: string) => {
							setSelectedRoom(id)
						}}
					/>
				</FlexDirectionSwitcher>
			</WhiteContainer>

			<div
				style={{
					flex: 1.4,
					flexBasis: "10px",
				}}
			/>
		</VerticalGroup>
	)
}

export default SubspaceOverview
