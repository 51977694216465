import { useFrame } from "@react-three/fiber"
import { Speaker } from "@saysom/shared"
import { MutableRefObject, useEffect, useRef } from "react"
import { config } from "../../config"
import { Bot, Owner, useAvatarStore, User } from "../../stores/avatarStore"
import { useDebugStore } from "../../stores/debugStore"
import { Group, useGroupStore } from "../../stores/groupStore"
import { useSettingsStore } from "../../stores/settingsStore"
import { useSpeakerStore } from "../../stores/speakerStore"
import { volumeFalloff } from "../../utils/audio"

const AudioManager = () => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Audio Manager Reload")

	// Stores
	const ownerMesh = useAvatarStore((state) => state.users["owner"]?.mesh)
	const ownerGroupId = useAvatarStore((state) => state.users["owner"]?.groupId)
	const audioConfig = config.audio
	const connectedUser = useGroupStore((state) => state.connectedUser)

	const users: MutableRefObject<Record<string, User | Owner | Bot>> = useRef({})
	useEffect(() => {
		useAvatarStore.subscribe((state) => (users.current = state.users))
	}, [])

	const groups: MutableRefObject<Record<string, Group>> = useRef({})
	useEffect(() => {
		useGroupStore.subscribe((state) => (groups.current = state.groups))
	}, [])

	const speaker: MutableRefObject<Record<string, Speaker>> = useRef({})
	useEffect(() => {
		useSpeakerStore.subscribe((state) => (speaker.current = state.speaker))
	}, [])

	const updateUsersAudio = () => {
		if (!ownerMesh) return
		// Owner current position
		const ownerPos = ownerMesh.position
		const closestUser = connectedUser.closestUser

		if (!(closestUser && closestUser.mesh)) return

		// Closest user audio
		var closestUsePos = closestUser.mesh.position
		const closestUserDist = closestUsePos.distanceTo(ownerPos)
		var minGroupVolume = volumeFalloff(closestUserDist, audioConfig.maxHearDist, audioConfig.minFullHearDist)

		// Owner group
		const group = ownerGroupId ? groups.current[ownerGroupId] : undefined

		// Loop users
		// TODO: Refactor
		Object.entries(users.current).forEach(([id, user]) => {
			if (id === "owner") return
			const _user = user as User

			if (!(_user && _user.mesh && _user.audio)) return

			if (speaker && speaker.current && speaker.current[id] !== undefined) {
				_user.audio.volume = 1
			}

			if (ownerGroupId && ownerGroupId === _user.groupId) {
				// User current position
				const userPos = _user.mesh.position
				const dist = userPos.distanceTo(ownerPos)

				var volume = volumeFalloff(dist, audioConfig.maxHearDist, audioConfig.minFullHearDist)

				if (group && group.users.has(id)) {
					// TODO: For user leaving the group audio is false
					volume = minGroupVolume
				}

				_user.audio.volume = volume
			} else {
				_user.audio.volume = 0 // TODO: Handle iOS
			}
		})
	}

	useFrame(() => {
		// if (preferredIds.current) console.log(preferredIds.current)
		updateUsersAudio()
	})

	return <group />
}

export default AudioManager
