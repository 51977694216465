import { useFrame } from "@react-three/fiber"
import { memo, MutableRefObject, useEffect, useRef } from "react"
import { VideoQuality } from "../../entities/avatar/avatarTile"
import { Bot, useAvatarStore } from "../../stores/avatarStore"
import { useBotAnimationStore } from "../../stores/botAnimationStore"
import { useDebugStore } from "../../stores/debugStore"
import { useSettingsStore } from "../../stores/settingsStore"

interface BotActionManagerProps {
	id: string
}

const BotActionManager = memo<BotActionManagerProps>(({ id }) => {
	// Reloading
	const logs = useDebugStore((state) => state.logs)
	const debug = useSettingsStore((state) => state.debug)
	if (debug && logs.reload) console.log("Bot Movement Manager Reload")

	// Store
	const currentTime = useBotAnimationStore((state) => state.currentTime)
	const interpolateAction = useBotAnimationStore((state) => state.interpolateAction)
	const updateIsSignaling = useAvatarStore((state) => state.actions.updateIsSignaling)
	const updateVideoQuality = useAvatarStore((state) => state.actions.updateVideoQuality)
	const updateIsInPrivateGroup = useAvatarStore((state) => state.botActions.updateIsInPrivateGroup)
	const updateIsTalking = useAvatarStore((state) => state.botActions.updateIsTalking)
	const video = useAvatarStore((state) => (state.users[id] as Bot).video)

	// Refs
	const previousTime: MutableRefObject<number> = useRef(0)

	const bot: MutableRefObject<Bot | undefined> = useRef(undefined)
	useEffect(() => {
		useAvatarStore.subscribe((state) => (bot.current = state.users[id] as Bot))
	}, [id])

	const act = () => {
		if (bot.current) {
			const time = currentTime()
			const currentAction = interpolateAction(time, previousTime.current, bot.current.positions)

			if (currentAction) {
				updateVideoQuality(id, currentAction.isVideoBlurred ? VideoQuality.Low : VideoQuality.High)
				updateIsSignaling(id, currentAction.isSignaling)
				updateIsInPrivateGroup(id, currentAction.isInPrivateGroup)
				updateIsTalking(id, currentAction.isTalking)
			}
			if (previousTime.current > time && video) {
				video.currentTime = 0
				video.play()
			}

			previousTime.current = time
		}
	}

	useFrame(() => {
		act()
	})

	return <> </>
})

export default BotActionManager
