import { Speaker, SpeakerType, SpeakerUpdatDelete, SpeakerUpdateChange, SpeakerUpdateType } from "@saysom/shared"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { useAvatarStore } from "./avatarStore"
import { useNotificationStore } from "./notificationStore"
import { useSocketStore } from "./socketStore"

type UpdateSpeaker = {
	change: (speaker: Record<string, Speaker>) => void
	delete: (speakerId: string) => void
}

type RequestSpeaker = {
	change: (speakerId: string, speakerType: SpeakerType) => void
	delete: (speakerId: string) => void
}

type SpeakerStore = {
	speaker: Record<string, Speaker>

	update: UpdateSpeaker
	request: RequestSpeaker
}

export const useSpeakerStore = create(
	immer<SpeakerStore>((set, _get) => ({
		speaker: {},

		ownerIsMegaphoneSpeaker: false,
		ownerIsScreenShareSpeaker: false,

		update: {
			change(speaker) {
				set((state) => {
					Object.entries(speaker).forEach(([speakerId, speaker]) => {
						const owner = useAvatarStore.getState().users["owner"]
						const id = speakerId === owner?.id ? "owner" : speakerId

						if (!(state.speaker[id] && state.speaker[id].speakerType === speaker.speakerType)) {
							if (
								speaker.speakerType === SpeakerType.Megaphone &&
								state.speaker[id]?.speakerType !== SpeakerType.ScreenShare
							) {
								useNotificationStore.getState().onSpeaker(id)
							}
							state.speaker[id] = speaker
						}
					})
				})
			},

			delete(speakerId) {
				set((state) => {
					const owner = useAvatarStore.getState().users["owner"]
					const id = speakerId === owner?.id ? "owner" : speakerId

					if (state.speaker[id]) {
						delete state.speaker[id]
					}
				})
			},
		},

		request: {
			change(speakerId, speakerType) {
				let id = speakerId
				if (speakerId === "owner") {
					const ownerId = useAvatarStore.getState().users["owner"]?.id
					if (ownerId) id = ownerId
				}

				const speaker: Record<string, Speaker> = {}
				speaker[speakerId] = { userId: id, speakerType: speakerType }

				const speakerUpdateChange: SpeakerUpdateChange = {
					type: SpeakerUpdateType.Change,
					speaker: speaker,
				}

				useSocketStore.getState().emit.speaker(speakerUpdateChange)
			},
			delete(speakerId) {
				let id = speakerId
				if (speakerId === "owner") {
					const ownerId = useAvatarStore.getState().users["owner"]?.id
					if (ownerId) id = ownerId
				}

				const speakerUpdateDelete: SpeakerUpdatDelete = {
					type: SpeakerUpdateType.Delete,
					id: id,
				}

				useSocketStore.getState().emit.speaker(speakerUpdateDelete)
			},
		},
	}))
)
