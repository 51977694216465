import { HorizontalGroup } from "@saysom/shared"
import { useEffect, useState } from "react"
import { Flowy, P } from "../../assets/style"
import { fetchApiForUrl } from "../../network/fetch"
import { urlForSpaceId } from "../../utils/formatUrl"

interface SpaceItemProps {
	selected?: boolean
	onSelect?: () => void
	spaceId: string
	alternateRow?: boolean
}

const SpaceItem = ({ spaceId, selected = false, onSelect = undefined, alternateRow = false }: SpaceItemProps) => {
	const [onlineUsers, setOnlineUser] = useState<Record<string, unknown>[] | undefined>(undefined)
	const [title, setTitle] = useState<string>("")
	const [logo, setLogo] = useState<string | undefined>(undefined)

	const fetchSpace = async (id: string) => {
		fetchApiForUrl(urlForSpaceId(id), `/spaces/${id}`).then((result) => {
			setOnlineUser(result?.onlineUsers)
			setLogo(result?.logoUrl)
			setTitle(result?.title)
		})
	}

	useEffect(() => {
		fetchSpace(spaceId)
	}, [spaceId])

	useEffect(() => {
		if (selected) {
			setOnlineUser(undefined)

			fetchApiForUrl(urlForSpaceId(spaceId), `/spaces/${spaceId}`).then((result) => {
				if (result) {
					if (result.onlineUsers) {
						setOnlineUser(result.onlineUsers)
					} else {
						setOnlineUser([])
					}
				}
			})
		}
	}, [selected, spaceId])

	return (
		<>
			<HorizontalGroup
				onClick={onSelect}
				alignItems="center"
				justifyContent="space-between"
				style={{ cursor: "pointer", background: alternateRow ? "white" : "none", padding: "8px" }}
			>
				{/* <Icon selected={selected} src={icon} /> */}
				{selected ? (
					<P
						style={{
							flex: "0",
							backgroundImage: Flowy,
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
							whiteSpace: "nowrap",
						}}
					>
						{title ? title : "Information unavailable"}
					</P>
				) : (
					<P>{title ? title : "Information unavailable"}</P>
				)}

				<P>{onlineUsers ? onlineUsers.length : "-"}/100</P>
			</HorizontalGroup>
		</>
	)
}

export default SpaceItem
