import { HorizontalGroup } from "@saysom/shared"
import styled from "styled-components"

export const Container = styled.div`
	width: 100%;

	max-height: 100%;

	margin-bottom: -4px;

	flex: 1;
	flex-grow: 0;

	z-index: 1;
	background-color: white;

	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

	border-radius: 10px;
	background-color: white;
	margin-top: 10px;

	min-width: 300px;
	max-width: 600px;
`

export const Top = styled(HorizontalGroup)`
	border-radius: 10px;
	background-color: white;
	position: relative;
	z-index: 2;

	justify-content: center;
	align-items: center;

	padding: 16px 12px;
`
export const List = styled.div`
	height: 1200px;
	max-height: calc(100vh - 10px);
`

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	background: #333333;
	border-radius: 10px;
	border: 0;
	outline: 0;
	cursor: pointer;

	width: 36px;
	height: 16px;

	transition: all 200ms ease-in-out;

	:hover {
		transform: scale(1.05);
	}

	:active {
		transform: scale(0.9);
	}

	:disabled {
		pointer-events: none;
		color: rgb(180, 180, 180);
	}
`
